import { API } from "aws-amplify";
import { EventOrganizer } from "models/EventOrganizer";
import * as mutations from "graphql/mutations";
import * as queries from "graphql/queries";

export default class EventOrganizerService {
    public static async createEventOrganizer(eventOrganizer: EventOrganizer) {
        try {
            let result: any = await API.graphql({
                query: mutations.createEventOrganizer, variables: {
                    input: {
                        ...eventOrganizer
                    }
                }
            })
            return result.data.createKeyPowerMetric as EventOrganizer
        } catch {
            throw Error(`Couldn't create eventOrganizer: ${JSON.stringify(eventOrganizer)}`)
        }
    }

    public static async getEventOrganizers() {
        try {
            let result: any = await API.graphql({
                query: queries.listEventOrganizers 
            })
            return result.data.listEventOrganizers.items as EventOrganizer[]
        } catch(err) {
            console.log(err)
            throw Error(`Couldn't get eventOrganizers: ${JSON.stringify(err)}`)
        }
    }
}
import React from "react"
import ContentLoader from "react-content-loader"
import { Card, CardBody, Table } from "reactstrap"

export default class KeyPowerMetricLoading extends React.Component<{ editable: boolean }> {
    getContentLoader() {
        return (
            <ContentLoader viewBox="0 0 100% 30" height={30} width={"100%"}>
                <rect x="0" y="5" width="100%" height="20" />
            </ContentLoader>
        )
    }
    render() {
        return (
            <Card>
                <CardBody>
                    <h2>Power Metrics</h2>
                    <Table responsive size="sm">
                        <thead>
                            <tr>
                                <th>Duration</th>
                                <th>Value</th>
                                <th>Source</th>
                                <th>Secondary</th>
                                {this.props.editable && <th>Actions</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {[0, 1, 2, 3].map(index => {
                                return (
                                    <tr>
                                        <td>{this.getContentLoader()}</td>
                                        <td>{this.getContentLoader()}</td>
                                        <td>{this.getContentLoader()}</td>
                                        <td>{this.getContentLoader()}</td>
                                        {this.props.editable && <td>{this.getContentLoader()}</td>}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
        )
    }
}
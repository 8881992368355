import React from 'react';
import { Card, CardBody, CardDeck, CardHeader, Col, Container, Row } from 'reactstrap';
import "pages/Home/Home.css"
import EchelonRacingLeagueLogo from "content/ERP.png"
import RGTLogo from "content/RGT.png"
import UsacLogo from "content/UsacLogo.svg"
import CyclingCanada from "content/CyclingCanada.png"
import LogoMin from "content/LogoMin.svg"
import LogoMin2 from "content/LogoMin2.svg"
import { Link } from 'react-router-dom';


export default class Home extends React.Component {
    render() {
        return (
            <div>
                <div style={{ overflow: 'hidden', scrollSnapAlign: "start" }}>
                    <div className="cover">
                        <div className="cover-banner"></div>
                        <div className="cover-text">
                            <h1>eBioPassport</h1>
                            <p className="brand-yellow">A community effort in fair play</p>
                        </div>
                    </div>
                </div>
                <Container className="xl-top-buffer">
                    <Row>
                        <Col className="d-none d-md-block my-auto" md={3}>
                            <img width="100%" src={LogoMin2} />
                        </Col>
                        <Col className="my-auto">
                            <h1 className="brand-blue">About us</h1>
                            <p>We are a community driven effort run by volunteers who are all committed to fair play at all levels of sport. What we have created is far from the perfect solution, but we strive for continuous improvement with the greater good of the community always in mind.</p>
                            <p>After years of racing at the highest levels, we've come to realize the shortcomings in the current system. We've set out to build a platform that will enable the virtual racing community to control their data and showcase proof of their legitimacy.</p>
                        </Col>
                    </Row>
                </Container>
                <Container className="xl-top-buffer d-md-none">
                    <img width="100%" src={LogoMin} />
                </Container>
                <Container className="xl-top-buffer">
                    <Row>
                        <Col className="my-auto">
                            <div>
                                <h1 className="brand-blue">Our Mission:</h1>
                                <h1 className="brand-yellow">
                                    <i>Providing the tools necessary to empower the racing community and support fair play at all levels.</i>
                                </h1>
                            </div>
                        </Col>
                        <Col className="d-none d-md-block my-auto" md={3}>
                            <img width="100%" src={LogoMin} />
                        </Col>
                    </Row>
                </Container>
                <Container className="xl-top-buffer">
                    <Row>
                        <Col>
                            <h1 className="brand-blue">How it works</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <h2 className="brand-yellow">Riders</h2>
                            <ol type="1">
                                <li className="steps-number">Create an <Link className="brand-yellow" to="/my_profile">account</Link>.</li>
                                <li className="steps-number">Create video entries for your weigh ins, height ins or anything else validation related.</li>
                                <li className="steps-number">Create power entries showcasing your dual recordings or outdoor efforts.</li>
                                <li className="steps-number">Add what equipment you are riding on.</li>
                            </ol>
                        </Col>
                        <Col>
                            <h2 className="brand-yellow">Event Organizers</h2>
                            <ol type="1">
                                <li className="steps-number">Send us an <a href="mailto:eBioPassport@gmail.com" className="brand-yellow">email</a> to create an event organizer account.</li>
                                <li className="steps-number">View riders data who have completed your event.</li>
                                <li className="steps-number">Filter out riders from results accordingly.</li>
                            </ol>
                        </Col>
                    </Row>
                </Container>
                <Container className="xl-top-buffer">
                    <Row>
                        <Col>
                            <h1 className="brand-blue">Supporters</h1>
                            <p >We'd like to thank those who have played a part in encouraging fair play and have contributed to eBioPassport in some way.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="top-buffer center-text center" sm={12} md={6}>
                            <div>
                                <a href="https://www.echelonracingleague.com/" target="_blank">
                                    <img className="supporter-logo" src={EchelonRacingLeagueLogo} />
                                </a>
                                <p>For their early adoption of mandating eBioPassport in their pro level races.</p>
                            </div>
                        </Col>
                        <Col className="top-buffer center" sm={12} md={6}>
                            <div>
                                <a href="https://www.rgtcycling.com/" target="_blank">
                                    <img className="supporter-logo" src={RGTLogo} />
                                </a>
                                <p>For their technical guidance in the initial stages of development.</p>
                            </div>
                        </Col>
                        <Col className="top-buffer center" sm={12} md={6}>
                            <div>
                                <a href="https://usacycling.org/" target="_blank">
                                    <img className="supporter-logo" src={UsacLogo} />
                                </a>
                                <p>For their early adoption of mandating eBioPassport in their national championships.</p>
                            </div>
                        </Col>
                        <Col className="top-buffer center" sm={12} md={6}>
                            <div>
                                <a href="https://cyclingcanada.ca/" target="_blank">
                                    <img className="supporter-logo" src={CyclingCanada} />
                                </a>
                                <p>For their early adoption of mandating eBioPassport in their national championships.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
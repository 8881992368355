import React from "react"
import Equipment from "../../models/Equipment";
import EquipmentService from "../../services/EquipmentService";
import EquipmentMutation from "./EquipmentMutation";
import ReactGA from "react-ga"
import Status, { StatusType } from "../../components/Status";

interface EquipmentUpdateProps {
    userId: string
    equipmentId: string
}

interface EquipmentUpdateState {
    equipment: Equipment
    isLoading: boolean
    error: boolean
}

export default class EquipmentUpdate extends React.Component<EquipmentUpdateProps, EquipmentUpdateState> {

    constructor(props: EquipmentUpdateProps) {
        super(props)

        this.state = {
            equipment: null,
            isLoading: true,
            error: false
        }
    }

    async componentDidMount() {
        try {            
            let equipment = await EquipmentService.getEquipment(this.props.equipmentId)
            this.setState({isLoading: false, equipment: equipment})
        } catch (err) {
            console.log(err)
            ReactGA.exception({
                description: `EquipmentUpdate.componentDidMount.getEquipment(${this.props.equipmentId})`,
                fatal: true
            })
            this.setState({isLoading: false, error: true})
        }
    }

    render() {
        if (!this.state.isLoading && !this.state.error && this.state.equipment) {
            return (
                <EquipmentMutation userId={this.props.userId} equipment={this.state.equipment} />
            )
        } else if (this.state.isLoading) {
            return (
                <Status statusType={StatusType.LOADING} />
            )
        } else {
            return (
                <Status statusType={StatusType.FAIL} message={`Couldn't find the required equipment with ID: ${this.props.equipmentId}`}/>
            )
        }
    }
}
import { VideoEntry } from "models/VideoEntry";
import VideoFeed from "components/feeds/video/VideoFeed";
import React from "react";
import VideoService from "services/VideoService";
import VideoFeedLoading from "./VideoFeedLoading";

interface VideoFeedWrapperState {
    loading: boolean;
    videoEntries: VideoEntry[];
}

interface VideoFeedWrapperProps {
    userId: string
    editable: boolean
}

export default class VideoFeedWrapper extends React.Component<VideoFeedWrapperProps, VideoFeedWrapperState> {
    constructor(props: VideoFeedWrapperProps) {
        super(props)

        this.state = {
            loading: true,
            videoEntries: []
        }
    }

    async componentDidMount() {
        let videoEntries = await VideoService.getVideoEntriesByUserId(this.props.userId)
        this.setState({ videoEntries: videoEntries, loading: false })
    }

    render() {
        if (!this.state.loading) {
            return (
                <VideoFeed videoEntries={this.state.videoEntries} editable={this.props.editable} />
            )
        } else {
            return (
                <VideoFeedLoading />
            )
        }
    }
}
import { VerificationStatus } from "models/VerificationStatus";
import React from "react"
import VerificationRequestService from "services/VerificationRequestService";
import VerificationFeed from "./VerificationFeed";
import VerificationLoading from "./VerificationLoading";

interface VerificationFeedWrapperState {
    loading: boolean;
    verificationStatus: VerificationStatus[];
}

interface VerificationFeedWrapperProps {
    userId: string
    editable: boolean
}

export default class VerificationFeedWrapper extends React.Component<VerificationFeedWrapperProps, VerificationFeedWrapperState> {
    constructor(props: VerificationFeedWrapperProps) {
        super(props)

        this.state = {
            loading: true,
            verificationStatus: []
        }
    }

    async componentDidMount() {
        let verificationStatus = await VerificationRequestService.getVerificationStatusByUser(this.props.userId)
        this.setState({verificationStatus: verificationStatus, loading: false})
    }

    render() {
        if (!this.state.loading) {
            return (
                <VerificationFeed verificationStatus={this.state.verificationStatus} editable={this.props.editable}/>
            )
        } else {
            return (
                <VerificationLoading />
            )
        }
    }
}

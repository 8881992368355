import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Button, Card, CardBody, CardHeader, CardText, CardTitle, Col, Container, Input, Row } from 'reactstrap'
import User from '../../models/User'
import VideoComment from '../../models/VideoComment'
import { VideoEntry } from '../../models/VideoEntry'
import VideoService from '../../services/VideoService'
import ProfileCard from '../Profile/ProfileCard'
import VideoCard from './VideoCard'

interface VideoEntryDetailProps {
    id: string
    loggedInUser: User
}

interface VideoEntryDetailState {
    videoEntry: VideoEntry
    loading: boolean
    newComment: string;
}

export default class VideoEntryDetail extends React.Component<VideoEntryDetailProps, VideoEntryDetailState> {

    constructor(props: VideoEntryDetailProps) {
        super(props)

        this.state = {
            videoEntry: undefined,
            loading: false,
            newComment: ""
        }
    }

    async componentDidMount() {
        this.setState({ loading: true })
        try {
            let videoEntry = await VideoService.getVideoEntryAllById(this.props.id)
            this.setState({ loading: false, videoEntry: videoEntry })
        } catch (err) {
            this.setState({ loading: false })
            console.log(`failed to load video entry: ${this.props.id}: ${err}`)
        }
    }

    async submitNewComment() {
        await VideoService.createNewComment(
            {
                userId: this.props.loggedInUser.id,
                videoEntryId: this.state.videoEntry.id,
                body: this.state.newComment,
                groupsCanAccess: ["all"]
            }
        )
        window.location.reload()
    }

    async deleteVideoComment(videoComment: VideoComment) {
        await VideoService.updateVideoComment(
            {
                id: videoComment.id,
                body: "[deleted]"
            }
        )
        window.location.reload()
    }

    getSortedVideoComments(): VideoComment[] {
        return this.state.videoEntry.comments.items.sort((a, b) => {
            let dateA = new Date(a.createdAt)
            let dateB = new Date(b.createdAt)

            if (dateA >= dateB) {
                return -1
            } else {
                return 1
            }
        })
    }

    render() {
        if (this.state.videoEntry) {
            return (
                <Container>
                    <Row>
                        <Col md={12} xl={6}>
                            <div className="top-buffer">
                                <ProfileCard user={this.state.videoEntry.user} editable={false} />
                            </div>
                            <Card className="top-buffer">
                                <CardBody>
                                    <VideoCard videoEntry={this.state.videoEntry} editable={false} />
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={12} xl={6}>
                            <Card className="top-buffer">
                                <CardBody>
                                    <CardTitle>
                                        <h2>Comments</h2>
                                    </CardTitle>
                                    <CardText>
                                        <Input type="textarea" value={this.state.newComment} onChange={(event) => this.setState({ newComment: event.target.value })} />
                                        <Button style={{ marginTop: "10px" }} onClick={() => this.submitNewComment()}>Submit</Button>
                                        {this.getSortedVideoComments().map((videoComment: VideoComment) => {
                                            let date = new Date(videoComment.createdAt)
                                            return (
                                                <Card className="top-buffer">
                                                    <CardHeader>
                                                        <Row>
                                                            <Col>
                                                                {`${videoComment.user.firstName} ${videoComment.user.lastName} - ${date.toLocaleDateString()} ${date.toLocaleTimeString()}`}
                                                            </Col>
                                                            {this.props.loggedInUser.id === videoComment.user.id &&
                                                                <Col xs={2}>
                                                                    <Button
                                                                        onClick={() => this.deleteVideoComment(videoComment)}
                                                                        outline style={{ float: "right" }} size="sm" color="danger">
                                                                        <FontAwesomeIcon size="sm" icon={faTrashAlt} />
                                                                    </Button>
                                                                </Col>
                                                            }
                                                        </Row>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <CardText>
                                                            {videoComment.body}
                                                        </CardText>
                                                    </CardBody>
                                                </Card>
                                            )
                                        })}
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container >
            )
        } else {
            return (
                <div></div>
            )
        }
    }
}
import { Unit } from "../models/Unit";

let weightLabelsByUnit = new Map([
    [Unit.METRIC, "kg"],
    [Unit.EMPIRICAL, "lbs"]
])

let heightLabelsByUnit = new Map([
    [Unit.METRIC, "cm"],
    [Unit.EMPIRICAL, "inches"]
])

export default class UnitUtilies {
    public static getWeightLabel(unit: Unit) {
        return weightLabelsByUnit.get(unit)
    }

    public static getHeightLabel(unit: Unit) {
        return heightLabelsByUnit.get(unit)
    }

    public static secondsToReadableString(seconds: number) {
        const h = Math.floor(seconds / 3600);
        const m = Math.floor((seconds % 3600) / 60);
        const s = Math.round(seconds % 60);
        return [
            h,
            m > 9 ? m : (h ? '0' + m : m || '0'),
            s > 9 ? s : '0' + s
        ].filter(Boolean).join(':');
    }

    public static getLocalDateTime(dateTime: string) {
        let date = new Date(dateTime)
        try {
            let format = navigator.language
            return `${date.toLocaleDateString(format)} ${date.toLocaleTimeString()}`
        } catch (err) {
            return `${date.toLocaleDateString("en-ca")} ${date.toLocaleTimeString()}`
        }
        
    }
}
import PrivacyInput from 'components/PrivacyInput'
import React from 'react'
import { Button, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import PrivacyService from 'services/PrivacyService'
import Status, { StatusType } from 'components/Status'
import KeyPowerMetric from 'models/KeyPowerMetric'
import KeyPowerMetricService from 'services/KeyPowerMetricService'

interface KeyPowerMetricMutateProps {
    userId: string;
    keyPowerMetric?: KeyPowerMetric;
}

interface KeyPowerMetricMutateState {
    durationHours: string
    durationMinutes: string
    durationSeconds: string
    value: string
    fileLink: string
    secondaryFileLink: string
    notes: string
    feature: boolean
    public: boolean
    groupsCanAccess: { value: string, label: string }[]
    submitted: boolean
    submitting: boolean
    error: string
}

export default class KeyPowerMetricMutate extends React.Component<KeyPowerMetricMutateProps, KeyPowerMetricMutateState> {

    constructor(props: KeyPowerMetricMutateProps) {
        super(props)

        this.state = {
            durationHours: "",
            durationMinutes: "",
            durationSeconds: "",
            value: "",
            notes: "",
            fileLink: "",
            secondaryFileLink: "",
            feature: false,
            public: true,
            groupsCanAccess: [PrivacyService.defaultPrivacySetting()],
            submitted: false,
            submitting: true,
            error: ""
        }
    }

    async componentDidMount() {
        if (this.props.keyPowerMetric) {
            let totalSeconds = this.props.keyPowerMetric.duration
            let hours = Math.floor(totalSeconds / 3600);
            totalSeconds %= 3600;
            let minutes = Math.floor(totalSeconds / 60);
            let seconds = totalSeconds % 60;

            let valueToLabelMapping = await PrivacyService.valueToLabelMapping();
            let groupsCanAccess = this.props.keyPowerMetric.groupsCanAccess.map((value) => {
                return {
                    value: value,
                    label: valueToLabelMapping.get(value)
                }
            })

            let newState = {
                durationHours: hours.toString(),
                durationMinutes: minutes.toString(),
                durationSeconds: seconds.toString(),
                value: this.props.keyPowerMetric.value.toString(),
                notes: this.props.keyPowerMetric.notes,
                fileLink: this.props.keyPowerMetric.fileLinks.length > 0 ? this.props.keyPowerMetric.fileLinks[0] : "",
                secondaryFileLink: this.props.keyPowerMetric.fileLinks.length > 1 ? this.props.keyPowerMetric.fileLinks[1] : "",
                feature: false,
                public: this.props.keyPowerMetric.groupsCanAccess.includes("all"),
                groupsCanAccess: groupsCanAccess,
                submitted: false,
                submitting: false,
                error: ""
            }
            this.setState({...newState})
        } else {
            this.setState({submitting: false})
        }
    }

    allTimeValuesAreZero() {
        let hours = +this.state.durationHours
        let minutes = +this.state.durationMinutes
        let seconds = +this.state.durationSeconds
        return (hours + minutes + seconds) === 0
    }

    stateToKeyPowerMetric(): KeyPowerMetric {
        let hours = +this.state.durationHours
        let minutes = +this.state.durationMinutes
        let seconds = +this.state.durationSeconds
        let candidate: KeyPowerMetric = {
            userId: this.props.userId,
            duration: hours * 60 * 60 + minutes * 60 + seconds,
            value: +this.state.value,
            fileLinks: this.state.secondaryFileLink === "" ? [this.state.fileLink] : [this.state.fileLink, this.state.secondaryFileLink],
            notes: this.state.notes,
            groupsCanAccess: this.state.groupsCanAccess.map((entry) => entry.value)
        }

        return candidate
    }

    async createnewKeyPowerMetric(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault()

        try {
            this.setState({ submitting: true })
            if (this.props.keyPowerMetric) {
                await KeyPowerMetricService.updateKeyPowerMetric({ ...this.stateToKeyPowerMetric(), id: this.props.keyPowerMetric.id })
            } else {
                await KeyPowerMetricService.createKeyPowerMetric(this.stateToKeyPowerMetric())
            }
            this.setState({ submitted: true, submitting: false })
        } catch (err) {
            this.setState({ submitting: false, submitted: true, error: "Something went wrong, please try again." })
        }
    }

    render() {
        if (this.state.submitting) {
            return (
                <Status statusType={StatusType.LOADING} title="Submitting" />
            )
        } else if (this.state.submitted && this.state.error === "") {
            return (
                <Status statusType={StatusType.SUCCESS} message="Return to your profile page to view your new submission" />
            )
        } else if (this.state.submitted && this.state.error !== "") {
            return (
                <Status statusType={StatusType.FAIL} message={this.state.error} />

            )
        }
        return (
            <Container className="top-buffer">
                <h2>Create New Key Power Metric</h2>
                <Form onSubmit={(event) => this.createnewKeyPowerMetric(event)}>
                    <FormGroup row>
                        <Label md={4}>*Duration:</Label>
                        <Col md={8}>
                            <Row>
                                <Label xs={4} md={1}>hh: </Label>
                                <Col xs={8} md={3}>
                                    <Input required={this.allTimeValuesAreZero()} type="number" min="0" value={this.state.durationHours} onChange={(event) => this.setState({ durationHours: event.target.value })} />
                                </Col>
                                <Label xs={4} md={1}>mm: </Label>
                                <Col xs={8} md={3}>
                                    <Input required={this.allTimeValuesAreZero()} type="number" min="0" max="59" value={this.state.durationMinutes} onChange={(event) => this.setState({ durationMinutes: event.target.value })} />
                                </Col>
                                <Label xs={4} md={1}>ss: </Label>
                                <Col xs={8} md={3}>
                                    <Input required={this.allTimeValuesAreZero()} type="number" min="0" max="59" value={this.state.durationSeconds} onChange={(event) => this.setState({ durationSeconds: event.target.value })} />
                                </Col>
                            </Row>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label md={4}>*Watts:</Label>
                        <Col md={8}>
                            <Input required type="number" min="0" max="10000" value={this.state.value} onChange={(event) => this.setState({ value: event.target.value })} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label md={4}>*Data Source URL (strava, trainingpeaks, etc..):</Label>
                        <Col md={8}>
                            <Input required type="url" value={this.state.fileLink} onChange={(event) => this.setState({ fileLink: event.target.value })} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label md={4}>Secondary Source URL:</Label>
                        <Col md={8}>
                            <Input type="url" value={this.state.secondaryFileLink} onChange={(event) => this.setState({ secondaryFileLink: event.target.value })} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label md={4}>Notes:</Label>
                        <Col md={8}>
                            <Input type="textarea" value={this.state.notes} onChange={(event) => this.setState({ notes: event.target.value })} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label md={4}>*Permissions</Label>
                        <Col md={8}>
                            <PrivacyInput isMulti required value={this.state.groupsCanAccess} onChange={(selection) => this.setState({ groupsCanAccess: selection as { value: string, label: string }[]})} />
                        </Col>
                    </FormGroup>
                    <Button outline color="success" type="submit">Submit</Button>
                </Form>
            </Container>
        )
    }
}
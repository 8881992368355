import React from "react"
import * as d3 from 'd3'
import UnitUtilies from "../../utilities/UnitUtilities"
import { SvgUtilities } from "../../utilities/SvgUtilitiles"


interface DeviationCurveProps {
    datas: Map<number, number>[]
    deviceNames: string[]
}

interface BaseSVG {
    svg: d3.Selection<any, unknown, HTMLElement, any>,
    xScale: d3.ScaleContinuousNumeric<any, any, never>,
    yScale: d3.ScaleContinuousNumeric<any, any, never>,
    colorGradienter: (t: number) => string
}

export default class DeviationCurve extends React.Component<DeviationCurveProps> {

    componentDidMount() {
        let deviationData = this.getDeviationData()
        let base = this.getBase(deviationData)
        SvgUtilities.createGraphAxis(base, true, "deviation-curve-container")
        deviationData.forEach((data: Map<number, number>, index: number) => {
            this.createLine(Array.from(data), base, index)
        })
        this.createTooltip(base, deviationData)
    }

    getDeviationData(): Map<number, number>[] {
        let result: Map<number, number>[] = []
        let basePowerCurve = this.props.datas[0]
        this.props.datas.forEach((data: Map<number, number>) => {
            let deltaCurve: Map<number, number> = new Map()
            data.forEach((power: number, duration: number) => {
                let delta = (power - basePowerCurve.get(duration)) / basePowerCurve.get(duration)
                if (delta > -1 && delta < 1) {
                    deltaCurve.set(duration, delta)
                }
            })
            result.push(deltaCurve)
        })

        return result
    }

    createLine(data: [number, number][],
        base: BaseSVG,
        index: number) {

        let line = d3.line()
            .x((d: [number, number]) => base.xScale(d[0]))
            .y((d: [number, number]) => base.yScale(d[1]))
            .curve(d3.curveMonotoneX)

        base.svg.append("path")
            .datum(Array.from(data))
            .attr("fill", "none")
            .attr("stroke", base.colorGradienter(index))
            .attr("stroke-width", 1.5)
            .attr("d", line)
    }

    createTooltip(base: BaseSVG, deviationData: Map<number, number>[]) {
        let xValues = this.props.datas.map((powerCurve: Map<number, number>) => {
            return Array.from(powerCurve.keys())
        }).flat()

        let spacing = SvgUtilities.getSpacing("deviation-curve-container")
        let width = spacing.width
        let height = spacing.height

        base.svg.append("text")
            .attr("class", `tooltip-text`)
            .attr("width", 300)
            .attr("height", 50)
            .style("opacity", 0.5)
            .attr("x", width - 200)
            .attr("y", 0)

        deviationData.forEach((data: Map<number, number>, index: number) => {
            base.svg.append("text")
                .attr("class", `tooltip-text-${index.toString()}`)
                .attr("width", 300)
                .attr("height", 50)
                .attr("x", width - 200)
                .attr("y", index * 18 + 18)
                .style("fill", base.colorGradienter(index))
        })

        let focus = base.svg.append("g")
            .attr("class", "focus")
            .style("display", "none");

        focus.append("line").attr("class", "lineHover")
            .style("stroke", "#999")
            .attr("stroke-width", 1)
            .style("shape-rendering", "crispEdges")
            .style("opacity", 0.5)
            .attr("y1", -height)
            .attr("y2", 0);

        let descBisector = d3.bisector(d3.descending)
        let mousemove = (event: any) => {
            let x0 = base.xScale.invert(d3.pointer(event)[0])
            let i = descBisector.center(xValues, x0, 1)
            let duration = xValues[i]
            focus.attr("transform", `translate(${base.xScale(duration)}, ${height})`)
            base.svg.select(`.tooltip-text`).text(`Duration: ${UnitUtilies.secondsToReadableString(duration)}`)
            deviationData.forEach((data: Map<number, number>, index: number) => {
                base.svg.select(`.tooltip-text-${index}`).text(`${this.props.deviceNames[index]}: ${data.has(duration) ? Math.round(data.get(duration) * 10000) / 100 : 0}%`)
            })
        }

        base.svg.append("rect")
            .attr("class", "overlay")
            .attr("width", width)
            .attr("height", height)
            .style("fill", "none")
            .style("pointer-events", "all")
            .on("mouseover", function () { focus.style("display", null); })
            .on("mouseout", function () { focus.style("display", "none"); })
            .on("mousemove", mousemove);
    }

    getBase(deviationData: Map<number, number>[]): BaseSVG {
        let xValues = deviationData.map((deviationCurve: Map<number, number>) => {
            return Array.from(deviationCurve.keys())
        }).flat()
        let yValues = deviationData.map((deviationCurve: Map<number, number>) => {
            return Array.from(deviationCurve.values())
        }).flat()

        let spacing = SvgUtilities.getSpacing("deviation-curve-container")
        let margin = spacing.margin
        let width = spacing.width
        let height = spacing.height

        let xScale = d3.scalePow()
            .exponent(0.4)
            .domain([d3.min(xValues), d3.max(xValues)])
            .range([0, width]);

        let yScale = d3.scaleLinear()
            .domain([d3.min(yValues), d3.max(yValues)])
            .range([height, 0])

        let svg = d3.select("#deviation-curve")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .style("opacity", 0.9)
            .append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

        let colorGradienter = d3.scaleLinear<string>()
            .domain([0, this.props.datas.length - 1])
            .range(["#fab529", "#032859"]);

        return {
            svg: svg,
            xScale: xScale,
            yScale: yScale,
            colorGradienter: colorGradienter
        }
    }


    render() {

        return (
            <div id="deviation-curve-container">
                <svg id="deviation-curve"></svg>
            </div>
        )
    }
}
import React from 'react'
import WCPowerCurveCanadaMenCanadaMen from './WCPowerCurve/CanadianAnalysisMen'
import WCPowerCurveCanadaWomen from './WCPowerCurve/CanadianAnalysisWomen'
import WCPowerCurve from './WCPowerCurve/WCPowerCurve'

interface CustomComponentProps {
    componentName: string;
}

export default class CustomComponentDelegator extends React.Component<CustomComponentProps> {
    render(): React.ReactNode {
        if (this.props.componentName == "WCPowerDataMen") {
            return <WCPowerCurve />
        } else if (this.props.componentName == "WCPowerDataCanada") {
            return (
                <div>
                    <h4>Men</h4>
                    <WCPowerCurveCanadaMenCanadaMen />
                    <div style={{height: "20px"}}></div>
                    <h4>Women</h4>
                    <WCPowerCurveCanadaWomen />
                </div>
            )
        } else {
            return (<div></div>)
        }
    }
}
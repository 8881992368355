import React, { FormEvent } from 'react'
import User from '../../models/User'
import { ButtonGroup, Card, CardBody, CardTitle, Button, Form, FormGroup, Label, Input, Col, Row, CardText, Progress, CardFooter, Toast, ToastBody, ToastHeader } from 'reactstrap'
import UserService from '../../services/UserService'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faShareAlt } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'

interface ProfileCardProps {
    user: User;
    editable: boolean;
}

interface ProfileCardState {
    showCopyToast: boolean;
    editing: boolean;
    user: { firstName: string, lastName: string, bio: string };
}


export default class ProfileCard extends React.Component<ProfileCardProps, ProfileCardState> {

    constructor(props: ProfileCardProps) {
        super(props)

        this.state = {
            editing: false,
            showCopyToast: false,
            user: {
                firstName: this.props.user.firstName,
                lastName: this.props.user.lastName,
                bio: this.props.user.bio
            }
        }
    }

    async updateUser(event: FormEvent) {
        event.preventDefault()
        await UserService.updateUserById(this.props.user.id, this.state.user);
        window.location.reload();
    }

    copyToClipboard() {
        navigator.clipboard.writeText(`Checkout my transparency profile: ${window.origin}/profile/${this.props.user.id}`)
        this.setState({ showCopyToast: true })
    }

    componentDidUpdate() {
        setTimeout(() => this.setState({ showCopyToast: false }), 3000);
    }

    render() {
        if (this.state.editing) {
            return (
                <Card>
                    {/* <CardImg height="200px" src={`${this.props.user.porfilePictureUrl}`}></CardImg> */}
                    <CardBody>
                        <Form onSubmit={(event) => this.updateUser(event)}>
                            <FormGroup row>
                                <Label>First Name</Label>
                                <Input type="text" value={this.state.user.firstName} onChange={(event) => this.setState({
                                    user: {
                                        ...this.state.user,
                                        firstName: event.target.value
                                    }
                                })} />
                            </FormGroup>
                            <FormGroup row>
                                <Label>Last Name</Label>
                                <Input type="text" value={this.state.user.lastName} onChange={(event) => this.setState({
                                    user: {
                                        ...this.state.user,
                                        lastName: event.target.value
                                    }
                                })} />
                            </FormGroup>
                            <FormGroup row>
                                <Label>Bio (supports markdown)</Label>
                                <Input type="textarea" value={this.state.user.bio} onChange={(event) => this.setState({
                                    user: {
                                        ...this.state.user,
                                        bio: event.target.value
                                    }
                                })} />
                            </FormGroup>
                            <ButtonGroup>
                                <Button size="sm" outline color="success" type="submit">Save</Button>
                                <Button size="sm" outline color="danger" onClick={() => this.setState({ editing: false })}>Cancel</Button>
                            </ButtonGroup>
                        </Form>
                    </CardBody>
                </Card>
            )
        } else {
            return (
                <Card>
                    <CardBody>
                        <CardTitle>
                            <Row>
                                <Col>
                                    <Link style={{ color: "inherit", textDecoration: "inherit" }} to={`/profile/${this.props.user.id}`}><h2>{`${this.props.user.firstName} ${this.props.user.lastName}`}</h2></Link>
                                </Col>
                                {this.props.editable &&
                                    <Col xs={3}>
                                        <ButtonGroup style={{ float: "right" }}>
                                            <Button onClick={() => this.copyToClipboard()} size="sm" color="primary" outline>
                                                <FontAwesomeIcon icon={faShareAlt} />
                                            </Button>
                                            <Button size="sm" style={{ float: "right" }} outline color="info" onClick={() => this.setState({ editing: true })}><FontAwesomeIcon icon={faEdit} /></Button>
                                        </ButtonGroup>
                                    </Col>
                                }
                            </Row>
                        </CardTitle>
                        <CardText>
                            {this.props.user.bio &&
                                <ReactMarkdown plugins={[gfm]}>{this.props.user.bio}</ReactMarkdown>
                            }
                        </CardText>
                    </CardBody>
                    {this.state.showCopyToast && <div style={{ position: "fixed", bottom: "20px", right: "10px", zIndex: 1 }}>
                        <Toast>
                            <ToastHeader>
                                Thanks for sharing!
                        </ToastHeader>
                            <ToastBody>
                                Link copied to clipboard.
                        </ToastBody>
                        </Toast>
                    </div>}
                </Card>
            )
        }
    }
}
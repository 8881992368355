import React from "react";
import { Card, CardBody, CardDeck, CardHeader, CardImg, CardText, CardTitle, Col, Container, Row, Table } from "reactstrap";
import TedKing from "./TedKing.jpg"
import KBH from "./KevinBouchardHall.jpg"
import AndrewFerence from "./AndrewFerence.jpg"
import GeoffKabush from "./GeoffKabush.jpg"
import "./BattleFor16A4.css"

const RESTART_RIDERS = [
    { name: "Mark Lennox", powerProfile: new Map<number, number>([[15, 10.2], [60, 7.57], [300, 5.67], [1200, 5.10]]), weight: 68.9 },
    { name: "Oliver Dowd", powerProfile: new Map<number, number>([[15, 14.74], [60, 9.25], [300, 6.04], [1200, 5.13]]), weight: 67 },
    { name: "Mark Sherboneau", powerProfile: new Map<number, number>([[15, 10.51], [60, 7.27], [300, 5.53], [1200, 4.97]]), weight: 79 },
    { name: "Jonathan Crain", powerProfile: new Map<number, number>([[15, 16.03], [60, 8.99], [300, 5.58], [1200, 4.93]]), weight: 61.3 },
    { name: "Alex Michel", powerProfile: new Map<number, number>([[15, 12.70], [60, 7.15], [300, 5.24], [1200, 4.64]]), weight: 78 },
    { name: "Mitchell Robinson", powerProfile: new Map<number, number>([[15, 12.88], [60, 7.50], [300, 5.26], [1200, 4.63]]), weight: 71 },
    { name: "Derek O'Farrell", powerProfile: new Map<number, number>([[15, 9.52], [60, 6.92], [300, 5.5], [1200, 4.96]]), weight: 75.9 },
    { name: "Matt Wheeler", powerProfile: new Map<number, number>([[15, 11.58], [60, 7.10], [300, 5.13], [1200, 5.37]]), weight: 75 },
    { name: "Alex Gagnon", powerProfile: new Map<number, number>([[15, 12.81], [60, 8.33], [300, 6.47], [1200, 5.64]]), weight: 65 }
]

const VELOCIO_RIDERS = [
    { name: "Jason Frank", powerProfile: new Map<number, number>([[15, 15.9], [60, 9.5], [300, 5.94], [1200, 5.28]]), weight: 64 },
    { name: "Ted King", powerProfile: new Map<number, number>([[15, 11.66], [60, 8.52], [300, 5.73], [1200, 4.96]]), weight: 80.7 },
    { name: "Kevin Bouchard-Hall", powerProfile: new Map<number, number>([[15, 13.97], [60, 8.75], [300, 6.17], [1200, 5.70]]), weight: 77.1 },
    { name: "Mike Morse", powerProfile: new Map<number, number>([[15, 16.98], [60, 9.15], [300, 5.68], [1200, 4.83]]), weight: 73 },
    { name: "Matthew Kurz", powerProfile: new Map<number, number>([[15, 13.87], [60, 8.88], [300, 5.8], [1200, 4.9]]), weight: 72.6 },
    { name: "Mike Barton", powerProfile: new Map<number, number>([[15, 12.29], [60, 7.56], [300, 5.66], [1200, 5.16]]), weight: 80.7 },
    { name: "Charles McCarthy", powerProfile: new Map<number, number>([[15, 13.31], [60, 7.77], [300, 5.87], [1200, 5.07]]), weight: 68 },
    { name: "Geoff Kabush", powerProfile: new Map<number, number>([[15, 13.36], [60, 8.09], [300, 5.41], [1200, 5.16]]), weight: 77 },
    { name: "Andrew Ference", powerProfile: new Map<number, number>([[15, 13.89], [60, 8.16], [300, 5.64], [1200, 4.92]]), weight: 79 },
    { name: "Yueto Shigihara", powerProfile: new Map<number, number>([[15, 10.32], [60, 7.89], [300, 5.57], [1200, 4.87]]), weight: 73.5 },
    { name: "AJ Moran", powerProfile: new Map<number, number>([[15, 14.18], [60, 9.02], [300, 5.37], [1200, 4.73]]), weight: 82.6 },
]
export default class BattleFor16A4 extends React.Component {
    render() {
        return (
            <Container className="top-buffer">
                <h1 className="brand-blue">Chasing Velocio</h1>
                <p>You know you're in for a rough ride when one of the teams you'll be racing with every week consists largely of recently retired or current pro athletes.
                At Restart Racing we knew we would have our work cut out for us, battling for the top spot in the America E A4 division.
                  Here's our story and how the regular season of <a href="https://www.wtrl.racing/about.php" target="_blank">WTRL</a> has gone for us racing every week against <a href="https://velocio.cc/" target="_blank">Velocio</a>.</p>
                <h2 className="brand-blue">The Antagonists - Team Velocio</h2>
                <p>The Velocio roster included a few notable names:</p>
                <div style={{ background: "linear-gradient(0, var(--brand-yellow) 50%, #f7f7f7 50%)", padding: "30px" }}>
                    <CardDeck>
                        <Card className="profile-card top-buffer">
                            <CardHeader>
                                <CardTitle><h5>Kevin Bouchard-Hall</h5></CardTitle>
                            </CardHeader>
                            <CardImg className="profile-image" src={KBH} />
                            <CardBody>
                                <CardText>
                                    Leader of the Velocio squad, and well known American CX racer.
                                </CardText>
                            </CardBody>
                        </Card>
                        <Card className="profile-card top-buffer">
                            <CardHeader>
                                <CardTitle><h5>Ted King</h5></CardTitle>
                            </CardHeader>
                            <CardImg className="profile-image" src={TedKing} />
                            <CardBody>
                                <CardText>
                                    Gravel racing legend, Dirty Kanza winner, and ex world tour pro.
                                </CardText>
                            </CardBody>
                        </Card>
                        <Card className="profile-card top-buffer">
                            <CardHeader>
                                <CardTitle><h5>Geoff Kabush</h5></CardTitle>
                            </CardHeader>
                            <CardImg className="profile-image" src={GeoffKabush} />
                            <CardBody>
                                <CardText>
                                    3x Olympian, I don't think any more credentials are needed than this.
                                </CardText>
                            </CardBody>
                        </Card>
                        <Card className="profile-card top-buffer">
                            <CardHeader>
                                <CardTitle><h5>Andrew Ference</h5></CardTitle>
                            </CardHeader>
                            <CardImg className="profile-image" src={AndrewFerence} />
                            <CardBody>
                                <CardText>
                                    Retired NHL player, who is more than capable of taking on the rest of the field in a fist fight.
                                </CardText>
                            </CardBody>
                        </Card>
                    </CardDeck>
                </div>
                <h2 className="brand-blue top-buffer">The Protagonists - Restart Racing</h2>
                <p>Then there is us, Restart racing, we represent the people, just your ordinary blue/white collar workers riding bikes for fun in our spare time.
                Most of us ride between 5-10 hours a week and have no athletic background worth bragging about, we're also a butt ugly gang so I'll spare your eyes of our photos.
                </p>
                <h2 className="brand-blue top-buffer">Full Rosters</h2>
                <div>
                    <CardDeck>
                        <Card className="top-buffer">
                            <CardHeader>
                                <h5>Restart</h5>
                            </CardHeader>
                            <CardBody>
                                <Table responsive size="sm">
                                    <thead>
                                        <tr>
                                            <th>Rider</th>
                                            <th>15s</th>
                                            <th>1min</th>
                                            <th>5min</th>
                                            <th>20min</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {RESTART_RIDERS.sort((a, b) => a.name > b.name ? 1 : -1).map((rider) => {
                                            return (
                                                <tr>
                                                    <td>{rider.name}</td>
                                                    <td>{rider.powerProfile.get(15)}</td>
                                                    <td>{rider.powerProfile.get(60)}</td>
                                                    <td>{rider.powerProfile.get(300)}</td>
                                                    <td>{rider.powerProfile.get(1200)}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                        <Card className="top-buffer">
                            <CardHeader>
                                <h5>Velocio</h5>
                            </CardHeader>
                            <CardBody>
                                <Table responsive size="sm">
                                    <thead>
                                        <tr>
                                            <th>Rider</th>
                                            <th>15s</th>
                                            <th>1min</th>
                                            <th>5min</th>
                                            <th>20min</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {VELOCIO_RIDERS.sort((a, b) => a.name > b.name ? 1 : -1).map((rider) => {
                                            return (
                                                <tr>
                                                    <td>{rider.name}</td>
                                                    <td>{rider.powerProfile.get(15)}</td>
                                                    <td>{rider.powerProfile.get(60)}</td>
                                                    <td>{rider.powerProfile.get(300)}</td>
                                                    <td>{rider.powerProfile.get(1200)}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </CardDeck>
                </div>
                <h2 className="brand-blue top-buffer">Week 1 - Richmond UCI Reverse</h2>
                <Row>
                    <Col sm={12} md={4} lg={3} className="top-buffer my-auto">
                        <div>
                            <img className="course-picture" src="https://zwifthacks.com/app/routes/svg/route/?world=2&route=4&showprofile=1&showlegend=1" />
                            <p className="text-muted">Course profile provided by <a href="https://zwifthacks.com/" target="_blank">Zwift Hacks</a>.</p>
                        </div>
                    </Col>
                    <Col className="top-buffer my-auto">
                        <p>There was noticeable excitement in the start pens as we discussed how the race would play out. We knew it would be a full gas race of survival on the first set of hills. After the first set of KOMs where behind us and the dust had settled the front group ended up being 13 riders, with Restart and Velocio both having 4 riders.
                            We had come away with 19 points, however Velocio had managed to net 30, so we had our work cut out for us. Kevin Bouchard-Hall attacked the first sprint banner early and Velocio were able to gain an even greater lead on us, however we had faster segment times than them and we were able to pull some points back in the second sprint.</p>
                    </Col>
                </Row>
                <div className="top-buffer center-text">
                    <iframe style={{ width: "100%", height: "300px", maxWidth: "500px" }} src="https://player.twitch.tv/?video=871489934&time=0h2m58s&autoplay=false&parent=localhost&parent=www.ebiopassport.com&parent=test.d3kz024hv2c1pt.amplifyapp.com" frameBorder={0} allowFullScreen scrolling="no"></iframe>
                    <p className="text-muted">KBH on an emotional roller coaster on the first primes.</p>
                </div>
                <p>The second lap was largely the same as the first, with points going back and forth between the two teams on each banner. As we went through the final sprint banner Ted King carried his effort and tried for a long bomb attack. The field was strung out and gaps where starting to open, but the draft was too strong, and everyone was too motivated and it all came back together.
                Then as per Zwift tradition on these types of courses it came down to the bunch sprint. There was no strategy involved here and we all put in one final dig to get the best result possible. At the end of the day once all the points had been added up Velocio had edged us out 309 - 290.
                </p>
                <Container className="top-buffer center-text">
                    <iframe style={{ width: "100%", height: "300px", maxWidth: "500px" }} src="https://www.youtube.com/embed/fkNu9rFThdA?controls=0&amp;start=1394" frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    <p className="text-muted">Ted King attacking after the final prime.</p>
                </Container>

                <h2 className="brand-blue top-buffer">Week 2 - TTT R.G.V</h2>
                <Row>
                    <Col sm={12} md={4} lg={3} className="top-buffer my-auto">
                        <div>
                            <img className="course-picture" src="https://zwifthacks.com/app/routes/svg/route/?world=10&route=0&showprofile=1&showlegend=1" />
                            <p className="text-muted">Course profile provided by <a href="https://zwifthacks.com/" target="_blank">Zwift Hacks</a>.</p>
                        </div>
                    </Col>
                    <Col className="top-buffer my-auto">
                        <p>The TTT was a daunting thought, especially after looking through the signups and seeing Velocios lineup consisted almost entirely of riders capable of pushing over 5wkg for 20 minutes.
                        We were realistic with ourselves and just focused on racing our own race and doing the best time we could.
                        Passing proved to be a bit of a logistical challenge, we overtook a couple teams but ended up losing one rider during overtakes after they got stuck in a sticky draft.
                        </p>
                        <p>
                            Then, at the end of the aquaduc KOM descent we saw Velocio approaching us to make a pass which is where the drama ensued.
                            A combination of them not overtaking us fast enough and us getting stuck in their draft resulted in a tense 2 minute period where the two teams where intermixed.
                            Some harsh words were thrown in the heat of things by both sides, but after things settled down post race, we smoothed things over with them.
                        </p>
                    </Col>
                </Row>
                <p>In the end the results where nothing to be surprised by, we ended up coming second losing ~40 seconds over the TTT. What was concerning us was seeing that almost each of their rider had done a higher 20 minute power than even our top rider, which meant next week at Innsbruck we would be on the defensive again.</p>

                <h2 className="brand-blue top-buffer">Week 3 - Innsbruck KOM after party</h2>
                <Row>
                    <Col sm={12} md={4} lg={3} className="top-buffer my-auto">
                        <div>
                            <img className="course-picture" src="https://zwifthacks.com/app/routes/svg/route/?world=5&route=5&showprofile=1&showlegend=1" />
                            <p className="text-muted">Course profile provided by <a href="https://zwifthacks.com/" target="_blank">Zwift Hacks</a>.</p>
                        </div>
                    </Col>
                    <Col className="top-buffer my-auto">
                        <p>We knew that it was unlikely for us to come out ahead this week since the point structure heavily riders who could do a good 20 minute climb.
                        We knew if there was any hope for us, we would have to go for the intermediate sprint points and be opportunistic.
                    </p>
                        <p>The first lap up the leg snapper was full gas with some of our best riders almost not making the front group. The sprint ended up going from very far out and resulted in the field getting very strung out.
                        When we noticed that Ted King was in a split 3 seconds off the main group coming into the start/finish banners we decided as a team to push the pace.
                        By the start of the leg snapper he was 20 seconds behind and most likely out of contention for intermediate points and would get significantly less finish line points than he would have if he stayed in the group.
                        Unfortunately, the effort of pulling the group took it's toll on some of our riders and we weren't able to get as many points the following sprints, but in hindsight it was still the right move to make.
                </p>
                    </Col>
                </Row>
                <Container className="top-buffer center-text">
                    <iframe style={{ width: "100%", height: "300px", maxWidth: "500px" }} src="https://player.twitch.tv/?video=889469896&time=0h11m24s&autoplay=false&parent=localhost&parent=www.ebiopassport.com&parent=test.d3kz024hv2c1pt.amplifyapp.com" frameBorder="0" allowFullScreen scrolling="no"></iframe>
                    <p className="text-muted">Ted King narrowly missing the group after the first sprint.</p>
                </Container>
                <p>We were taking as many points as we could through the remaining intermediate sprints and came into the base of the KOM with all 6 riders.
                As soon as the climb started the big pack broke up into smaller groups with the front group having 3 Velocio riders and 2 Restart riders.
                We fought hard but, in the end, couldn't respond when KBH attacked halfway up the climb to take the stage win and a slew of points on the finish.
                Once all the points where tallied it wasn't close, as expected with Velocio scoring a huge number of points on the finishing climb beating us 356 - 204.
                </p>
                <Container className="top-buffer center-text">
                    <iframe style={{ width: "100%", height: "300px", maxWidth: "500px" }} src="https://player.twitch.tv/?video=889469896&time=0h47m13s&autoplay=false&parent=localhost&parent=www.ebiopassport.com&parent=test.d3kz024hv2c1pt.amplifyapp.com" frameBorder="0" allowFullScreen scrolling="no"></iframe>
                    <p className="text-muted">KBH's nuclear attack on the Innsbruck KOM</p>
                </Container>
                <h2 className="brand-blue top-buffer">Week 4 - UCI Worlds Harrogate</h2>
                <Row>
                    <Col sm={12} md={4} lg={3} className="top-buffer my-auto">
                        <div>
                            <img className="course-picture" src="https://zwifthacks.com/app/routes/svg/route/?world=7&route=0&showprofile=1&showlegend=1" />
                            <p className="text-muted">Course profile provided by <a href="https://zwifthacks.com/" target="_blank">Zwift Hacks</a>.</p>
                        </div>
                    </Col>
                    <Col className="top-buffer my-auto">
                        <p>This course seemed to be one where we stood a decent chance of taking an overall win, and a lot of us where out for some revenge after the previous weeks battering.
                        This week was also our division's turn to be broadcasted on Zwift Community Live, so it was a better time than ever to have a good performance.
                        However, after looking at the start list it seemed that Velocio had added even more depth to their lineup with Geoff Kabush and Andrew Ference both riding for Velocio.
                        </p>
                        <p>It was all out on the first climb from the pens, but a big group stayed together. Coming into the first KOM points, there was a game of chicken being played trying to start as far back in the group to get the fastest time segment. We managed to put a couple riders in the top 10 and it looked like we would be around equal points with Velocio.
                            The sprint banner being so close after the KOM banner meant that no one had good legs for the sprint but we were still able to get points on the board. After the first lap it seemed like we were on almost equal points to Velocio, although it's hard to tell in the heat of the moment.</p>
                    </Col>
                </Row>
                <Container className="top-buffer center-text">
                    <iframe style={{ width: "100%", height: "300px", maxWidth: "500px" }} src="https://www.youtube.com/embed/Uk1Kjby0XFs?start=755" frameBorder="0" allowFullScreen scrolling="no"></iframe>
                    <p className="text-muted">Jon going full gas for KOM points</p>
                </Container>
                <p>We got a good look at the finish line this time through and settled in for the unmarked climbs on the first half of the course.
                The second time up the KOM started off easier than the first time as people were clearly fatigued, one rider went off the front and almost made it to the banner before getting caught by the group.
                Half of us didn't contest the final sprint in order to save our legs for the final, Velocio opened up the finishing sprint early and looked like they were going to hold it to the line but we managed to overtake some of their riders.
                After the final points had been tallied, Velocio edged us out once again and it became clear that just winning a single race would be a challenge in itself.
                </p>
                <Container className="top-buffer center-text">
                    <Row>
                        <Col xs={12} md={6}>
                            <iframe style={{ width: "100%", height: "300px", maxWidth: "500px" }} src="https://www.youtube.com/embed/lDVvzWnW02s?start=3046" frameBorder="0" allowFullScreen scrolling="no"></iframe>
                            <p className="text-muted">Moreau attempting to go early on the KOM</p>
                        </Col>
                        <Col>
                            <iframe style={{ width: "100%", height: "300px", maxWidth: "500px" }} src="https://www.youtube.com/embed/lDVvzWnW02s?start=3450" frameBorder="0" allowFullScreen scrolling="no"></iframe>
                            <p className="text-muted">ZCL miscalling our rider as the first across the line</p>
                        </Col>
                    </Row>
                </Container>
                <h2 className="brand-blue top-buffer">Week 5 - TTT Mighty Metropolitan</h2>
                <Row>
                    <Col sm={12} md={4} lg={3} className="top-buffer my-auto">
                        <div>
                            <img className="course-picture" src="https://zwifthacks.com/app/routes/svg/route/?world=4&route=5&showprofile=1&showlegend=1" />
                            <p className="text-muted">Course profile provided by <a href="https://zwifthacks.com/" target="_blank">Zwift Hacks</a>.</p>
                        </div>
                    </Col>
                    <Col className="top-buffer my-auto">
                        <p>This TTT would prove to be a challenge, we had a strong squad this week but we were realistic with our object and just wanted to not get overtaken by Velocio.
                        In the opening section into the skybridge that seemed like it would be a tougher challenge than anticipated with Velocio hot on our trial as we came into the NYC KOM.
                        At the end of the KOM they were so close it seemed like they were going to make the bridge and to make matters worse we were a bit messy regrouping over the top of the KOM.
                        However, it seemed like they had paced the start too hard and by the bottom of the skybridge our gap had stabilized to just over 10 seconds.
                        Then in the final kilometers both teams put in big digs to keep the other at bay, in the end Velocio didn't overtake us and we only lost ~25 seconds, a pretty good performance overall.
                        </p>
                    </Col>
                </Row>
                <Container className="top-buffer center-text">
                    <iframe style={{ width: "100%", height: "300px", maxWidth: "500px" }} src="https://player.twitch.tv/?video=907933476&time=0h15m33s&autoplay=false&parent=localhost&parent=www.ebiopassport.com&parent=test.d3kz024hv2c1pt.amplifyapp.com" frameBorder="0" allowFullScreen scrolling="no"></iframe>
                    <p className="text-muted">Velocio coming so close to catching us at the top of the KOM</p>
                </Container>
                <h2 className="brand-blue top-buffer">Week 6 - Whole Lotta Lava</h2>
                <Row>
                    <Col sm={12} md={4} lg={3} className="top-buffer my-auto">
                        <div>
                            <img className="course-picture" src="https://zwifthacks.com/app/routes/svg/route/?world=1&route=32&showprofile=1&showlegend=1" />
                            <p className="text-muted">Course profile provided by <a href="https://zwifthacks.com/" target="_blank">Zwift Hacks</a>.</p>
                        </div>
                    </Col>
                    <Col className="top-buffer my-auto">
                        <p>This course would be another challenge for us and us with lots of points being offered to strong climbers. The first lap went hard out of the gates but it seemed that things settled down to a manageable pace up the climb.
                        For us a slow climb with the opportunity to sprint for points on the top was the best case outcome and we had placed a lot of our riders in the top 10. In the descent there was a good opportunity to recover and we started the next lap with a full squad in the front group.
                        </p>
                    </Col>
                </Row>
                <Container className="top-buffer center-text">
                    <iframe style={{ width: "100%", height: "300px", maxWidth: "500px" }} src="https://clips.twitch.tv/embed?clip=AnnoyingSillyRabbitGivePLZ-FqMbDa6uOc5XIprT&autoplay=false&parent=localhost&parent=www.ebiopassport.com&parent=test.d3kz024hv2c1pt.amplifyapp.com" frameBorder="0" allowFullScreen scrolling="no"></iframe>
                    <p className="text-muted">Jon taking the first prime on the KOM</p>
                </Container>
                <p>The second time over the climb some even described the pace as "comfortable" and Jon was able to take another first across the line on the segment.
                However, on the third pass of the climb Velocio decided they wanted to break things up more, and wound up the pace at the start of the third climb.
                KBH was able to hold off the group and take the final prime as well as set the new fastest time up the volcano KOM. Things came back together for the most part on the descent.
                    At the base of the descent, Ted King launched an incredible attack and managed to stick it to the line giving him a much deserved win after trying risky moves all season.</p>
                <Container className="top-buffer center-text">
                    <iframe style={{ width: "100%", height: "300px", maxWidth: "500px" }} src="https://player.twitch.tv/?video=917067314&time=0h53m13s&autoplay=false&parent=localhost&parent=www.ebiopassport.com&parent=test.d3kz024hv2c1pt.amplifyapp.com" frameBorder="0" allowFullScreen scrolling="no"></iframe>
                    <p className="text-muted">Ted King sticking a risky move</p>
                </Container>
                <h2 className="brand-blue top-buffer">Week 7 - Libby Hill After Party</h2>
                <Row>
                    <Col sm={12} md={4} lg={3} className="top-buffer my-auto">
                        <div>
                            <img className="course-picture" src="https://zwifthacks.com/app/routes/svg/route/?world=2&route=6&showprofile=1&showlegend=1" />
                            <p className="text-muted">Course profile provided by <a href="https://zwifthacks.com/" target="_blank">Zwift Hacks</a>.</p>
                        </div>
                    </Col>
                    <Col className="top-buffer my-auto">
                        <p>This course looked like it would favor the sprinters and give us a chance to try and take a win in the final scratch race of the season.
                        With 6 flat sprints before the final kick up to libby hill we figured that even though we lack the long duration power, this course would favor our roster of punchier riders.
                            The trouble for began when we crossed through the first sprint banner and instead of things settling down the pace stayed high and there was no chance to recover.</p>
                    </Col>
                </Row>
                <p>We had thought our strength would be in the sprints, but each lap was so fast and strung out each sprint that most of us didn't have legs to sprint and could barely hang onto the group.
                We managed to take a couple points here and there, but it was far from the haul we had anticipated. All was not lost as there was still a lot of points on offer for the finish with points for overall place, first across the line and fastest time.
                        Things also slowed down a bit on the lead into the climb and we were all able to recover. In the end we all were able to put down good sprints with many of our riders doing their best 1 minute powers.</p>
                <Container className="top-buffer center-text">
                    <iframe style={{ width: "100%", height: "300px", maxWidth: "500px" }} src="https://player.twitch.tv/?video=926060334&time=0h40m10s&autoplay=false&parent=localhost&parent=www.ebiopassport.com&parent=test.d3kz024hv2c1pt.amplifyapp.com" frameBorder="0" allowFullScreen scrolling="no"></iframe>
                    <p className="text-muted">The final sprint up libby hill</p>
                </Container>
                <h2 className="brand-blue top-buffer">Week 8 - TTT Greatest London Flat</h2>
                <Row>
                    <Col sm={12} md={4} lg={3} className="top-buffer my-auto">
                        <div>
                            <img className="course-picture" src="https://zwifthacks.com/app/routes/svg/route/?world=3&route=12&showprofile=1&showlegend=1" />
                            <p className="text-muted">Course profile provided by <a href="https://zwifthacks.com/" target="_blank">Zwift Hacks</a>.</p>
                        </div>
                    </Col>
                    <Col className="top-buffer my-auto">
                        <p>The final TTT of the season and we were once again realistic with ourselves knowing the fire power Velocio had. 
                            We raced our own race to get the best time we could and didn't worry about getting overtaken. 
                            We started off very organized rotating every 30 seconds and pulling on the front around 6wkg, and the flat course made it easy to keep the team together.
                            Things fell apart a bit as we went up the box hill bypass and we also started to catch some teams and had some messy passes.
                            We had to do some regrouping and repassing after exiting the tube station and the chaos burned some of our riders, but in the end we managed to finish with a full squad and cap off our season with another 2nd place finish.
                            </p>
                    </Col>
                </Row>
                <h1 className="brand-blue">Final Thoughts</h1>
                <p>Overall, we had a great series of weeks participating in the ZRL, even if we were a constant 2nd place, the points structure made us feel like we had a chance every week.
                    In the future it would be nice to have slightly longer events up to 1.5 hours which could allow for some even more dynamic racing with the potential for break aways.
                </p>
            </Container >
        )
    }
}
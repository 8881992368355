import * as prismic from '@prismicio/client'
import { BooleanModel } from 'aws-sdk/clients/gamelift'

const client = prismic.createClient("https://ebiopassport.prismic.io/api/v2",
    { accessToken: "MC5ZZ2JXZUJFQUFDOEFka1NN.WO-_ve-_ve-_vXHvv70V77-9Pe-_vRjvv71d77-977-9Ue-_ve-_ve-_ve-_ve-_ve-_ve-_ve-_ve-_vSEEBO-_vWHvv71p" }
)

export interface Article {
    data: {
        enabled: BooleanModel,
        title: any,
        cover_image: {
            url: string
        }
        description: any
        body: {
            image: {
                url: string
            },
            text: any,
            image_caption: any,
            youtube_video: {
                url: string
            },
            custom_component: string
        }[],
    },
    uid: string
}

export default class PrismicService {
    static async getArticles() {
        //@ts-ignore
        let response: Article[] = await client.getAllByType("article")
        return response.filter(article => article.data.enabled)
    }

    static async getArticle(uid: string) {
        //@ts-ignore
        let response: Article[] = await client.getAllByUIDs("article", [uid])
        return response
    }
}
import Token from "models/Token";
import { API, input } from "aws-amplify";

import * as queries from "../graphql/queries"
import * as mutations from "../graphql/mutations"

export default class TokenService {
    public static async getTokensByUserId(userId: string) {
        let nextToken: string;
        let result: Token[] = []
        do {
            let response: any = await API.graphql({query: queries.tokenByUserId, variables: {userId, nextToken}})
            let tokens = response.data.tokenByUserId.items as Token[]
            result = result.concat(tokens)
            nextToken = response.data.tokenByUserId.nextToken
        } while (nextToken)

        return result
    }

    public static async createToken(token: Token) {
        let response: any = await API.graphql({query: mutations.createToken, variables: {
            input: {...token}
        }})
        return response.data.createToken
    }

    public static async deleteToken(tokenId: string) {
        await API.graphql({query: mutations.deleteToken, variables: {
            input: {id: tokenId}
        }})
    }
}
import User from "models/User";
import React from "react"
import UserService from "services/UserService";
import ProfileCard from "./ProfileCard";
import ProfileCardLoading from "./ProfileCardLoading";

interface ProfileWrapperState {
    loading: boolean;
    user: User;
}

interface ProfileWrapperProps {
    userId: string
    editable: boolean
}

export default class ProfileCardWrapper extends React.Component<ProfileWrapperProps, ProfileWrapperState> {
    constructor(props: ProfileWrapperProps) {
        super(props)

        this.state = {
            loading: true,
            user: undefined
        }
    }

    async componentDidMount() {
        let user = await UserService.getUserById(this.props.userId)
        this.setState({user: user, loading: false})
    }

    render() {
        if (!this.state.loading && this.state.user) {
            return (
                <ProfileCard user={this.state.user} editable={this.props.editable}/>
            )
        } else {
            return (
                <ProfileCardLoading editable={this.props.editable}/>
            )
        }
    }
}
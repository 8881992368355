import React from "react"
import PowerAnalysis from "models/PowerAnalysis";
import PowerAnalysisService from "services/PowerAnalysisService";
import PowerAnalysisFeed from "./PowerAnalysisFeed";
import PowerAnalysisLoading from "./PowerAnalysisLoading";

interface PowerAnalysisFeedWrapperState {
    loading: boolean;
    powerAnalysis: PowerAnalysis[];
}

interface PowerAnalysisFeedWrapperProps {
    userId: string
    editable: boolean
}

export default class PowerAnalysisFeedWrapper extends React.Component<PowerAnalysisFeedWrapperProps, PowerAnalysisFeedWrapperState> {

    constructor(props: PowerAnalysisFeedWrapperProps) {
        super(props)

        this.state = {
            loading: true,
            powerAnalysis: []
        }
    }

    async componentDidMount() {
        let powerAnalysis = await PowerAnalysisService.getPowerAnalysisByUser(this.props.userId)
        this.setState({powerAnalysis: powerAnalysis, loading: false})
    }

    render() {
        if (!this.state.loading) {
            return (
                <PowerAnalysisFeed powerAnalysis={this.state.powerAnalysis} editable={this.props.editable} />
            )
        } else {
            return (
                <PowerAnalysisLoading editable={this.props.editable}/>
            )
        }
    }
}
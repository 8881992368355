import KeyPowerMetric from "../models/KeyPowerMetric";

import { API } from "aws-amplify";
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';
import { error } from "console";

export default class KeyPowerMetricService {
    public static async createKeyPowerMetric(keyPowerMetric: KeyPowerMetric) {
        let result: any = await API.graphql({
            query: mutations.createKeyPowerMetric, variables: {
                input: {
                    ...keyPowerMetric
                }
            }
        })
        return result.data.createKeyPowerMetric as KeyPowerMetric
    }

    public static async getKeyPowerMetric(id: string) {
        let result: any = await API.graphql({
            query: queries.getKeyPowerMetric, variables: {
                id: id
            }
        })
        let keyPowerMetric = result.data.getKeyPowerMetric as KeyPowerMetric
        if (keyPowerMetric) {
            return keyPowerMetric
        } else {
            throw Error(`Failed to find keyPowerMetric ${id}.`)
        }
    }

    public static async getKeyPowerMetricsByUser(userId: string) {
        try {
            let result: any = await API.graphql({
                query: queries.keyPowerMetricByUserId, variables: {
                    userId: userId
                }
            })
            return result.data.KeyPowerMetricByUserId.items as KeyPowerMetric[]
        } catch (err) {
            console.log(err)
            throw Error(`Failed to get keyPowerMetrics for user ${userId}, due to: ${err.message}`)
        }
    }

    public static async updateKeyPowerMetric(keyPowerMetric: KeyPowerMetric) {
        await API.graphql({
            query: mutations.updateKeyPowerMetric, variables: {
                input: {...keyPowerMetric}
            }
        })
    }

    public static async deleteKeyPowerMetricById(id: string) {
        await API.graphql({ query: mutations.deleteKeyPowerMetric, variables: { input: { id: id } } });
    }
}
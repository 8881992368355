import React from "react";
import { Card, CardBody, CardTitle, Container, Table } from "reactstrap";
import UserRequest from "../../models/UserRequest";
import UserRequestService from "../../services/UserRequestService";
import ConfidenceScoreTableRow from "./ConfidenceScoreTableRow";

interface AdminConfidenceScoreFeedState {
    confidenceScoreRequests: UserRequest[]
}

export default class AdminConfidenceScoreFeed extends React.Component<{}, AdminConfidenceScoreFeedState> {

    constructor(props: {}) {
        super(props)

        this.state = {
            confidenceScoreRequests: []
        }
    }

    async componentDidMount() {
        let confidenceScoreRequests = await UserRequestService.getConfidenceScoreRequests();
        this.setState({confidenceScoreRequests: confidenceScoreRequests})
    }

    render() {
        return (
            <Container className="top-buffer">
                <Card>
                    <CardBody>
                        <CardTitle><h2>Confidence Score Requests</h2></CardTitle>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>Requester</th>
                                    <th>Subject</th>
                                    <th>Request Reason</th>
                                    <th>Status</th>
                                    <th>Admin Notes</th>
                                    <th>Score</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.confidenceScoreRequests.map((confidenceScoreRequest) => {
                                    return (<ConfidenceScoreTableRow confidenceScoreRequest={confidenceScoreRequest}/>)
                                })}
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>
            </Container>
        )
    }
}
import { faEdit, faSave, faUndo } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import ReactMarkdown from "react-markdown"
import gfm from "remark-gfm"
import { Link } from "react-router-dom"
import { Button, ButtonGroup, Input, Progress } from "reactstrap"
import UserRequest from "../../models/UserRequest"
import UserRequestService from "../../services/UserRequestService"
import UserService from "../../services/UserService"

interface ConfidenceScoreTableRowProps {
    confidenceScoreRequest: UserRequest
}

interface ConfidenceScoreTableRowState {
    editing: boolean
    status: string
    statusReason: string
    score: string
    loading: boolean
}

enum UserRequestStatus {
    NOT_VIEWED = "Not Viewed",
    PENDING = "Pending",
    SUCCESS = "Success",
    DENIED = "Denied"
}

const DEFAULT_STATUS_REASON = `#### Confidence Score Report  
x/4 - Weigh in Video  
x/2 - Height Video  
x/2 - Calibration Video  
x/2 - 3 Sisters test  
x/4 - Outdoor verification  
x/14 - Total`

export default class ConfidenceScoreTableRow extends React.Component<ConfidenceScoreTableRowProps, ConfidenceScoreTableRowState> {

    constructor(props: ConfidenceScoreTableRowProps) {
        super(props)

        this.state = {
            editing: false,
            status: UserRequestStatus.NOT_VIEWED,
            statusReason: DEFAULT_STATUS_REASON,
            score: "",
            loading: false
        }
    }

    toggleEdit() {
        this.setState({
            editing: !this.state.editing,
            status: this.props.confidenceScoreRequest.status ? this.props.confidenceScoreRequest.status : UserRequestStatus.NOT_VIEWED,
            statusReason: this.props.confidenceScoreRequest.statusReason ? this.props.confidenceScoreRequest.statusReason : DEFAULT_STATUS_REASON,
            score: this.props.confidenceScoreRequest.subject.confidenceScore ? this.props.confidenceScoreRequest.subject.confidenceScore.toString() : "0"
        })
    }

    async save() {
        let userRequestResult: UserRequest = {
            id: this.props.confidenceScoreRequest.id,
            status: this.state.status,
            statusReason: this.state.statusReason
        }
        this.setState({ loading: true })
        try {
            await UserRequestService.updateUserRequest(userRequestResult);
            await UserService.updateUserById(this.props.confidenceScoreRequest.subjectId, {
                confidenceScore: +this.state.score,
                firstName: this.props.confidenceScoreRequest.subject.firstName,
                lastName: this.props.confidenceScoreRequest.subject.lastName
            })
            this.setState({loading: false})
        } catch (err) {
            console.log(err)
            this.setState({loading: false})
        }
        window.location.reload()
    }

    render() {
        if (!this.state.editing) {
            return (
                <tr>
                    <td><Link to={`/profile/${this.props.confidenceScoreRequest.userId}`}>{`${this.props.confidenceScoreRequest.user.firstName} ${this.props.confidenceScoreRequest.user.lastName}`}</Link></td>
                    <td><Link to={`/profile/${this.props.confidenceScoreRequest.subjectId}`}>{`${this.props.confidenceScoreRequest.subject.firstName} ${this.props.confidenceScoreRequest.subject.lastName}`}</Link></td>
                    <td><ReactMarkdown plugins={[gfm]}>{this.props.confidenceScoreRequest.requestReason}</ReactMarkdown></td>
                    <td>{this.props.confidenceScoreRequest.status}</td>
                    <td style={{ width: "30%" }}><ReactMarkdown plugins={[gfm]}>{this.props.confidenceScoreRequest.statusReason}</ReactMarkdown></td>
                    <td>{this.props.confidenceScoreRequest.user.confidenceScore}</td>
                    <td><Button size="sm" color="info" outline onClick={() => this.toggleEdit()}><FontAwesomeIcon size="sm" icon={faEdit} /></Button></td>
                </tr>
            )
        } else {
            return (
                <tr>
                    <td><Link to={`/profile/${this.props.confidenceScoreRequest.userId}`}>{`${this.props.confidenceScoreRequest.user.firstName} ${this.props.confidenceScoreRequest.user.lastName}`}</Link></td>
                    <td><Link to={`/profile/${this.props.confidenceScoreRequest.subjectId}`}>{`${this.props.confidenceScoreRequest.subject.firstName} ${this.props.confidenceScoreRequest.subject.lastName}`}</Link></td>
                    <td><ReactMarkdown plugins={[gfm]}>{this.props.confidenceScoreRequest.requestReason}</ReactMarkdown></td>
                    <td>
                        <select disabled={this.state.loading} className="form-control" value={this.state.status} onChange={(event) => this.setState({ status: event.target.value })}>
                            <option value={UserRequestStatus.NOT_VIEWED}>
                                {UserRequestStatus.NOT_VIEWED}
                            </option>
                            <option value={UserRequestStatus.PENDING}>
                                {UserRequestStatus.PENDING}
                            </option>
                            <option value={UserRequestStatus.SUCCESS}>
                                {UserRequestStatus.SUCCESS}
                            </option>
                            <option value={UserRequestStatus.DENIED}>
                                {UserRequestStatus.DENIED}
                            </option>
                        </select>
                    </td>
                    <td><Input disabled={this.state.loading} onChange={(event) => this.setState({statusReason: event.target.value})} value={this.state.statusReason} type="textarea" /></td>
                    <td><Input disabled={this.state.loading} style={{ minWidth: "60px" }} value={this.state.score} type="number" min={0} max={14} onChange={(event) => this.setState({ score: event.target.value })} /></td>
                    <td>
                        <ButtonGroup>
                            <Button disabled={this.state.loading} size="sm" color="warning" outline onClick={() => this.toggleEdit()}><FontAwesomeIcon size="sm" icon={faUndo} /></Button>
                            <Button disabled={this.state.loading} size="sm" color="primary" outline onClick={() => this.save()}><FontAwesomeIcon size="sm" icon={faSave} /></Button>
                        </ButtonGroup>
                    </td>
                </tr>
            )
        }
    }
}
import React from "react"
import { Card, CardBody, Container } from "reactstrap"
import PowerAnalysis, { PowerAnalysisData } from "../../models/PowerAnalysis"
import User from "../../models/User"
import PowerAnalysisInput from "./PowerAnalysisInput"
import PowerAnalysisOffsetWrapper from "./PowerAnalysisOffsetWrapper"
import PowerAnalysisSave from "./PowerAnalysisSave"
import PowerAnalysisView from "./PowerAnalysisView"

interface PowerAnalysisCreationState {
    step: number
    powerAnalysis: PowerAnalysis
}

interface PowerAnalysisCreationProps {
    user: User
}

export default class PowerAnalysisCreation extends React.Component<PowerAnalysisCreationProps, PowerAnalysisCreationState> {

    constructor(props: PowerAnalysisCreationProps) {
        super(props)

        this.state = {
            step: 1,
            powerAnalysis: null
        }
    }

    powerAnalysisOffsetUpdate(powerAnalysisData: PowerAnalysisData, offset: { start: number; end: number }): void {
        let powerAnalysis = { ...this.state.powerAnalysis }
        powerAnalysisData.startOffset = offset.start
        powerAnalysisData.endOffset = offset.end
        this.setState({ powerAnalysis })
    }

    render() {
        return (
            <Container className="top-buffer">
                <Card>
                    <CardBody>
                        {this.state.step === 1 &&
                            <PowerAnalysisInput nextStep={(powerAnalysis: PowerAnalysis) => this.setState({ powerAnalysis: powerAnalysis, step: this.state.step + 1 })} user={this.props.user} />}
                        {this.state.step === 2 &&
                            <PowerAnalysisOffsetWrapper powerAnalysis={this.state.powerAnalysis} offsetUpdate={(powerAnalysisData, offset) => this.powerAnalysisOffsetUpdate(powerAnalysisData, offset)} nextStep={() => this.setState({ step: this.state.step + 1 })} />}
                        {this.state.step === 3 &&
                            <PowerAnalysisView nextStep={() => this.setState({ step: this.state.step + 1 })} powerAnalysis={this.state.powerAnalysis} />}
                        {this.state.step === 4 &&
                            <PowerAnalysisSave powerAnalysis={this.state.powerAnalysis} />}
                    </CardBody>
                </Card>
            </Container>
        )
    }
}
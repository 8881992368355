import PrivacyInput from "components/PrivacyInput"
import React from "react"
import { Container, Form, FormGroup, Label, Col, Row, Input, ButtonGroup, Button } from "reactstrap"
import PrivacyService from "services/PrivacyService"
import Status, { StatusType } from "../../components/Status"
import Equipment from "../../models/Equipment"
import EquipmentService from "../../services/EquipmentService"

interface EquipmentMutateProps {
    userId: string
    equipment?: Equipment
}

interface EquipmentMutateState {
    make: string
    manualInputedMake: string
    model: string
    manualInputedModel: string
    notes: string
    proof: string
    type: string
    serialNumber: string
    public: boolean
    groupsCanAccess: { value: string, label: string }[]
    submitting: boolean
    submitted: boolean
    error: string
}

export default class EquipmentMutation extends React.Component<EquipmentMutateProps, EquipmentMutateState> {

    constructor(props: EquipmentMutateProps) {
        super(props)

        this.state = {
            type: "Smart trainer",
            make: "Other",
            manualInputedMake: "",
            model: "Other",
            manualInputedModel: "",
            notes: "",
            proof: "",
            serialNumber: "",
            public: true,
            groupsCanAccess: [PrivacyService.defaultPrivacySetting()],
            submitting: true,
            submitted: false,
            error: ""
        }
    }

    async componentDidMount() {
        if (this.props.equipment) {
            const equipment = this.props.equipment

            let valueToLabelMapping = await PrivacyService.valueToLabelMapping();
            let groupsCanAccess = equipment.groupsCanAccess.map((value) => {
                return {
                    value: value,
                    label: valueToLabelMapping.get(value)
                }
            })

            let equipmentMakes = EquipmentService.getBrands(equipment.type)
            let equipmentModels = EquipmentService.getModels(equipment.type, equipment.make)
            let newState = {
                type: EquipmentService.equipmentTypeEnumToLabel(equipment.type),
                make: equipmentMakes.includes(equipment.make) ? equipment.make : "Other",
                manualInputedMake: equipmentMakes.includes(equipment.make) ? "" : equipment.make,
                model: equipmentModels.includes(equipment.model) ? equipment.model : "Other",
                manualInputedModel: equipmentModels.includes(equipment.model) ? "" : equipment.model,
                notes: equipment.notes,
                proof: equipment.proof.length > 0 ? equipment.proof[0] : "",
                serialNumber: equipment.serialNumber,
                public: equipment.groupsCanAccess.includes("all"),
                groupsCanAccess: groupsCanAccess,
                submitting: false,
                submitted: false,
                error: ""
            }
            this.setState({...newState})
        } else {
            this.setState({submitting: false})
        }
    }

    async createNewEquipment(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault()
        try {
            this.setState({ submitting: true })
            if (this.props.equipment) {
                await EquipmentService.updateEquipment({ ...this.stateToModel(), id: this.props.equipment.id })
            } else {
                await EquipmentService.createNewEquipment(this.stateToModel())
            }
            this.setState({ submitting: false, submitted: true })
        } catch (err) {
            this.setState({ submitting: false, submitted: true, error: "Something went wrong, please try again." })
            console.log(err)
        }

    }

    stateToModel() {
        let model: Equipment = {
            userId: this.props.userId,
            type: EquipmentService.equipmentTypeLabelToEnum(this.state.type),
            make: this.state.make === "Other" ? this.state.manualInputedMake : this.state.make,
            model: this.state.model === "Other" ? this.state.manualInputedModel : this.state.model,
            notes: this.state.notes,
            proof: [this.state.proof],
            serialNumber: this.state.serialNumber,
            groupsCanAccess: this.state.groupsCanAccess.map((entry) => entry.value)
        }
        return model
    }

    render() {
        if (this.state.submitting) {
            return (
                <Status statusType={StatusType.LOADING} title="Submitting" />
            )
        } else if (this.state.submitted && this.state.error === "") {
            return (
                <Status statusType={StatusType.SUCCESS} message="Return to your profile page to view your new submission" />
            )
        } else if (this.state.submitted && this.state.error !== "") {
            return (
                <Status statusType={StatusType.FAIL} message={this.state.error} />
            )
        } else {
            return (
                <Container className="top-buffer">
                    <h2>Create/Update Equipment</h2>
                    <Form onSubmit={(event) => this.createNewEquipment(event)}>
                        <FormGroup row>
                            <Label md={4}>*Type:</Label>
                            <Col md={8}>
                                <select required value={this.state.type} onChange={(event) => this.setState({ type: event.target.value, make: "Other", model: "Other" })} className="form-control">
                                    {EquipmentService.getEquipmentTypeLabels().map((type: string) => {
                                        return (
                                            <option value={type}>{type}</option>
                                        )
                                    })}
                                </select>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label md={4}>*Make:</Label>
                            <Col md={8}>
                                <select required value={this.state.make} onChange={(event) => this.setState({ make: event.target.value, model: "Other" })} className="form-control">
                                    <option value={"Other"}>Other</option>
                                    {EquipmentService.getBrands(EquipmentService.equipmentTypeLabelToEnum(this.state.type)).map((brand: string) => {
                                        return (
                                            <option value={brand}>{brand}</option>
                                        )
                                    })}
                                </select>
                            </Col>
                        </FormGroup>
                        {this.state.make === "Other" &&
                            <FormGroup row>
                                <Label md={4}>*Make (if other is selected):</Label>
                                <Col md={8}>
                                    <Input required type="text" value={this.state.manualInputedMake} onChange={(event) => this.setState({ manualInputedMake: event.target.value })} />

                                </Col>
                            </FormGroup>
                        }
                        <FormGroup row>
                            <Label md={4}>*Model:</Label>
                            <Col md={8}>
                                <select required value={this.state.model} onChange={(event) => this.setState({ model: event.target.value })} className="form-control">
                                    <option value={"Other"}>Other</option>
                                    {EquipmentService.getModels(EquipmentService.equipmentTypeLabelToEnum(this.state.type), this.state.make).map((model: string) => {
                                        return (
                                            <option value={model}>{model}</option>
                                        )
                                    })}
                                </select>
                            </Col>
                        </FormGroup>
                        {this.state.model === "Other" &&
                            <FormGroup row>
                                <Label md={4}>*Model (if other is selected):</Label>
                                <Col md={8}>
                                    <Input required type="text" value={this.state.manualInputedModel} onChange={(event) => this.setState({ manualInputedModel: event.target.value })} />
                                </Col>
                            </FormGroup>
                        }
                        <FormGroup row>
                            <Label md={4}>Proof (url link to pictures):</Label>
                            <Col md={8}>
                                <Input type="text" value={this.state.proof} onChange={(event) => this.setState({ proof: event.target.value })} />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label md={4}>Serial Number:</Label>
                            <Col md={8}>
                                <Input type="text" value={this.state.serialNumber} onChange={(event) => this.setState({ serialNumber: event.target.value })} />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label md={4}>Notes:</Label>
                            <Col md={8}>
                                <Input type="textarea" value={this.state.notes} onChange={(event) => this.setState({ notes: event.target.value })} />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label md={4}>Permissions</Label>
                            <Col md={8}>
                                <PrivacyInput isMulti required value={this.state.groupsCanAccess} onChange={(selection) => this.setState({ groupsCanAccess: selection as { value: string, label: string }[]})} />
                            </Col>
                        </FormGroup>
                        <Button outline color="success" type="submit">Submit</Button>
                    </Form>
                </Container>
            )
        }
    }
}
import React from 'react';
import { Auth } from 'aws-amplify';
import { Button, ButtonGroup, Container } from 'reactstrap';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib-esm/types/Auth'
import { Link } from 'react-router-dom';

export const UNAUTHENTICATED_REDIRECT = "UNAUTHENTICATED_REDIRECT"

export default class SignInPage extends React.Component<{}, {}> {


    signInUser() {
        localStorage.setItem("UNAUTHENTICATED_REDIRECT", window.location.pathname)
        Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google })
    }
    render() {


        return (
            <div>
                <Container style={{ textAlign: "center", padding: "200px 20px" }}>
                    <h2>Sign In To Continue</h2>
                    <p>In order to protect our users data from being generally accessible, we require you to sign in before accessing this feature.</p>
                    <p><i>By signing in you acknowledge the creators of eBioPassport will protect your data to the best of our abilities. View our <Link to="/privacy">privacy page</Link> for more info.</i></p>
                    <ButtonGroup>
                        <Button outline onClick={() => this.signInUser()}>Sign In</Button>
                        <Button outline onClick={() => this.signInUser()}>Sign Up</Button>
                    </ButtonGroup>
                </Container>
            </div>
        )
    }
}
import { API, Auth, input } from "aws-amplify";
import * as mutations from '../graphql/mutations';
import * as customQueries from '../graphql/customQueries';
import User from "../models/User";
import ReactGA from "react-ga"

const USER_KEY = "USER_KEY"

export default class UserService {
    public static async createAndGetUser(data: any): Promise<User> {
        let user: User = this.loadUserFromLocal();
        if (!user) {
            user = await this.getUserById(data.username)
            if (!user) {
                let firstName: string;
                let lastName: string;
                let searchableName: string;
                try {
                    firstName = data.attributes.given_name as string
                    lastName = data.attributes.family_name as string
                    searchableName = lastName.toLowerCase() + firstName.toLowerCase()
                } catch {
                    firstName = ""
                    lastName = ""
                    searchableName = ""
                }
                
                let input = {
                    groupsCanAccess: "all",
                    id: data.username,
                    firstName: firstName,
                    lastName: lastName,
                    searchableName: searchableName
                }
                try {
                    await API.graphql({
                        query: mutations.createUser, variables: {
                            input: input
                        }
                    })
                    user = await this.getUserById(data.username)
                } catch (err) {
                    console.log(err)
                    ReactGA.exception({ description: "createAndGetUser.createUser", fatal: true })
                    throw(new Error(`Unable to create user: ${JSON.stringify(input)}`))
                }

            }
            localStorage.setItem(USER_KEY, JSON.stringify(user))
            user = this.loadUserFromLocal();
        }
        return user;
    }

    public static signOutUser() {
        console.log("removing user from local")
        localStorage.removeItem(USER_KEY)
    }

    public static loadUserFromLocal() {
        return JSON.parse(localStorage.getItem(USER_KEY)) as User
    }

    public static async getUserById(userId: string): Promise<User> {
        try {
            let user: any = await API.graphql({ query: customQueries.getFullUser, variables: { id: userId } })
            return user.data.getUser;
        } catch (err) {
            console.log(err)
            ReactGA.exception({ description: "getUserById.getFullUser", fatal: true })
        }
    }

    public static async updateUserById(userId: string, updatedUser: User) {
        let searchableName = updatedUser.firstName.toLowerCase() + updatedUser.lastName.toLowerCase()
        let user: any = await API.graphql({
            query: mutations.updateUser, variables: {
                input: {
                    ...updatedUser,
                    searchableName: searchableName,
                    id: userId
                }
            }
        })
        return user.data.updateUser
    }

    public static async getUsersByNearName(name: string): Promise<User[]> {
        let users: User[] = []
        try {
            let result: any = await API.graphql({
                query: customQueries.listUsers,
                variables: {
                    filter: {
                        searchableName: { contains: name.replace(/\s/g, '').toLowerCase() }
                    }
                }
            })
            users = users.concat(result.data.listUsers.items);
            let nextToken = result.data.listUsers.nextToken
            while (nextToken !== null) {
                result = await API.graphql({
                    query: customQueries.listUsers,
                    variables: {
                        filter: {
                            searchableName: { contains: name.replace(/\s/g, '').toLowerCase() }
                        },
                        nextToken: nextToken
                    }
                })
                users = users.concat(result.data.listUsers.items);
                nextToken = result.data.listUsers.nextToken
            }
            return users
        } catch (err) {
            console.log(err)
            ReactGA.exception({ description: "getUserByNearName.listUsers", fatal: true })
        }
    }

    static async deleteUserById(id: string) {
        await API.graphql({query: mutations.deleteUser, variables: {
            input: {
                id: id
            }
        }})
    }
}
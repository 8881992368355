import React from "react";
import { Col, Container, Row } from "reactstrap";
import weighIn from "./weighIn.jpg"
import fredDays from "./fredDays.jpg"
import startOfIndoorTraining from "./startOfIndoorTraining.jpg"
import newIndoorTrainerSetup from "./newIndoorTrainerSetup.jpg"
import becomingMoreWellRounded from "./becomingMoreWellRounded.jpg"
import PowerCurve from "pages/PowerAnalysis/PowerCurve";
import alwaysFueling from "./alwaysFueling.jpg"
import currentState from "./currentState.jpg"

const powerCurve2016 = new Map([[3600, 220.8], [2700, 225.4], [1800, 230.92000000000002], [1200, 248.4], [600, 254.84], [300, 281.52000000000004], [210.0, 310.04], [120, 349.6], [60, 483], [15, 817], [5, 902], [1, 980]])
const powerCurve2017 = new Map([[3600, 218.04000000000002], [2700, 226.32000000000002], [1800, 242.88000000000002], [1200, 259.44], [600, 273.24], [300, 345.92], [210.0, 360.64000000000004], [120, 425.04], [60, 596], [15, 955], [5, 985], [1, 1005]])
const powerCurve2018 = new Map([[3600, 253.0], [2700, 260.36], [1800, 268.64], [1200, 283.36], [600, 307.28000000000003], [300, 343.16], [210.0, 361.56], [120, 456.32], [60, 666], [15, 945], [5, 980], [1, 1013]])
const powerCurve2019 = new Map([[3600, 269.56], [2700, 289.8], [1800, 288.88], [1200, 298.08000000000004], [600, 324.76], [300, 356.96000000000004], [210.0, 384.56], [120, 422.28000000000003], [60, 535.44], [15, 1009], [5, 1074], [1, 1137]])
const powerCurve2020 = new Map([[3600, 289.8], [2700, 294.40000000000003], [1800, 313.72], [1200, 316.48], [600, 333.96000000000004], [300, 372.6], [210.0, 424.12], [120, 454.48], [60, 570.4], [15, 1013], [5, 1070], [1, 1108]])
const powerCurve2021 = new Map([[3600, 298], [2700, 308], [1800, 332], [1200, 339], [600, 359], [300, 402], [210.0, 441], [120, 504], [60, 688], [15, 1064], [5, 1109], [1, 1134]])

export default class WeighInCulture extends React.Component {
    render() {
        return (
            <div>
                <div style={{ position: "relative" }}>
                    <div style={{ zIndex: 3, color: "white", position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)", padding: "20px", backgroundColor: "rgb(0,0,0,0.8)" }}><h1>Our Obsession with Weight</h1></div>
                    <div style={{ zIndex: 2, position: "absolute", width: "100%", height: "100%", backgroundColor: "rgb(0,0,0,0.3)" }}></div>
                    <img src={weighIn} style={{ zIndex: 1, width: "100%", objectFit: "cover", height: "500px", filter: "blur(3px)" }}></img>
                </div>
                <Container className="top-buffer">
                    <h1>My Ever Changing Relationship with Food</h1>
                    <p>I’d like to preface my thoughts by acknowledging that I’ve never been diagnosed with an eating disorder, and that certain things would have come easier for me than others.</p>
                    <p>If you don’t care to spend 10 minutes reading my story, the conclusion I am anecdotally coming to is as follows. As cyclists, we fixate on WKGs and specifically the KG part of the equation. In my early days I was obsessed with the weight side of the equation and thought it was the key to achieving results. I’ve since shifted my focus to increasing raw power while fueling properly and have since become a stronger cyclist in every aspect.</p>
                    <p>In recent months, there has been a lot of talk relating to the unhealthy and unreasonable weigh-in culture we are all experiencing. I believe that weigh-ins are a necessary part of e-racing however they should never have to be public, and organizers should be reasonable about time windows for valid weigh ins.</p>
                    <p>In light of all this negativity surrounding weight, I thought it would be nice to share my positive personal experience with weight and performance. I’m by no stretch of the imagination a world class athlete, or a genetic anomaly and I hope that cyclists can hear and relate to the path which I’ve been on.</p>
                    <h1>The Early Days</h1>
                    <Row>
                        <Col sm={12} lg={6} className="top-buffer my-auto text-center">
                            <img style={{ width: "100%" }} src={fredDays}></img>
                            <p className="text-muted">My roommate (right) and I on one of our first bike rides. Some would say these were my prime "fred" years.</p>
                        </Col>
                        <Col className="top-buffer my-auto">
                            <p>I first started riding more seriously in 2016 after making some money during an internship. Most of the knowledge and opinions I developed where based off YouTube channels especially GCN and The Vegan Cyclist. At the time, these YouTubers represented the peak of human performance for me and I took a lot of the advice from those channels very seriously. One positive thing that each of those influencers encouraged was training with power, and as a result I bought a stages power meter within a couple weeks of buying my bike.</p>
                        </Col>
                    </Row>
                    <p>When I first started riding I had no concept of intervals or any knowledge of power zones so my first summer was spent trying to ride up short hills as fast as I could and push out different parts of my power curve with each ride. After a couple months of riding I did a <a href="https://www.strava.com/activities/664941800/analysis/1472/2668" target="_blank">20-minute test</a> and managed 258w at 64kg. This is where the obsession with weight for me really began. Like many cyclists I fell into the trap of “my ftp is X, so I just need to lose Y kgs to get to Z WKGs”.</p>
                    <p>The 4wkg FTP was seems to have been put up as the ultimate goal for a lot of cyclists and I was no different. A 258w 20 minute test multiplied by 95% gave me a 245w FTP and thus I became fixated on losing weight and hitting 62kgs so I could say I have a 4wkg FTP (with some rounding up of course). I was already very lean to begin, and was probably in the 8-10% body fat range based on pictures however it never crossed my mind that I should instead focus on improving my raw wattage while maintaining weight. I would skip meals and drink water when hungry to cut weight, going to bed hungry would make me feel good about myself. Eventually I did get down to 62kgs and in the reality I lived in as relatively newbie cyclist, I thought I had reached my genetic potential.</p>
                    <p>The reason I was so focused on weight was because it seemed like I could control much easier. There are many resources on the internet that calculate weight loss based on caloric deficit. However, increasing power isn’t as directly computable, there’s no formula saying these intervals are guaranteed to give you a 10w increase. I also didn’t understand how high the power ceiling was, and didn’t realize how much room there was for improving raw power.</p>
                    <h1>The Start of Indoor Training and Racing</h1>
                    <Row>
                        <Col sm={12} lg={6} className="top-buffer my-auto text-center">
                            <img style={{ width: "100%", height: "350px", objectFit: "cover" }} src={startOfIndoorTraining}></img>
                            <p className="text-muted">One of the many homes of my first indoor trainers.</p>
                        </Col>
                        <Col sm={12} lg={6} className="top-buffer my-auto text-center">
                            <img style={{ width: "100%", height: "350px", objectFit: "cover" }} src={newIndoorTrainerSetup}></img>
                            <p className="text-muted">My upgraded indoor training setup during quarantine.</p>
                        </Col>
                        <Col className="top-buffer my-auto">
                            <p>	In the start of 2018 I got a wheel on smart trainer and followed the Zwift 4 week ftp booster training plan almost religiously. I managed <a href="https://www.strava.com/activities/1383924699/analysis/2542/3742" target="_blank">307w for 20 minutes</a> during my post plan FTP test. I had also taken up some weight lifting in the winter and was at a more “healthy looking weight” of 65kg putting me at a 4.5wkg FTP. It’s fair to say I was blown away by my performance and was convinced once again that I had reached my genetic potential. </p>
                        </Col>
                    </Row>
                    <p>The rest of the winter I logged more consistent training than ever, but still restrained my food intake thinking I would be faster if I got back down to 62kgs although in less extreme ways as I had before. I was proud to finish 41st in the Canadian national Zwift championships that year holding what used to be my best 20-minute power for the full 1.5hr event.</p>
                    <p>Throughout the summer my theory of reaching my genetic potential further engrained itself in my head as my weight and power seemed to go unchanged regardless of what type of training I did. However, I was starting to relax more about my diet and just ate to maintain my weight.</p>
                    <h1>Increasing Weight and Power</h1>
                    <Row>
                        <Col sm={12} lg={6} className="top-buffer my-auto text-center">
                            <img style={{ width: "100%", height: "350px", objectFit: "cover" }} src={becomingMoreWellRounded}></img>
                            <p className="text-muted">Taking up rock climbing and becoming a more well rounded athlete.</p>
                        </Col>
                        <Col className="top-buffer my-auto">
                            <p>	Over the summer of 2018 I had started rock climbing and was developing more upper body strength and saw my weight get up to 67kgs occasionally but weighed in on averaged at 66kgs. My power numbers had also gone up proportionally and I was overall a more well-rounded and healthier athlete.</p>
                            <p>	During the fall of 2018 I was fortunate enough to intern at Freshii and they essentially provided me an unlimited supply of highly nutritious, tasty, and convenient food. I would routinely have 3000 calories of Freshii Monday to Friday. By now I was no longer obsessing over trying to stay lean and go to bed hungry. Instead I adopted the policy of eating as much healthy food as I wanted since I knew I’d be burning lots of calories on the bike.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="top-buffer my-auto">
                            <p>	Then in 2020, in addition to my methodology of eating as much food as I wanted, I also started drinking carbs on every ride. I focused on eating before rides and never coming into a ride empty, as well as eating after rides to help with the recovery process. The result of these changes has been an increase in body weight to 67-68kgs, I’ve even seen 69kgs at the end of recovery weeks.</p>
                            <p>My power gain has also outstripped my weight gain with my ftp growing to 330 watts, which actually is an understatement of how much I’ve improved, since in 2020 I switched from a stages to power tap pedals which read 8% lower than my stages due to my left-right leg power imbalance (power meter discrepancies is a story for another time). In the past months I’ve been posting the best numbers of my life from sprints to long distance endurance efforts. In addition, I have a much better relationship with food.</p>
                        </Col>
                        <Col sm={12} md={6} lg={3} className="top-buffer my-auto text-center">
                            <img style={{ width: "100%", objectFit: "cover" }} src={alwaysFueling}></img>
                            <p className="text-muted">I now drink one bottle of carbs every 45 minutes regardless of how easy I'm riding.</p>
                        </Col>
                    </Row>
                    <h1>Concluding Thoughts</h1>
                    <Row>
                        <Col sm={12} md={6} lg={3} className="top-buffer my-auto text-center">
                            <img style={{ width: "100%", objectFit: "cover" }} src={currentState}></img>
                            <p className="text-muted">I'm now more bulky than when I started riding but overall stronger.</p>
                        </Col>
                        <Col className="top-buffer my-auto">
                            <p>	As an athlete I’ve come a long way from the 62kg rider pushing 245 watts to my current fitness level. I’ve gone through different phases and my relationship with food has constantly been evolving. It was freeing to stop worrying so much about weight and focus on other aspects of performance. However, I still feel a sense of guilt when I weigh in for a race and see 68kg instead of 67kg despite knowing that the 1kg difference likely won’t matter. My point being that even though I’ve come a long way towards a healthy mindset surrounding body weight, there will always be work to be done and room for improvement. </p>
                        </Col>
                    </Row>
                    <PowerCurve datas={[powerCurve2016, powerCurve2017, powerCurve2018, powerCurve2019, powerCurve2020, powerCurve2021]} deviceNames={["2016", "2017", "2018", "2019", "2020", "2021"]} />
                    <p className="text-muted center-text">My power curve over the years, with the data adjusted to reflect my stages reading lower than my power tap pedals.</p>
                </Container>
            </div>
        )
    }
}
import KeyPowerMetric from "models/KeyPowerMetric";
import React from "react"
import KeyPowerMetricService from "services/KeyPowerMetricService";
import KeyPowerMetricFeed from "./KeyPowerMetricFeed";
import KeyPowerMetricLoading from "./KeyPowerMetricLoading";

interface KeyPowerMetricFeedWrapperState {
    loading: boolean;
    keyPowerMetrics: KeyPowerMetric[];
}

interface KeyPowerMetricFeedWrapperProps {
    userId: string
    editable: boolean
}

export default class KeyPowerMetricFeedWrapper extends React.Component<KeyPowerMetricFeedWrapperProps, KeyPowerMetricFeedWrapperState> {
    constructor(props: KeyPowerMetricFeedWrapperProps) {
        super(props) 
        this.state = {
            loading: true,
            keyPowerMetrics: []
        }
    }

    async componentDidMount() {
        let keyPowerMetrics = await KeyPowerMetricService.getKeyPowerMetricsByUser(this.props.userId)
        this.setState({keyPowerMetrics: keyPowerMetrics, loading: false})
    }

    render() {
        if (!this.state.loading) {
            return (
                <KeyPowerMetricFeed keyPowerMetrics={this.state.keyPowerMetrics} editable={this.props.editable}/>
            )
        } else {
            return (
                <KeyPowerMetricLoading editable={this.props.editable}/>
            )
        }
    }
}
import React from "react";
import { Button, ButtonGroup, Col, Container, Form, FormGroup, Input, Label } from "reactstrap";
import Status, { StatusType } from "../../components/Status";
import UserRequest, { UserRequestType } from "../../models/UserRequest";
import UserRequestService from "../../services/UserRequestService";

interface UserRequestCreationProps {
    userRequestType: UserRequestType
    loggedInUserId: string
}

interface UserRequestCreationState {
    userRequestType: string
    requestReason: string
    public: boolean
    submitted: boolean
    submitting: boolean
    error: string
}

export default class UserRequestCreation extends React.Component<UserRequestCreationProps, UserRequestCreationState> {

    constructor(props: UserRequestCreationProps) {
        super(props)

        this.state = {
            userRequestType: UserRequestService.userRequestTypeEnumToLabel(this.props.userRequestType),
            requestReason: "",
            public: true,
            submitted: false,
            submitting: false,
            error: ""
        }
    }

    stateToModel(): UserRequest {
        return {
            subjectId: this.props.loggedInUserId,
            userId: this.props.loggedInUserId,
            userRequestType: UserRequestService.userRequestTypeLabelToEnum(this.state.userRequestType),
            requestReason: this.state.requestReason,
            groupsCanAccess: this.state.public ? ["all"] : []
        }
    }

    async createNewUserRequest(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault()

        try {
            this.setState({submitting: true})
            await UserRequestService.createUserRequest(this.stateToModel())
            this.setState({submitted: true, submitting: false})
        } catch (err) {
            console.log(err)
            this.setState({submitting: false, submitted: true, error: "Something went wrong, please try again."})
        }
    }

    render() {
        if (this.state.submitting) {
            return (
                <Status statusType={StatusType.LOADING} title="Submitting"/>
            )
        } else if (this.state.submitted && this.state.error === "") {
            return (
                <Status statusType={StatusType.SUCCESS} message="Return to your profile page to view your new submission"/>
            )
        } else if (this.state.submitted && this.state.error !== "") {
            return (
                <Status statusType={StatusType.FAIL} message={this.state.error}/>

            )
        }
        return (
            <Container className="top-buffer">
                <h2>User Request</h2>
                <Form onSubmit={(event) => this.createNewUserRequest(event)}>
                    <FormGroup row>
                        <Label md={4}>*Request Type:</Label>
                        <Col md={8}>
                            <select required value={this.state.userRequestType} onChange={(event) => this.setState({ userRequestType: event.target.value })} className="form-control">
                                {UserRequestService.getUserRequestTypeLabels().map((type: string) => {
                                    return (
                                        <option value={type}>{type}</option>
                                    )
                                })}
                            </select>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label md={4}>*Reason for request:</Label>
                        <Col md={8}>
                            <Input required type="textarea" value={this.state.requestReason} placeholder="Enter inquiry here..." onChange={(event) => this.setState({ requestReason: event.target.value })} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label md={4}>*Permissions</Label>
                        <Col md={8}>
                            <ButtonGroup>
                                <Button color={this.state.public ? "primary" : "secondary"} outline={!this.state.public} onClick={() => this.setState({public: true})} type="button">Public</Button>
                                <Button color={!this.state.public ? "primary" : "secondary"} outline={this.state.public} onClick={() => this.setState({public: false})} type="button">Private</Button>
                            </ButtonGroup>
                        </Col>
                    </FormGroup>
                    <Button outline color="success" type="submit">Submit</Button>
                </Form>
            </Container>
        )
    }
}
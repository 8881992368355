import { faPlusCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import ReactMarkdown from "react-markdown"
import gfm from "remark-gfm"
import { Link } from "react-router-dom"
import { Button, Card, CardBody, CardText, CardTitle, Col, Container, Row, Table } from "reactstrap"
import User from "../../models/User"

interface UserRequestFeedProps {
    user: User
}


export default class UserRequestFeed extends React.Component<UserRequestFeedProps> {

    render() {
        return (
            <Container className="top-buffer">
                <Card>
                    <CardBody>
                        <CardTitle>
                            <Row>
                                <Col>
                                    <h2>Confidence Score Requests</h2>
                                </Col>
                                <Col xs={2}>
                                    <Button style={{ float: "right" }} outline color="success"><Link style={{ color: "inherit" }} to="/confidence_score_creation"><FontAwesomeIcon icon={faPlusCircle} /></Link></Button>
                                </Col>
                            </Row>
                        </CardTitle>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>Status</th>
                                    <th>Reason</th>
                                    <th>Admin Notes</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.user.userRequests.items.map((userRequest) => {
                                    return (<tr>
                                        <td>{userRequest.status}</td>
                                        <td><ReactMarkdown plugins={[gfm]}>{userRequest.requestReason}</ReactMarkdown></td>
                                        <td><ReactMarkdown plugins={[gfm]}>{userRequest.statusReason}</ReactMarkdown></td>
                                    </tr>)
                                })}
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>
                <Card className="top-buffer">
                    <CardBody>
                    <h2>Confidence Score</h2>
                        <CardText>
                            <p>Each user is assigned a confidence score by system admins based on criteria listed below. This score is meant to represent how likely it is the user is legitimate, with a higher score being better.</p>
                        </CardText>
                        <h2>How scores are assigned</h2>
                        <CardText>
                            <p>A users score is the sum of the following criteria:</p>
                            <ol>
                                <li>Weigh in videos (4pts): If the user has a weigh in video with a marked weight to prove the calibration of their scale they recieve 4 points. If they don't use a standard calibration weight they get 2 point.</li>
                                <li>Height in video (2pts): If the user has a height in video they recieve 2 points. If there are any issues with the video such as a crooked measuring tape the user may only get 1 point.</li>
                                <li>Calibration video (2pts): If the user has a video of them calibrating their power meter or smart trainer they recieve 2 points. If there is any issues with the video the user may only get 1 point.</li>
                                <li>Three sisters test (2pts): If the user has a video of the three sisters test they will recieve 2 points. If there are any issues with the video the user may only recieve 1 point.</li>
                                <li>Outdoor verification (4pts): If the user has linked outdoor performances for their 15 second, 1, 5 and 20 minute power which matches with their outdoor power they will recieve 1 point per power zone. If there is an issue with any of the performances such as lack of heart rate the user may only recieve 0.5 points.</li>
                            </ol>
                            <p>The system for judging scores are subject to change and admins will do the best of their abilities to uphold the system.</p>
                        </CardText>
                        <h2>Requesting a Confidence Score Evaluation</h2>
                        <CardText>
                            <p>If you haven't had your confidence score evaluated or you wish to have it re-evaluated, you can open an inquiry <Link to="/confidence_score_creation">here</Link>. Note that submitting multiple requests won't make the process any faster and will slow it down for you and others.</p>
                        </CardText>
                    </CardBody>
                </Card>
            </Container>
        )
    }
}
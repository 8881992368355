import Equipment from "./Equipment"
import { EventOrganizer } from "./EventOrganizer"
import KeyPowerMetric from "./KeyPowerMetric"
import PowerAnalysis from "./PowerAnalysis"
import User from "./User"
import { VideoEntry } from "./VideoEntry"

export default interface VerificationRequest {
    id?: string
    eventOrganizerId?: string
    eventOrganizer?: EventOrganizer
    title?: string
    notes?: string
    status?: VerificationRequestStatus
    userId?: string
    user?: User
    verificationRequestToVideoEntries?: {items: {videoEntry: VideoEntry}[]}
    verificationRequestToKeyPowerMetrics?: {items: {keyPowerMetric: KeyPowerMetric}[]}
    verificationRequestToEquipment?: {items?: {equipment: Equipment}[]}
    verificationRequestToPowerAnalysis?: {items?: {powerAnalysis: PowerAnalysis}[]}
    groupsCanAccess?: string[]
    createdAt?: string
}

export enum VerificationRequestStatus {
    ACCEPTED = "ACCEPTED",
    DENIED= "DENIED",
    SUBMITTED = "SUBMITTED",
    PENDING = "PENDING"
}
import React from 'react'
import PrismicService, { Article } from 'services/PrismicService';
import * as prismicH from '@prismicio/helpers'
import "./Article.css"
import { Col, Row } from 'reactstrap';

interface ArticlesState {
    loading: boolean;
    articles: Article[]
}

export default class Articles extends React.Component<{}, ArticlesState> {
    constructor(props: {}) {
        super(props);

        this.state = {
            loading: true,
            articles: []
        }
    }

    async componentDidMount() {
        let articles = await PrismicService.getArticles();
        this.setState({ articles })
    }

    render(): React.ReactNode {
        return (
            <div className='container'>
                <Row>
                    {this.state.articles.map((article: Article) => {
                        return (
                            <Col sm={12} md={4}>
                                <div className='center'>
                                    <a href={`/articles/${article.uid}`}>
                                        <div className='article-card top-buffer' style={{
                                            background: `linear-gradient( 180deg, transparent 0%, #121212 80%, #000000 100% ), url(${article.data.cover_image.url})`,
                                            backgroundSize: "cover",
                                            backgroundPosition: "center"
                                        }}>
                                            <div className='article-description'>
                                                <div dangerouslySetInnerHTML={{__html: prismicH.asHTML(article.data.title)}}/>
                                                <div dangerouslySetInnerHTML={{__html: prismicH.asHTML(article.data.description)}}/>
                                            </div>

                                        </div>
                                    </a>
                                </div>

                            </Col>

                        )
                    })}
                </Row>
            </div>
        )
    }
}
import React from "react"
import ContentLoader from "react-content-loader"
import { Card, CardBody, Table } from "reactstrap"

export default class VerificationLoading extends React.Component {
    render() {
        return (
            <Card>
                <CardBody>
                    <h2>Verification</h2>
                    <Table responsive size="sm">
                        <thead>
                            <th>Organizer</th>
                            <th>Status</th>
                        </thead>
                        <tbody>
                            {[0, 1, 2, 3, 4].map(verificationStatus => {
                                return (
                                    <tr>
                                        <td>
                                            <ContentLoader viewBox="0 0 100% 30" height={30} width={"100%"}>
                                                <rect x="0" y="5" width="100%" height="20" />
                                            </ContentLoader>
                                        </td>
                                        <td>
                                            <ContentLoader viewBox="0 0 100% 30" height={30} width={"100%"}>
                                                <circle cx="20" cy="15" r="10" />
                                            </ContentLoader>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
        )
    }
}
import Status, { StatusType } from "components/Status"
import { EventOrganizer } from "models/EventOrganizer"
import React from "react"
import { Button, Card, CardBody, CardText, CardTitle, Col, Container, Form, FormGroup, Input, Label } from "reactstrap"
import EventOrganizerService from "services/EventOrganizerService"

interface EventOrganizerMutationState {
    label: string;
    permissionGroup: string;
    email: string;
    submitting: boolean
    submitted: boolean
    error: string
}

export default class EventOrganizerMutation extends React.Component<{}, EventOrganizerMutationState> {

    constructor(props: {}) {
        super(props)

        this.state = {
            label: "",
            permissionGroup: "",
            email: "",
            submitting: false,
            submitted: false,
            error: ""
        }
    }

    stateToModel(): EventOrganizer {
        return {
            label: this.state.label,
            permissionGroup: this.state.permissionGroup,
            email: this.state.email,
            groupsCanAccess: ["all"],
        }
    }

    async submit (event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault()
        try {
            this.setState({submitting: true})
            await EventOrganizerService.createEventOrganizer(this.stateToModel())
            this.setState({submitted: true, submitting: false})
        } catch(err) {
            this.setState({submitted: true, submitting: false, error: err.message})
        }
    }

    render() {
        if (this.state.submitting) {
            return (
                <Status statusType={StatusType.LOADING} title="Submitting" />
            )
        } else if (this.state.submitted && this.state.error === "") {
            return (
                <Status statusType={StatusType.SUCCESS} />
            )
        } else if (this.state.submitted && this.state.error !== "") {
            return (
                <Status statusType={StatusType.FAIL} message={this.state.error} />

            )
        }
        return (
            <Container className="top-buffer">
                <Card>
                    <CardBody>
                        <CardTitle>
                            <h2>Event Organizer Creation/Update</h2>
                        </CardTitle>
                        <CardText>
                            <Form onSubmit={(event) => this.submit(event)}>
                                <FormGroup row>
                                    <Label md={4}>Label:</Label>
                                    <Col md={8}>
                                        <Input type="text" value={this.state.label} onChange={(event) => this.setState({label: event.target.value})}/>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label md={4}>Permission group:</Label>
                                    <Col md={8}>
                                        <Input type="text" value={this.state.permissionGroup} onChange={(event) => this.setState({permissionGroup: event.target.value})}/>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label md={4}>Email:</Label>
                                    <Col md={8}>
                                        <Input type="email" value={this.state.email} onChange={(event) => this.setState({email: event.target.value})}/>
                                    </Col>
                                </FormGroup>
                                <Button type="submit">Submit</Button>
                            </Form>
                        </CardText>
                    </CardBody>
                </Card>
            </Container>
        )
    }
}
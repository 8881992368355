import PowerAnalysis, { PowerAnalysisData } from "models/PowerAnalysis";
import React from "react"
import { Card } from "reactstrap";
import PowerAnalysisOffsetEditor from "./PowerAnalysisOffsetEditor";

interface PowerAnalysisOffsetWrapperProps {
    powerAnalysis: PowerAnalysis
    offsetUpdate: (powerAnalysisData: PowerAnalysisData, offset: {start: number, end: number}) => void
    nextStep?: () => void
}

export default class PowerAnalysisOffsetWrapper extends React.Component<PowerAnalysisOffsetWrapperProps> {
    render() {
        return (
            <div>
                <PowerAnalysisOffsetEditor 
                    powerAnalysis={this.props.powerAnalysis} 
                    offsetUpdate={(powerAnalysisData, offset) => this.props.offsetUpdate(powerAnalysisData, offset)}
                    nextStep={() => this.props.nextStep()}/>
            </div>
        )
    }
}
import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import './App.css';
import ReactGA from "react-ga"
import { Auth, Hub } from 'aws-amplify';
import UserService from 'services/UserService'
import User from 'models/User';
import Home from 'pages/Home/Home'
import MyNavBar from 'components/Navbar';
import SignInPage, { UNAUTHENTICATED_REDIRECT } from './pages/SignInPage';
import Profile from 'pages/Profile/Profile';
import VideoEntryCreation from 'pages/Video/VideoEntryCreation';
import KeyPowerMetricMutate from 'pages/KeyPowerMetrics/KeyPowerMetricMutate';
import VideoEntryDetail from 'pages/Video/VideoEntryDetail';
import ReleaseNotes from 'pages/ReleaseNotes';
import Status, { StatusType } from 'components/Status';
import EquipmentMutation from 'pages/Equipment/EquipmentMutation';
import UserSearchCard from 'pages/Home/UserSearchCard';
import UserRequestCreation from 'pages/ConfidenceScore/UserRequestCreation';
import { UserRequestType } from 'models/UserRequest';
import AdminConfidenceScoreFeed from 'pages/Admin/AdminConfidenceScoreFeed';
import UserRequestFeed from 'pages/ConfidenceScore/UserRequestFeed';
import PowerAnalysisCreation from 'pages/PowerAnalysis/PowerAnalysisCreation';
import PowerAnalysisViewWrapper from 'pages/PowerAnalysis/PowerAnalysisViewWrapper';
import UpdateKeyPowerMetric from 'pages/KeyPowerMetrics/UpdateKeyPowerMetric';
import EquipmentUpdate from 'pages/Equipment/EquipmentUpdate';
import Footer from 'components/Footer';
import Privacy from 'pages/Privacy';
import DataExport from 'pages/GDBR/DataExport';
import BattleFor16A4 from 'pages/News/BattleFor16A4/BattleFor16A4';
import EventOrganizerMutation from 'pages/Admin/EventOrganizerMutation';
import VerificationRequestMutation from 'pages/VerificationRequest/VerificationRequestMutation';
import VerificationRequestFeed from 'pages/Admin/VerificationRequestFeed';
import WeighInCulture from 'pages/News/WeighInCulture/WeighInCulture';
import Articles from 'pages/Articles/Articles';
import Article from 'pages/Articles/Article';
import LineChart from 'components/LineChart/LineChart';
import WCPowerCurve from 'pages/Articles/CustomComponents/WCPowerCurve/WCPowerCurve';

interface AppState {
    user: User;
    loading: boolean;
    redirect: string;
    error: string;
}

ReactGA.initialize("UA-187864791-1");
export default class App extends React.Component<{}, AppState> {

    constructor(props: {}) {
        super(props);

        this.state = {
            user: undefined,
            loading: false,
            redirect: "",
            error: ""
        }

    }

    trackPage() {
        ReactGA.set({ page: window.location.pathname });
        ReactGA.pageview(window.location.pathname);
    }

    async componentDidMount() {
        UserService.signOutUser()
        Hub.listen("auth", async ({ payload: { event, data } }) => {
            switch (event) {
                case "signIn":
                    break;
                case "signOut":
                    UserService.signOutUser();
                    this.setState({ user: null });
                    break;
            }
        });

        Auth.currentAuthenticatedUser()
            .then(async (data) => {
                this.setState({ loading: true })
                let user = await UserService.createAndGetUser(data);
                if (localStorage.getItem(UNAUTHENTICATED_REDIRECT) !== null) {
                    let redirect = localStorage.getItem(UNAUTHENTICATED_REDIRECT);
                    localStorage.removeItem(UNAUTHENTICATED_REDIRECT)
                    ReactGA.set({ userId: user.id })
                    this.setState({ user: user, loading: false, redirect: redirect });
                } else {
                    this.setState({ user: user, loading: false, redirect: "" });
                }
                console.log("Signed in")
            })
            .catch((err: Error) => {
                if (err instanceof Error) {
                    this.setState({ loading: false, error: err.message })
                }
                else { // if user isn't signed in we go here
                    this.setState({ loading: false })
                }
                UserService.signOutUser();
                console.log("Not signed in")
            });
    }

    render() {
        if (this.state.loading) {
            return (
                <div className="App">
                    <Status statusType={StatusType.LOADING} />
                </div>
            )
        } else if (this.state.error !== "") {
            return (
                <div className="App">
                    <Status statusType={StatusType.FAIL} message={this.state.error} />
                </div>
            )
        } else {
            return (
                <div className="App">
                    <Router>
                        {this.state.redirect !== "" && <Redirect to={this.state.redirect}></Redirect>}
                        <div>
                            <MyNavBar />
                            <Switch>
                                <Route path="/profile/:id" render={(props) => {
                                    let id = props.match.params.id as string
                                    this.trackPage()
                                    if (!this.state.user) {
                                        return <SignInPage />
                                    }
                                    return <Profile key={id} userId={id} loggedInUser={this.state.user} />
                                }} />
                                <Route path="/my_profile" render={() => {
                                    this.trackPage()
                                    if (!this.state.user) {
                                        return <SignInPage />
                                    }
                                    return <Profile key={this.state.user.id} userId={this.state.user.id} loggedInUser={this.state.user} />
                                }} />
                                <Route path="/video_entry/edit/:id" render={(props) => {
                                    let id = props.match.params.id as string
                                    this.trackPage()
                                    if (!this.state.user) {
                                        return <SignInPage />
                                    }
                                    return <VideoEntryCreation userId={this.state.user.id} videoEntryToEditId={id} />
                                }} />
                                <Route path="/video_entry/:id" render={(props) => {
                                    let id = props.match.params.id as string
                                    this.trackPage()
                                    if (!this.state.user) {
                                        return <SignInPage />
                                    }
                                    return <VideoEntryDetail id={id} loggedInUser={this.state.user} />
                                }} />
                                <Route path="/power_analysis/:id" render={(props) => {
                                    let id = props.match.params.id as string
                                    this.trackPage()
                                    if (!this.state.user) {
                                        return <SignInPage />
                                    }
                                    return <PowerAnalysisViewWrapper id={id} />
                                }} />
                                <Route path="/key_power_metric_edit/:id" render={(props) => {
                                    let id = props.match.params.id as string
                                    this.trackPage()
                                    if (!this.state.user) {
                                        return <SignInPage />
                                    }
                                    return <UpdateKeyPowerMetric userId={this.state.user.id} keyPowerMetricId={id} />
                                }} />
                                <Route path="/equipment_edit/:id" render={(props) => {
                                    let id = props.match.params.id as string
                                    this.trackPage()
                                    if (!this.state.user) {
                                        return <SignInPage />
                                    }
                                    return <EquipmentUpdate userId={this.state.user.id} equipmentId={id} />
                                }} />
                                <Route path="/video_entry_creation" render={(props) => {
                                    this.trackPage()
                                    if (!this.state.user) {
                                        return <SignInPage />
                                    }
                                    return <VideoEntryCreation userId={this.state.user.id} />
                                }} />
                                <Route path="/search_users" render={(props) => {
                                    this.trackPage()
                                    if (!this.state.user) {
                                        return <SignInPage />
                                    }
                                    return <UserSearchCard />
                                }} />
                                <Route path="/key_power_metric_creation" render={(props) => {
                                    this.trackPage()
                                    if (!this.state.user) {
                                        return <SignInPage />
                                    }
                                    return <KeyPowerMetricMutate userId={this.state.user.id} />
                                }} />
                                <Route path="/equipment_creation" render={(props) => {
                                    this.trackPage()
                                    if (!this.state.user) {
                                        return <SignInPage />
                                    }
                                    return <EquipmentMutation userId={this.state.user.id} />
                                }} />
                                <Route path="/confidence_score_creation" render={(props) => {
                                    this.trackPage()
                                    if (!this.state.user) {
                                        return <SignInPage />
                                    }
                                    return <UserRequestCreation userRequestType={UserRequestType.CONFIDENCE_SCORE_ASSESMENT} loggedInUserId={this.state.user.id} />
                                }} />
                                <Route path="/verification_request_creation" render={(props) => {
                                    this.trackPage()
                                    if (!this.state.user) {
                                        return <SignInPage />
                                    }
                                    return <VerificationRequestMutation userId={this.state.user.id} />
                                }} />
                                <Route path="/sign_in" render={(props) => {
                                    this.trackPage()
                                    return <SignInPage />
                                }} />
                                <Route path="/release_notes" render={(props) => {
                                    this.trackPage()
                                    return <ReleaseNotes />
                                }} />
                                <Route path="/privacy" render={(props) => {
                                    this.trackPage()
                                    return <Privacy />
                                }} />
                                <Route path="/GDPR" render={(props) => {
                                    this.trackPage()
                                    if (!this.state.user) {
                                        return <SignInPage />
                                    }
                                    return <DataExport user={this.state.user} />
                                }} />
                                <Route path="/confidence_score" render={(props) => {
                                    this.trackPage()
                                    if (!this.state.user) {
                                        return <SignInPage />
                                    }
                                    return <UserRequestFeed user={this.state.user} />
                                }} />
                                <Route path="/admin/confidence_score" render={(props) => {
                                    this.trackPage()
                                    if (!this.state.user) {
                                        return <SignInPage />
                                    }
                                    return <AdminConfidenceScoreFeed />
                                }} />
                                <Route path="/admin/event_organizer_creation" render={(props) => {
                                    this.trackPage()
                                    if (!this.state.user) {
                                        return <SignInPage />
                                    }
                                    return <EventOrganizerMutation />
                                }} />
                                <Route path="/admin/verification_requests" render={(props) => {
                                    this.trackPage()
                                    if (!this.state.user) {
                                        return <SignInPage />
                                    }
                                    return <VerificationRequestFeed editable />
                                }} />
                                <Route path="/verification_requests" render={(props) => {
                                    this.trackPage()
                                    if (!this.state.user) {
                                        return <SignInPage />
                                    }
                                    return <VerificationRequestFeed editable={false} />
                                }} />
                                <Route path="/power_analysis_creation" render={() => {
                                    this.trackPage()
                                    if (!this.state.user) {
                                        return <SignInPage />
                                    }
                                    return <PowerAnalysisCreation user={this.state.user} />
                                }} />
                                <Route path="/news/chasing_velocio" render={() => {
                                    this.trackPage()
                                    return <BattleFor16A4 />
                                }}
                                />
                                <Route path="/news/our_obsession_with_weight" render={() => {
                                    this.trackPage()
                                    return <WeighInCulture />
                                }} />
                                <Route path="/articles/:id" render={(props) => {
                                    let id = props.match.params.id as string
                                    this.trackPage()
                                    return <Article articleId={id} />
                                }} />
                                <Route path="/articles" render={() => {
                                    this.trackPage()
                                    return <Articles />
                                }} />
                                <Route path="/test" render={() => {
                                    this.trackPage()
                                    return <WCPowerCurve />
                                }} />
                                <Route path="/" render={() => {
                                    this.trackPage()
                                    return <Home />
                                }} />
                            </Switch>
                        </div>
                    </Router>
                    <Footer />
                </div >
            );
        }
    }
}

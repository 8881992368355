import React from "react"
import { Button, Card, CardBody, CardDeck, CardFooter, Form, FormGroup, FormText, Input, Label } from "reactstrap"
import FitFileParser from "fit-file-parser"
import FitFile from "../../models/FitFile"
import PowerAnalysis, { PowerAnalysisData, PowerAnalysisDataDao } from "../../models/PowerAnalysis";
import User from "../../models/User";
import { v4 as uuidv4 } from "uuid";
import Equipment from "../../models/Equipment";
import { Link } from "react-router-dom";
import PowerCurveUtilities from "utilities/PowerCurveUtilities";
import PrivacyInput from "components/PrivacyInput";
import PrivacyService from "services/PrivacyService";

interface PowerAnalysisInputProps {
    nextStep: (powerAnalysis: PowerAnalysis) => any,
    user: User
}

interface PowerAnalysisState {
    powerAnalysis: PowerAnalysis
    fitFiles: FitFile[];
    startAnalysis: boolean;
    groupsCanAccess: { value: string, label: string }[];
}

export default class PowerAnalysisInput extends React.Component<PowerAnalysisInputProps, PowerAnalysisState> {

    constructor(props: PowerAnalysisInputProps) {
        super(props)

        this.state = {
            powerAnalysis: {
                id: uuidv4(),
                userId: this.props.user.id,
                files: { items: [] },
                title: "",
                groupsCanAccess: ["all"],
                notes: "",
            },
            groupsCanAccess: [PrivacyService.defaultPrivacySetting()],
            fitFiles: [],
            startAnalysis: false
        }
    }

    async handle(event: React.ChangeEvent<HTMLInputElement>) {
        let fileReader = new FileReader()
        let file = event.target.files[event.target.files.length - 1]
        fileReader.onload = async () => {
            const fitFileParser = new FitFileParser({
                force: true,
                speedUnit: 'm/s',
                lengthUnit: 'm',
                temperatureUnit: 'celsius',
                elapsedRecordField: false,
                mode: 'both',
            });

            fitFileParser.parse(fileReader.result, (err: any, content: FitFile) => {
                if (err === null) {
                    content = {
                        ...content,
                        fileName: file.name
                    }
                    let powerAnalysisData: PowerAnalysisData = {
                        id: uuidv4(),
                        powerAnaysisId: this.state.powerAnalysis.id,
                        equipment: { items: this.props.user.equipment.items.length ? [{ equipment: this.props.user.equipment.items[0] }] : [] },
                        fitFile: content,
                        fileName: file.name,
                        rawFile: file,
                        notes: "",
                        partialPowerCurve: JSON.stringify(Object.fromEntries(PowerCurveUtilities.getCompressedPowerCurve(content.records))),
                        groupsCanAccess: ["all"]
                    }
                    this.setState({
                        powerAnalysis: {
                            ...this.state.powerAnalysis,
                            files: { items: [...this.state.powerAnalysis.files.items, powerAnalysisData] }
                        }
                    })
                } else {
                    console.log(err)
                }
            })
        };

        fileReader.readAsArrayBuffer(file)
    }

    deleteFitFile(index: number) {
        let temp = [...this.state.powerAnalysis.files.items]
        temp.splice(index, 1)
        this.setState({
            powerAnalysis: {
                ...this.state.powerAnalysis,
                files: { items: temp }
            }
        })
    }

    formUpdateFileName(powerAnalysisData: PowerAnalysisData, value: string): void {
        powerAnalysisData.fileName = value
        this.setState({ powerAnalysis: { ...this.state.powerAnalysis } })
    }

    updatePowerSource(powerAnalysisData: PowerAnalysisData, value: string): void {
        let newEquipment = this.props.user.equipment.items.filter((equipment: Equipment) => equipment.id === value)
        powerAnalysisData.equipment.items = [{ equipment: newEquipment[0] }]
        this.setState({ powerAnalysis: { ...this.state.powerAnalysis } })
    }

    updatePrimary(powerAnalysisData: PowerAnalysisData) {
        let powerAnalysis = { ...this.state.powerAnalysis }
        console.log(PowerAnalysisDataDao.isPrimary(powerAnalysisData))
        if (PowerAnalysisDataDao.isPrimary(powerAnalysisData)) {
            powerAnalysisData.priorityOrder = 1
        } else {
            powerAnalysisData.priorityOrder = 0
        }
        this.setState({ powerAnalysis })
    }

    updateTitle(title: string) {
        let powerAnalysis = { ...this.state.powerAnalysis }
        powerAnalysis.title = title
        this.setState({ powerAnalysis })
    }

    updateNotes(notes: string) {
        let powerAnalysis = { ...this.state.powerAnalysis }
        powerAnalysis.notes = notes
        this.setState({ powerAnalysis })
    }

    updateGroupsCanAccess(event: { value: string; label: string; }[] | { value: string; label: string; }): void {
        let newGroupsCanAccess = event as { value: string; label: string; }[]
        let groupsCanAccessValues = newGroupsCanAccess.map(entry => entry.value)
        this.state.powerAnalysis.groupsCanAccess = groupsCanAccessValues
        this.setState({ groupsCanAccess: newGroupsCanAccess })
    }

    startAnalysis() {
        this.props.nextStep(this.state.powerAnalysis)
    }

    render() {
        let userHasEquipment = this.props.user.equipment.items.length !== 0
        return (
            <Form onSubmit={() => this.startAnalysis()}>
                <FormGroup>
                    <Label>Title:</Label>
                    <Input required type="text" value={this.state.powerAnalysis.title} onChange={(event) => this.updateTitle(event.target.value)} maxLength={100} />
                </FormGroup>
                <FormGroup>
                    <Label>Description:</Label>
                    <Input type="textarea" value={this.state.powerAnalysis.notes} onChange={(event) => this.updateNotes(event.target.value)} />
                </FormGroup>
                <PrivacyInput isMulti value={this.state.groupsCanAccess} onChange={event => this.updateGroupsCanAccess(event)} />
                <Input type="file" accept=".fit" value="" multiple={false} onChange={(event) => this.handle(event)} />
                {this.state.powerAnalysis.files.items.length === 0 && <Input style={{ height: 0, padding: 0, border: 0 }} required type="text" value="" />}
                <CardDeck>
                    {this.state.powerAnalysis.files.items.map((powerAnalysisData: PowerAnalysisData, index: number) => {
                        return (
                            <Card style={{ overflow: "hidden", textOverflow: "ellipsis", minWidth: "200px", maxWidth: "400px" }} className="top-buffer">
                                <CardBody>
                                    <FormGroup>
                                        <Label>File Title:</Label>
                                        <Input required type="text" value={powerAnalysisData.fileName} onChange={(event) => this.formUpdateFileName(powerAnalysisData, event.target.value)} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Power Source:</Label>
                                        <select required onChange={(event) => this.updatePowerSource(powerAnalysisData, event.target.value)} className="form-control">
                                            {this.props.user.equipment.items.map((equipment: Equipment) => {
                                                return <option value={equipment.id}>{`${equipment.make} ${equipment.model}`}</option>
                                            })}
                                        </select>
                                        {!userHasEquipment &&
                                            <FormText color="muted">
                                                It appears you haven't linked any equipment to your profile, please do so <Link to="/equipment_creation">here</Link>
                                            </FormText>
                                        }
                                    </FormGroup>
                                    <FormGroup check>
                                        <Label check>
                                            <Input type="checkbox" checked={PowerAnalysisDataDao.isPrimary(powerAnalysisData)} onClick={() => this.updatePrimary(powerAnalysisData)} />Primary Source
                                        </Label>
                                    </FormGroup>
                                </CardBody>
                                <CardFooter>
                                    <Button outline color="danger" onClick={() => this.deleteFitFile(index)}>Delete</Button>
                                </CardFooter>
                            </Card>
                        )
                    })}
                </CardDeck>
                <Button type="submit" outline color="primary" className="top-buffer">Preview</Button>
            </Form>
        )
    }

}
import { Auth } from "aws-amplify";
import User from "models/User";
import React from "react"
import { Button, Card, CardBody, CardText, Container, Form, FormGroup, FormText, Input, Progress } from "reactstrap";
import EquipmentService from "services/EquipmentService";
import KeyPowerMetricService from "services/KeyPowerMetricService";
import UserService from "services/UserService";
import VideoService from "services/VideoService";

interface DataExportProps {
    user: User
}

interface DataExportState {
    textInputConfirmation: string
    loadingMessage: string
}

export default class DataExport extends React.Component<DataExportProps, DataExportState> {

    constructor(props: DataExportProps) {
        super(props)

        this.state = {
            textInputConfirmation: "",
            loadingMessage: ""
        }
    }

    delete(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault()
        console.log("delete")
        this.setState({ loadingMessage: "Deleting Equipment" }, async () => {
            for (let index = 0; index < this.props.user.equipment.items.length; index++) {
                const equipment = this.props.user.equipment.items[index];
                await EquipmentService.deleteEquipment(equipment.id)
            }
            this.setState({ loadingMessage: "Deleting Power Metrics" }, async () => {
                for (let index = 0; index < this.props.user.keyPowerMetrics.items.length; index++) {
                    const powerMetric = this.props.user.keyPowerMetrics.items[index];
                    await KeyPowerMetricService.deleteKeyPowerMetricById(powerMetric.id)
                }
                this.setState({ loadingMessage: "Deleting Video Entries" }, async () => {
                    for (let index = 0; index < this.props.user.videoEntries.items.length; index++) {
                        const videoEntry = this.props.user.videoEntries.items[index];
                        await VideoService.deleteVideoEntryById(videoEntry.id)
                    }
                    this.setState({ loadingMessage: "Deleting User" }, async () => {
                        await UserService.deleteUserById(this.props.user.id)
                        Auth.signOut()
                        this.setState({ loadingMessage: "" })
                        window.location.reload()
                    })
                })
            })
        })
    }

    render() {
        if (this.state.loadingMessage === "") {
            return (
                <Container>
                    <Card className="top-buffer">
                        <CardBody>
                            <CardText>
                                <h3>Data export</h3>
                                <p>Here is your profile data, if you have any issues send us an email (see footer)</p>
                                <Input type="textarea" value={JSON.stringify(this.props.user)} />
                            </CardText>
                        </CardBody>
                    </Card>
                    <Card className="top-buffer">
                        <CardBody>
                            <CardText>
                                <h3>Delete All Data</h3>
                                <p>You cannot undo this action. If you delete your account there is no way to recover your data.</p>
                                <Form onSubmit={(event) => this.delete(event)}>
                                    <FormGroup>
                                        <FormText>If you understand that this is an irreversible action please fill in the input box below with the word "delete"</FormText>
                                        <Input valid={this.state.textInputConfirmation === "delete"} value={this.state.textInputConfirmation} onChange={(event) => this.setState({ textInputConfirmation: event.target.value })} type="text" />
                                    </FormGroup>
                                    <Button disabled={this.state.textInputConfirmation !== "delete"} type="submit" color="danger">Delete permanently</Button>
                                </Form>
                            </CardText>
                        </CardBody>
                    </Card>
                </Container>
            )
        } else {
            return (
                <Container>
                    <Card>
                        <Progress animated bar color="danger" value="100">{this.state.loadingMessage}</Progress>
                    </Card>
                </Container>
            )
        }
    }
}
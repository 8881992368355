import { Storage } from "aws-amplify"
import React from "react"
import Status, { StatusType } from "../../components/Status"
import PowerAnalysis from "../../models/PowerAnalysis"
import PowerAnalysisService from "../../services/PowerAnalysisService"
import {v4} from "uuid"
import { Link } from "react-router-dom"

interface PowerAnalysisSaveProps {
    powerAnalysis: PowerAnalysis
}

interface PowerAnalysisSaveState {
    submitting: boolean
    submittingMessage: string
    submitted: boolean
    error: string
}

export default class PowerAnalysisSave extends React.Component<PowerAnalysisSaveProps, PowerAnalysisSaveState> {

    constructor(props: PowerAnalysisSaveProps) {
        super(props)

        this.state = {
            submitting: true,
            submittingMessage: "Submitting",
            submitted: false,
            error: ""
        }
    }

    async componentDidMount() {
        await PowerAnalysisService.createPowerAnalysis(this.props.powerAnalysis)
        this.setState({submittingMessage: "Uploading fit files"})
        for (let i = 0; i < this.props.powerAnalysis.files.items.length; i++) {
            let fileName = v4()
            await Storage.put(fileName, this.props.powerAnalysis.files.items[i].rawFile)
            this.props.powerAnalysis.files.items[i].file = fileName
            await PowerAnalysisService.createPowerAnalysisData(this.props.powerAnalysis.files.items[i])
            this.setState({submittingMessage: `Uploading fit files: ${i + 1}/${this.props.powerAnalysis.files.items.length}`})
        }
        this.setState({submitted: true, submitting: false})
    }
    render() {
        if (this.state.submitting) {
            return (
                <Status statusType={StatusType.LOADING} title="Submitting" />
            )
        } else if (this.state.submitted && this.state.error === "") {
            return (
                <Status statusType={StatusType.SUCCESS} message="Return to your profile page to view your new submission" />
            )
        } else if (this.state.submitted && this.state.error !== "") {
            return (
                <div>                  
                    <Status statusType={StatusType.FAIL} message={this.state.error} />
                </div>

            )
        } else {
            return (
                <div>Hello</div>
            )
        }
    }
}
import { API } from "aws-amplify";
import VerificationRequest, { VerificationRequestStatus } from "models/VerificationRequest";
import * as mutations from "graphql/mutations"
import * as queries from "graphql/customVerificationRequest"
import PowerAnalysisService from "./PowerAnalysisService";

export default class VerificationRequestService {
    public static async createVerificationRequest(verificationRequest: VerificationRequest, videoEntryIds: string[], keyPowerMetricIds: string[], equipmentIds: string[], powerAnalysis: string[]) {
        try {
            await API.graphql({
                query: mutations.createVerificationRequest, variables: {
                    input: { ...verificationRequest }
                }
            })
            for (let i = 0; i < videoEntryIds.length; i++) {
                await API.graphql({
                    query: mutations.createVerificationRequestToVideoEntries, variables: {
                        input: {
                            verificationRequestId: verificationRequest.id,
                            videoEntryId: videoEntryIds[i],
                            groupsCanAccess: verificationRequest.groupsCanAccess,
                        }
                    }
                })
            }
            for (let i = 0; i < keyPowerMetricIds.length; i++) {
                await API.graphql({
                    query: mutations.createVerificationRequestToKeyPowerMetrics, variables: {
                        input: {
                            verificationRequestId: verificationRequest.id,
                            keyPowerMetricId: keyPowerMetricIds[i],
                            groupsCanAccess: verificationRequest.groupsCanAccess
                        }
                    }
                })
            }
            for (let i = 0; i < powerAnalysis.length; i++) {
                await API.graphql({
                    query: mutations.createVerificationRequestToPowerAnalysis, variables: {
                        input: {
                            verificationRequestId: verificationRequest.id,
                            powerAnalysisId: powerAnalysis[i],
                            groupsCanAccess: verificationRequest.groupsCanAccess
                        }
                    }
                })
            }
            for (let i = 0; i < equipmentIds.length; i++) {
                await API.graphql({
                    query: mutations.createVerificationRequestToEquipment, variables: {
                        input: {
                            verificationRequestId: verificationRequest.id,
                            equipmentId: equipmentIds[i],
                            groupsCanAccess: verificationRequest.groupsCanAccess
                        }
                    }
                })
            }
        } catch (err) {
            console.log(err)
            throw Error(`Unable to create verification request ${verificationRequest} due to ${err.message}`)
        }
    }

    public static async updateVerificationRequest(verificationRequest: VerificationRequest) {
        try {
            await API.graphql({
                query: mutations.updateVerificationRequest, variables: {
                    input: { ...verificationRequest }
                }
            })
        } catch (err) {
            console.log(err)
            throw new Error(`Failed to update request: ${JSON.stringify(verificationRequest)}`)
        }
    }

    public static async getVerificationRequestsByOrganizerId(organizerId: string) {
        let nextToken: string;
        let verificationRequests: VerificationRequest[] = []
        do {
            try {
                let result: any = await API.graphql({
                    query: queries.deepVerificationRequestByOrganizerId, variables: {
                        eventOrganizerId: organizerId,
                        nextToken: nextToken
                    }
                })
                let verificationRequestsToAdd = result.data.VerificationRequestByOrganizerId.items as VerificationRequest[]
                verificationRequestsToAdd.forEach(verificationRequest => {
                    verificationRequest.verificationRequestToPowerAnalysis.items.forEach(verificationRequestToPowerAnalysis => {
                        PowerAnalysisService.instantiatePartialPowerCurveMap(verificationRequestToPowerAnalysis.powerAnalysis)
                    })
                })
                verificationRequests = verificationRequests.concat(verificationRequestsToAdd)
                nextToken = result.data.VerificationRequestByOrganizerId.nextToken
            } catch (err) {
                console.log(err)
                try {
                    if (err.data.VerificationRequestByOrganizerId.items) {
                        verificationRequests = verificationRequests.concat(err.data.VerificationRequestByOrganizerId.items as VerificationRequest[])
                        nextToken = err.data.VerificationRequestByOrganizerId.nextToken
                    } else {
                        throw new Error()
                    }
                } catch (err) {
                    console.log(err)
                    throw new Error(`Unable to get verification requests for ${organizerId}`)
                }
            }
        } while (nextToken)
        return verificationRequests

    }

    public static getVerificationRequestStatusValueToLabelsMap() {
        return new Map([
            [VerificationRequestStatus.DENIED.toString(), "Denied"],
            [VerificationRequestStatus.PENDING.toString(), "Pending"],
            [VerificationRequestStatus.ACCEPTED.toString(), "Approved"],
            [VerificationRequestStatus.SUBMITTED.toString(), "Requested"]
        ])
    }

    public static async getVerificationStatusByUser(userId: string) {
        let result = await API.post("TransparencyPublic", "/verificationStatus", {body: {userIds: [userId]}})
        return result.data[userId]
    }
}
import User from "./User";

export default interface Equipment {
    id?: string
    userId?: string
    user?: User
    make?: string
    model?: string
    notes?: string
    proof?: string[]
    type?: EquipmentType
    serialNumber?: string
    groupsCanAccess?: string[]
    createdAt?: string
}

export enum EquipmentType {
    POWER_METER = "POWER_METER",
    HEART_RATE_MONITOR = "HEART_RATE_MONITOR",
    SMART_TRAINER = "SMART_TRAINER",
    DUMB_TRAINER = "DUMB_TRAINER",
    SPEED_SENSOR = "SPEED_SENSOR",
    CADENCE_SENSOR = "CADENCE_SENSOR",
    OTHER = "OTHER"
}
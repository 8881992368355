import {Record} from "../models/FitFile"


export default class PowerCurveUtilities {

    public static getDurations() {
        let index = 3600
        let durations: number[] = []
        while (index > 0) {
            durations.push(index)
            if (index > 20 * 60) {
                index = index - 30
            } else if (index > 10 * 60) {
                index = index - 15
            } else if (index > 5 *60) {
                index = index - 5
            }  else {
                index = index - 1
            }
        }
        return durations
    }

    public static getCompressedPowerCurve(records: Record[]) {
        let cumulativePower = this.getCumulativePower(records)
        let durations = [1, 15, 60, 360, 1200]
        let powerCurve: Map<number, number> = new Map()
        durations.forEach((duration: number) => {
            let max = this.getMaxPowerForDuration(duration, cumulativePower)
            powerCurve.set(duration, max);
        })
        return powerCurve
    }

    public static getPowerCurve(records: Record[]) {
        let cumulativePower = this.getCumulativePower(records)
        let durations = this.getDurations()
        let powerCurve: Map<number, number> = new Map()
        durations.forEach((duration: number) => {
            let max = this.getMaxPowerForDuration(duration, cumulativePower)
            powerCurve.set(duration, max);
        })
        return powerCurve
    }

    static getMaxPowerForDuration(duration: number, cumulativePower: number[]) {
        let maxObserved = 0
        for (let index = 0; index < cumulativePower.length; index++) {
            if (index + duration > cumulativePower.length - 1) {
                return maxObserved
            }
            let average = (cumulativePower[index + duration] - cumulativePower[index]) / duration
            maxObserved = Math.max(maxObserved, average)
        }
    }

    static interpolateRecords(records: Record[]): Record[] {
        let interpolatedData: Record[] = []
        let previousRecord: Record;
        if (records.length) {
            interpolatedData.push(records[0])
            previousRecord = records[0]
            if (isNaN(previousRecord.power)) {
                previousRecord.power = 0
            }
        }
        records.slice(1).forEach((record: Record) => {
            if (isNaN(record.power)) {
                record.power = 0
            }
            let timeDelta = (record.timestamp.getTime() - previousRecord.timestamp.getTime())/1000
            let powerAverage = (record.power + previousRecord.power) / 2
            for (let offset = 1; offset < timeDelta; offset++) {
                let interpolatedRecord = {...previousRecord}
                interpolatedRecord.timestamp = new Date(previousRecord.timestamp.getTime() + offset * 1000)
                interpolatedRecord.elapsed_time += 1
                interpolatedRecord.power = powerAverage
                interpolatedData.push(interpolatedRecord)
            }
            interpolatedData.push(record)
            previousRecord = record
        })
        return interpolatedData
    }

    static getCumulativePower(records: Record[]): number[] {
        let cumulativePower: number[] = [0]
        this.interpolateRecords(records).forEach((record: Record) => {
            cumulativePower.push(record.power + cumulativePower[cumulativePower.length - 1])
        })
        return cumulativePower
    }
}
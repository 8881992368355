export const getVideoEntryAll = /* GraphQL */ `
  query GetVideoEntryAll($id: ID!) {
    getVideoEntry(id: $id) {
      id
      userId
      user {
        id
        firstName
        lastName
        porfilePictureUrl
      }
      videoUrl
      height
      heightUnit
      weight
      weightUnit
      title
      description
      type
      comments {
        items {
            id
            userId
            user {
                id
                firstName
                lastName
                searchableName
                porfilePictureUrl
                bio
                groupsCanAccess
                createdAt
                updatedAt
            }
            body
            groupsCanAccess
            createdAt
            updatedAt
        }
      }
      videoRatings {
        nextToken
      }
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;

export const detailedUserRequestsByDate = /* GraphQL */ `
  query detailedUserRequestsByDate(
    $userRequestType: UserRequestType
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userRequestsByDate(
      userRequestType: $userRequestType
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        user {
          id
          firstName
          lastName
          searchableName
          porfilePictureUrl
          bio
          confidenceScore
          groupsCanAccess
          createdAt
          updatedAt
        }
        subjectId
        subject {
          id
          firstName
          lastName
          searchableName
          porfilePictureUrl
          bio
          confidenceScore
          groupsCanAccess
          createdAt
          updatedAt
        }
        userRequestType
        requestReason
        statusReason
        status
        groupsCanAccess
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getFullUser = /* GraphQL */ `
  query GetFullUser($id: ID!) {
    getUser(id: $id) {
      id
      firstName
      lastName
      confidenceScore
      porfilePictureUrl
      bio
      userRequests {
          items {
            id
            userId
            subjectId
            userRequestType
            requestReason
            statusReason
            status
            groupsCanAccess
            createdAt
            updatedAt
          }
      }
      videoEntries {
        items {
            createdAt
            description
            groupsCanAccess
            height
            heightUnit
            id
            title
            type
            updatedAt
            userId
            videoUrl
            weight
            weightUnit
        }
      }
      keyPowerMetrics {
          items {
            id
            userId
            duration
            value
            notes
            fileLinks
            feature
            createdAt
            updatedAt
            groupsCanAccess
          }
      }
      equipment {
          items {
            id
            make
            model
            notes
            proof
            type
            serialNumber
            groupsCanAccess
            createdAt
            updatedAt
          }
      }
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;

export const getFullPowerAnalysis = /* GraphQL */ `
  query GetFullPowerAnalysis($id: ID!) {
    getPowerAnalysis(id: $id) {
      id
      userId
      user {
        id
        firstName
        lastName
        searchableName
        porfilePictureUrl
        bio
        confidenceScore
        groupsCanAccess
        createdAt
        updatedAt
      }
      title
      notes
      files {
        items {
          id
          powerAnaysisId
          file
          fileName
          notes
          groupsCanAccess
          createdAt
          updatedAt
          owner
          startOffset
          priorityOrder
          equipment {
            items {
              equipment {
                id
                userId
                make
                model
                notes
                proof
                type
                serialNumber
                groupsCanAccess
                createdAt
                updatedAt
              }
            }
          }
        }
      }
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListPublicUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        searchableName
        porfilePictureUrl
        bio
        confidenceScore
        groupsCanAccess
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const deepPowerAnalysisByUserId = /* GraphQL */ `
  query DeepPowerAnalysisByUserId(
    $userId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPowerAnalysisFilterInput
    $limit: Int
    $nextToken: String
  ) {
    powerAnalysisByUserId(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        title
        notes
        files {
          items {
            id
            priorityOrder
            partialPowerCurve
            equipment {
              items {
                id
              }
            }
          }
        }
        groupsCanAccess
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export default class YoutubeUtilities {
    public static youtubeParser(url: string) {
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        var match = url.match(regExp);
        return (match && match[7].length === 11) ? match[7] : "";
    }

    public static youtubeShortsParser(url: string) {
        const parsed = url.split("/")
        return parsed[parsed.length - 1]
    }
}
import { faCheckCircle, faCircleNotch, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

export enum StatusType {
    LOADING,
    SUCCESS,
    FAIL
}

interface StatusProps {
    statusType: StatusType
    title?: string
    message?: string
}

export default class Status extends React.Component<StatusProps> {

    getIcon() {
        if (this.props.statusType === StatusType.LOADING) {
            return (
                <FontAwesomeIcon className="text-info fa-spin" size="8x" icon={faCircleNotch} />
            )
        } else if (this.props.statusType === StatusType.SUCCESS) {
            return (
                <FontAwesomeIcon className="text-success" size="8x" icon={faCheckCircle} />
            )
        } else if (this.props.statusType === StatusType.FAIL) {
            return (
                <FontAwesomeIcon className="text-danger" size="8x" icon={faExclamationCircle} />
            )
        } else {
            return (
                <div></div>
            )
        }
    }

    getText() {
        if (this.props.statusType === StatusType.LOADING) {
            return (
                <div className="text-info top-buffer">
                    {this.props.title ? <h2>{this.props.title}</h2> : <h2>Loading</h2>}
                    {this.props.message ? <p>{this.props.message}</p> : <p>Please wait</p>}
                </div>
            )
        } else if (this.props.statusType === StatusType.SUCCESS) {
            return (
                <div className="text-success top-buffer">
                    {this.props.title ? <h2>{this.props.title}</h2> : <h2>Success!</h2>}
                    {this.props.message ? <p>{this.props.message}</p> : <p></p>}
                </div>
            )
        } else if (this.props.statusType === StatusType.FAIL) {
            return (
                <div className="text-danger top-buffer">
                    {this.props.title ? <h2>{this.props.title}</h2> : <h2>Error</h2>}
                    {this.props.message ? <p>{this.props.message}</p> : <p>Please try again</p>}
                </div>
            )
        } else {
            return (
                <div></div>
            )
        }
    }

    render() {
        return (
            <div style={{ padding: "50px 10px", textAlign: "center" }}>
                {this.getIcon()}
                {this.getText()}
            </div>
        )
    }
}
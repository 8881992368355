import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Token, { Authorizer } from "models/Token";
import React from "react";
import { Button, Card, CardBody, Table } from "reactstrap";
import TokenService from "services/TokenService";

interface ThirdPartyFeedProps {
    userId: string
    tokens: Token[]
}

export default class ThirdPartyFeed extends React.Component<ThirdPartyFeedProps> {

    async createRGTDBToken() {
        let token: Token = {
            userId: this.props.userId,
            authorizer: Authorizer.RGTDB
        }
        let id = await TokenService.createToken(token)
        window.location.reload()
    }

    async deleteToken(tokenId: string) {
        await TokenService.deleteToken(tokenId)
        window.location.reload()
    }

    render() {
        return (
            <Card>
                <CardBody>
                    <h2>Third Party Authorization</h2>
                    <Table size="sm" responsive>
                        <thead>
                            <tr>
                                <th>Service</th>
                                <th>Token</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.tokens.map(token => {
                                return (
                                    <tr>
                                        <td>{token.authorizer}</td>
                                        <td>{token.id}</td>
                                        <td><Button color="danger" outline size="sm" onClick={() => this.deleteToken(token.id)}>
                                            <FontAwesomeIcon size="sm" icon={faTrashAlt} />
                                        </Button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                    <Button onClick={() => this.createRGTDBToken()}>Link RGTDB</Button>
                </CardBody>
            </Card>
        )
    }
}
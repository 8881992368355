import React from 'react'
import LineChart from 'components/LineChart/LineChart'
import * as data from './data.json';
import { Line } from 'components/LineChart/model';
import { Col, Row, Table } from 'reactstrap';
import * as d3 from "d3";
import UnitUtilies from 'utilities/UnitUtilities';

const DURATIONS = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '35', '40', '45', '50', '55', '60', '70', '80', '90', '100', '110', '120', '150', '180', '210', '240', '270', '300', '330', '360', '390', '420', '450', '480', '510', '540', '570', '600', '660', '720', '780', '840', '900', '960', '1020', '1080', '1140', '1200', '1320', '1440', '1560', '1680', '1800', '1920', '2040', '2160', '2280', '2400', '2520', '2640', '2760', '2880', '3000', '3120', '3240', '3360', '3480', '3600', '3900', '4200', '4500', '4800', '5100', '5400', '5700', '6000', '6300', '6600', '6900', '7200']

interface WCPowerCurveProps {

}

interface WCPowerCurveState {
    data: any
    legendData: [number, number][]
}

export default class WCPowerCurve extends React.Component<WCPowerCurveProps, WCPowerCurveState> {

    constructor(props: WCPowerCurveProps) {
        super(props)


        this.state = {
            data: data,
            // @ts-ignore
            legendData: data.default.map(_ => [0, 0])
        }
    }

    getLines(): Line[] {
        return this.state.data.default.map((row: any) => {
            let data = DURATIONS.map(duration => {
                return [+duration, +row[duration]]
            }).filter(row => row[0] <= 60*20)
            return {
                data,
                label: row["name"]
            }
        })
    }

    render(): React.ReactNode {
        let lines = this.getLines()
        let colorScale = d3.scaleOrdinal(d3.schemeCategory10)
        lines.forEach((line, index) => {
            line.color = colorScale((index % 10).toString())
        })
        let legendIndex = this.state.legendData ? +this.state.legendData[0][0] : 0
        let powerValues = this.state.legendData.map(row => row[1])
        let maxPower = Math.max(...powerValues)
        let avgPower = powerValues.reduce((acc, val) => val + acc, 0)
        avgPower = avgPower/this.state.legendData.length
        return (
            <div className='container'>
                <div className='center'>
                    <h3>Wkg by duration(s)</h3>
                </div>
                <LineChart lines={lines} onMouseOver={(points) => this.setState({ legendData: points })} xAxisLabel={(duration) => UnitUtilies.secondsToReadableString(duration)}/>
                <div className='center'>
                    <p>Duration: {UnitUtilies.secondsToReadableString(legendIndex)} Average: {avgPower.toFixed(2)} Max: {maxPower}</p>
                </div>
                <Row>
                    {lines
                        .map((line, index) => {
                            return {line, value: this.state.legendData[index][1]}
                        })
                        .sort((a, b) => b.value - a.value)
                        .map((data, index) => {
                            return (
                                <Col lg={3} md={4} sm={6} style={{ color: data.line.color }}>
                                    {data.line.label} - {data.value}wkg
                                </Col>
                            )
                        })}
                </Row>
            </div>)
    }
}
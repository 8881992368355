import { faEdit, faPlusCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'
import { Button, ButtonGroup, Card, CardBody, CardText, CardTitle, Col, Row, Table } from 'reactstrap'
import KeyPowerMetric from '../../../models/KeyPowerMetric'
import KeyPowerMetricService from '../../../services/KeyPowerMetricService'
import UnitUtilies from '../../../utilities/UnitUtilities'

interface KeyPowerMetricFeedProps {
    keyPowerMetrics: KeyPowerMetric[]
    editable: boolean
}

export default class KeyPowerMetricFeed extends React.Component<KeyPowerMetricFeedProps> {

    async deleteKeyPowerMetric(id: string) {
        await KeyPowerMetricService.deleteKeyPowerMetricById(id)
        window.location.reload()
    }

    render() {
        return (
            <Card>
                <CardBody>
                    <CardTitle>
                        <Row>
                            <Col>
                                <h2>Power Metrics</h2>
                            </Col>
                            {
                                this.props.editable &&
                                <Col xs={2}>
                                    <Link className="btn btn-outline-success" style={{ float: "right" }} to="/key_power_metric_creation">
                                        <FontAwesomeIcon icon={faPlusCircle} />
                                    </Link>
                                </Col>
                            }
                        </Row>
                    </CardTitle>
                    <CardText>
                        {this.props.keyPowerMetrics.length > 0 &&
                            <Table responsive size="sm">
                                <thead>
                                    <tr>
                                        <th>Duration</th>
                                        <th>Value</th>
                                        <th>Source</th>
                                        <th>Secondary</th>
                                        {this.props.editable && <th>Actions</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.keyPowerMetrics.sort((a, b) => a.duration - b.duration).map((keyPowerMetric: KeyPowerMetric) => {
                                        return (
                                            <tr id={keyPowerMetric.id}>
                                                <td>{UnitUtilies.secondsToReadableString(keyPowerMetric.duration)}</td>
                                                <td>{`${keyPowerMetric.value}w`}</td>
                                                <td><a target="_blank" ref="noreferrer" href={keyPowerMetric.fileLinks[0]}>Source</a></td>
                                                <td>{keyPowerMetric.fileLinks.length > 1 && <a target="_blank" ref="noreferrer" href={keyPowerMetric.fileLinks[1]}>Secondary</a>}</td>
                                                {this.props.editable && <th>
                                                    <ButtonGroup>
                                                        <Link className="btn btn-sm btn-outline-info" to={`/key_power_metric_edit/${keyPowerMetric.id}`}>
                                                            <FontAwesomeIcon size="sm" icon={faEdit} />
                                                        </Link>
                                                        <Button color="danger" outline size="sm" onClick={() => this.deleteKeyPowerMetric(keyPowerMetric.id)}>
                                                            <FontAwesomeIcon size="sm" icon={faTrashAlt} />
                                                        </Button>
                                                    </ButtonGroup>

                                                </th>}
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        }
                        {this.props.keyPowerMetrics.length === 0 && this.props.editable &&
                            <div style={{ textAlign: 'center' }}>
                                <p>You can add your best power outputs for different durations to display here along with a link to a strava activity.</p>
                            </div>
                        }
                        {this.props.keyPowerMetrics.length === 0 && !this.props.editable &&
                            <div style={{ textAlign: 'center' }}>
                                <p>This user hasn't uploaded any power metrics yet.</p>
                            </div>
                        }
                    </CardText>
                </CardBody>
            </Card>
        )
    }
}
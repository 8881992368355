import PowerAnalysis, { PowerAnalysisData, PowerAnalysisDataDao } from "../models/PowerAnalysis";
import { API, Storage } from "aws-amplify";
import * as mutations from '../graphql/mutations';
import * as customQueries from '../graphql/customQueries';
import FitFileParser from "fit-file-parser"
import FitFile from "../models/FitFile";
import ReactGA from "react-ga"


const fitFileParser = new FitFileParser({
    force: true,
    speedUnit: 'm/s',
    lengthUnit: 'm',
    temperatureUnit: 'celsius',
    elapsedRecordField: false,
    mode: 'both',
});

const fileReader = new FileReader()

export default class PowerAnalysisService {
    static async createPowerAnalysis(powerAnalysis: PowerAnalysis) {
        await API.graphql({
            query: mutations.createPowerAnalysis, variables: {
                input: {
                    id: powerAnalysis.id,
                    userId: powerAnalysis.userId,
                    title: powerAnalysis.title,
                    notes: powerAnalysis.notes,
                    groupsCanAccess: powerAnalysis.groupsCanAccess
                }
            }
        })
    }

    static async createPowerAnalysisData(powerAnalysisData: PowerAnalysisData) {
        let offset = PowerAnalysisDataDao.getOffset(powerAnalysisData)
        await API.graphql({
            query: mutations.createPowerAnalysisData, variables: {
                input: {
                    id: powerAnalysisData.id,
                    powerAnaysisId: powerAnalysisData.powerAnaysisId,
                    file: powerAnalysisData.file,
                    fileName: powerAnalysisData.fileName,
                    notes: powerAnalysisData.notes,
                    startOffset: offset.start,
                    endOffset: offset.end,
                    priorityOrder: PowerAnalysisDataDao.getPriorityOrder(powerAnalysisData),
                    partialPowerCurve: powerAnalysisData.partialPowerCurve,
                    groupsCanAccess: powerAnalysisData.groupsCanAccess
                }
            }
        })

        for (let i = 0; i < powerAnalysisData.equipment.items.length; i++) {
            await API.graphql({
                query: mutations.createPowerAnalysisDataToEquipment, variables: {
                    input: {
                        powerAnalysisDataId: powerAnalysisData.id,
                        equipmentId: powerAnalysisData.equipment.items[i].equipment.id,
                        groupsCanAccess: powerAnalysisData.groupsCanAccess
                    }
                }
            })
        }
    }

    static async getPowerAnalysisByUser(userId: string) {
        let nextToken;
        let result: PowerAnalysis[] = []
        do {
            let response: any = await API.graphql({ query: customQueries.deepPowerAnalysisByUserId, variables: { userId, nextToken } })
            let powerAnalysis = response.data.powerAnalysisByUserId.items as PowerAnalysis[]
            powerAnalysis.forEach(powerAnalysis => {
                this.instantiatePartialPowerCurveMap(powerAnalysis)
            })
            result = result.concat(powerAnalysis)
            nextToken = response.data.powerAnalysisByUserId.nextToken
        } while (nextToken)
        
        return result
    }

    static async instantiatePartialPowerCurveMap(powerAnalysis: PowerAnalysis) {
        powerAnalysis.files.items.forEach(file => {
            file.partialPowerCurveMap = new Map(Object.entries(JSON.parse(file.partialPowerCurve)))
        })
    }

    static async getPowerAnalysis(id: string) {
        let result: any;
        try {
            result = await API.graphql({ query: customQueries.getFullPowerAnalysis, variables: { id: id } })
        } catch(e) {
            result = e
        }
        if (result && result.data && result.data.getPowerAnalysis) {
            let partialPowerAnalysis = result.data.getPowerAnalysis as PowerAnalysis
            for (let i = 0; i < partialPowerAnalysis.files.items.length; i++) {
                let partialPoweranalysisData = partialPowerAnalysis.files.items[i]
                let fitFile: any = await Storage.get(partialPoweranalysisData.file, { download: true })
                fileReader.readAsArrayBuffer(fitFile.Body)
                let file = await new Promise((resolve) => {
                    fileReader.onload = () => {
                        resolve(fileReader.result)
                    }
                })
                await new Promise((resolve) => {
                    fitFileParser.parse(file, (err: any, content: FitFile) => {
                        partialPoweranalysisData.fitFile = content
                        resolve(true)
                    })
                })
            }
            return partialPowerAnalysis
        } else {
            ReactGA.exception({ description: "getPowerAnalysis.getFullPowerAnalysis", fatal: true })
            throw(new Error(`Unable to create get power analysis: ${id}`))
        }
        
    }

    static async deletePowerAnalysis(powerAnalysis: PowerAnalysis) {
        for (let i = 0; i < powerAnalysis.files.items.length; i++) {
            let file = powerAnalysis.files.items[i];
            for (let j = 0; j < file.equipment.items.length; j++) {
                await API.graphql({ query: mutations.deletePowerAnalysisDataToEquipment, variables: { input: { id: file.equipment.items[j].id } } })
            }
            await API.graphql({ query: mutations.deletePowerAnalysisData, variables: { input: { id: file.id } } })
        }
        await API.graphql({ query: mutations.deletePowerAnalysis, variables: { input: { id: powerAnalysis.id } } })
    }
}
import React from 'react'
import { Line } from './model'
import * as d3 from "d3";
import { v4 } from "uuid";
import { SvgUtilities } from 'utilities/SvgUtilitiles';
import { ScaleSequential } from 'd3';

interface LineChartProps {
    lines?: Line[];
    xAxisLabel?: (input: number) => string
    onMouseOver?: (points: [number, number][]) => void
    onBrush?: (points: { startIndex: number, endIndex: number }[]) => void
    colorScale?: ScaleSequential<string, never>
}

interface LineChartState {
    d3Data: {
        d3Line: d3.Line<[number, number]>
        d3Path: d3.Selection<SVGPathElement, [number, number][], HTMLElement, any>
        lineIndex: number; // refers to the line index in props
    }[]
    pointer: number
    xAxis: any
    yAxis: any
    xScale: d3.ScaleLinear<number, number, never>
    yScale: d3.ScaleLinear<number, number, never>
    svg: any
    id: string,
    brush: d3.BrushBehavior<unknown>
    mouseLock: boolean
}

export default class LineChart extends React.Component<LineChartProps, LineChartState> {

    constructor(props: LineChartProps) {
        super(props)

        this.state = {
            d3Data: [],
            pointer: 0,
            xAxis: null,
            yAxis: null,
            xScale: null,
            yScale: null,
            svg: null,
            id: "a" + v4(),
            brush: null,
            mouseLock: false
        }
    }

    static defaultProps = {
        lines: [
            {
                data: Array(100).fill(0).map((e, i) => [i, i])
            }
        ],
        xAxisLabel: (input: number) => input.toString(),
        onMouseOver: (_: any): void => undefined,
        onBrush: (): void => undefined
    }

    componentDidMount() {
        this.createBase();
    }

    lineZoom(event: { selection: [number, number] }) {
        if (event.selection) {
            let [start, end] = event.selection
            let xScale = this.state.xScale
            let yScale = this.state.yScale
            let xAxis = this.state.xAxis
            let yAxis = this.state.yAxis
            let svg = this.state.svg
            let brush = this.state.brush

            svg.select(".brush").call(brush.move, null)
            let mappedStart = xScale.invert(start);
            let mappedEnd = xScale.invert(end);
            let points: { startIndex: number, endIndex: number }[] = []

            let yValues: number[] = []
            this.props.lines.forEach(line => {
                let data = line.data
                let y = data.map(d => d[1])
                let x = data.map(d => d[0])
                let startIndex = d3.bisect(x, mappedStart, 1)
                let endIndex = d3.bisect(x, mappedEnd, 1)
                points.push({ startIndex: startIndex, endIndex: endIndex })
                yValues = yValues.concat(y.slice(startIndex, endIndex))
            })

            xScale
                .domain([mappedStart, mappedEnd])

            yScale
                .domain([Math.min(...yValues), Math.max(...yValues)])

            xAxis.transition().duration(1000).call(d3.axisBottom(xScale)
                .tickFormat((domainValue: d3.NumberValue) => {
                    return this.props.xAxisLabel(domainValue as number)
                }))
            yAxis.transition().duration(1000).call(d3.axisLeft(yScale))
            this.state.d3Data.forEach(line => {
                line.d3Path
                    .transition()
                    .duration(1000)
                    .attr("d", d3.line()
                        .x((d: [number, number]) => xScale(d[0]))
                        .y((d: [number, number]) => yScale(d[1]))
                    )
            })

            this.props.onBrush(points)

            this.setState({ xScale, yScale })
        }
    }

    lineResetZoom() {
        let spacing = SvgUtilities.getSpacing(this.state.id)
        let width = spacing.width
        let height = spacing.height

        let xAxis = this.state.xAxis
        let yAxis = this.state.yAxis
        let data = this.props.lines.map(line => line.data);
        let xValues = data.map(values => values.map(value => value[0])).flat()
        let yValues = data.map(values => values.map(value => value[1])).flat()
        let { xScale, yScale } = SvgUtilities.getScale(xValues, yValues, width, height);

        xAxis.transition().duration(1000).call(d3.axisBottom(xScale)
            .tickFormat((domainValue: d3.NumberValue) => {
                return this.props.xAxisLabel(domainValue as number)
            }))
        yAxis.transition().duration(1000).call(d3.axisLeft(yScale))
        this.state.d3Data.forEach(line => {
            line.d3Path
                .transition()
                .duration(1000)
                .attr("d", d3.line()
                    .x((d: [number, number]) => xScale(d[0]))
                    .y((d: [number, number]) => yScale(d[1]))
                )
        })
        this.props.onBrush([])
        this.setState({ xScale, yScale })
    }

    createBase() {
        let spacing = SvgUtilities.getSpacing(this.state.id)
        let margin = spacing.margin
        let width = spacing.width
        let height = spacing.height
        let data = this.props.lines.map(line => line.data);
        let xValues = data.map(values => values.map(value => value[0])).flat()
        let yValues = data.map(values => values.map(value => value[1])).flat()

        let { xScale, yScale } = SvgUtilities.getScale(xValues, yValues, width, height);
        let colorGradienter = this.props.colorScale ? this.props.colorScale : SvgUtilities.getColorGradient(this.props.lines.length);



        let svg = d3.select(`#${this.state.id}-svg`)
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .style("opacity", 0.9)
            .append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");


        svg.on("dblclick", () => this.lineResetZoom());

        let xAxis = svg.append("g")
            .attr("class", "x axis")
            .attr("transform", "translate(0," + height + ")")
            .style("opacity", "0.5")
            .call(d3.axisBottom(xScale)
                .tickFormat((domainValue: d3.NumberValue) => {
                    return this.props.xAxisLabel(domainValue as number)
                }))

        let yAxis = svg.append("g")
            .attr("class", "y axis")
            .style("opacity", "0.5")
            .call(d3.axisLeft(yScale))

        let d3Data: { d3Line: d3.Line<[number, number]>; d3Path: d3.Selection<SVGPathElement, [number, number][], HTMLElement, any>; lineIndex: number; }[] = []

        data.forEach((data, index) => {
            let line = this.props.lines[index]
            let d3Line = d3.line()
                .x((d: [number, number]) => xScale(d[0]))
                .y((d: [number, number]) => yScale(d[1]))
                .curve(d3.curveMonotoneX)

            let d3Path = svg.append("path")
                .datum(data)
                .attr("fill", "none")
                .attr("stroke", line.color ? line.color : colorGradienter(index))
                .attr("stroke-width", 0.5)
                .attr("d", d3Line)

            d3Data.push({ d3Line, d3Path, lineIndex: index })
        });

        svg.append("line").attr("class", "lineHover")
            .attr("id", "legend-line")
            .style("stroke", "#999")
            .attr("stroke-width", 1)
            .style("shape-rendering", "crispEdges")
            .style("opacity", 0.5)
            .attr("y1", -height)
            .attr("y2", 0);

        let mousemove = (event: any) => {
            if (!this.state.mouseLock) {
                svg.select("#legend-line").attr("transform", `translate(${d3.pointer(event)[0]}, ${height})`)
                let x0 = this.state.xScale.invert(d3.pointer(event)[0])
                let points: [number, number][] = []
                this.props.lines.forEach(line => {
                    let data = line.data
                    let y = data.map(d => d[1])
                    let x = data.map(d => d[0])
                    let i = d3.bisect(x, x0, 1)
                    points.push([x[i], y[i]])
                })
                this.props.onMouseOver(points)
            }
        }


        let brush = d3.brushX()
            .extent([[0, 0], [width, height]])
            .on("end", (event) => this.lineZoom(event))
        svg
            .append("g")
            .attr("class", "brush")
            .call(brush);

        svg.select('.brush')
            .on("mousemove", mousemove);
        svg.select(".brush")
            .on("click", () => this.setState({ mouseLock: !this.state.mouseLock }))

        this.setState({
            svg, xAxis, yAxis, d3Data, xScale, yScale, brush
        })
    }


    render(): React.ReactNode {
        return (
            <div id={this.state.id}>
                <svg id={`${this.state.id}-svg`}></svg>
            </div>
        )
    }
}
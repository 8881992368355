import PowerAnalysis, { PowerAnalysisDao } from 'models/PowerAnalysis';
import React from 'react';
import { CSVLink } from 'react-csv';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Row } from 'reactstrap';

interface PowerAnalysisBasicCardProps {
    powerAnalysis: PowerAnalysis
}


export default class PowerAnalysisBasicCard extends React.Component<PowerAnalysisBasicCardProps> {
    render(): React.ReactNode {
        return (
            <Card>
                <CardHeader>
                    {this.props.powerAnalysis.title}
                </CardHeader>
                <CardBody>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <h4>Author:&nbsp;</h4> <a href={`/profile/${this.props.powerAnalysis.userId}`}>{this.props.powerAnalysis.user?.firstName} {this.props.powerAnalysis.user?.lastName}</a>
                    </div>
                    <h4>Description:</h4> {this.props.powerAnalysis.notes}
                </CardBody>
                <CardFooter>
                    <CSVLink filename={this.props.powerAnalysis.title} data={PowerAnalysisDao.toCSV(this.props.powerAnalysis)} target="_blank">
                        <Button>Download CSV</Button>
                    </CSVLink>
                </CardFooter>
            </Card>
        )
    }
}

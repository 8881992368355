import Token from "models/Token";
import React from "react";
import TokenService from "services/TokenService";
import ThirdPartyFeed from "./ThirdPartyFeed";
import ThirdPartyLoading from "./ThirdPartyLoading";

interface ThirdPartyFeedWrapperState {
    loading: boolean;
    tokens: Token[];
}

interface ThirdPartyFeedWrapperProps {
    userId: string
}

export default class ThirdPartyFeedWrapper extends React.Component<ThirdPartyFeedWrapperProps, ThirdPartyFeedWrapperState> {
    constructor(props: ThirdPartyFeedWrapperProps) {
        super(props)

        this.state = {
            loading: true,
            tokens: []
        }
    }

    async componentDidMount() {
        let tokens = await TokenService.getTokensByUserId(this.props.userId);
        this.setState({tokens, loading: false})
    }

    render() {
        if (!this.state.loading) {
            return (<ThirdPartyFeed userId={this.props.userId} tokens={this.state.tokens}/>)
        } else {
            return (<ThirdPartyLoading/>)
        }
    }
}
import * as d3 from "d3"
import UnitUtilies from "./UnitUtilities"

export interface BaseSVG {
    svg?: d3.Selection<any, unknown, HTMLElement, any>,
    xScale?: d3.ScaleContinuousNumeric<any, any, never>,
    yScale?: d3.ScaleContinuousNumeric<any, any, never>,
    colorGradienter?: (t: number) => string
}

export class SvgUtilities {
    public static getSpacing(elementId: string) {
        let margin = { top: 50, right: 50, bottom: 50, left: 50 }
        let width = Math.min(document.getElementById(elementId).clientWidth - margin.left - margin.right)
        let height = Math.min(width * 0.5, 400)
        return {
            margin: margin,
            width: width,
            height: height
        }
    }

    public static getScale(xValues: number[], yValues: number[], width: number, height: number) {

        let xScale = d3.scaleLinear()
            .domain([d3.min(xValues), d3.max(xValues)])
            .range([0, width]);

        let yScale = d3.scaleLinear()
            .domain([0, Math.ceil(d3.max(yValues))])
            .range([height, 0])

        return { xScale, yScale }
    }

    public static getColorGradient(numberOfLines: number) {
        return d3.scaleLinear<string>()
            .domain([0, numberOfLines - 1])
            .range(["#fab529", "#032859"]);           
    }

    
    public static createGraphAxis(base: BaseSVG, scaledTimeTicks: boolean, elementId: string) {

        let spacing = SvgUtilities.getSpacing(elementId)
        let height = spacing.height
        let xAxis;
        let yAxis;

        if (scaledTimeTicks) {
            xAxis = base.svg.append("g")
                .attr("class", "x axis")
                .attr("transform", "translate(0," + height + ")")
                .style("opacity", "0.5")
                .call(d3.axisBottom(base.xScale)
                    .tickValues([10, 30, 60, 5 * 60, 10 * 60, 20 * 60, 30 * 60, 60 * 60])
                    .tickFormat((domainValue: d3.NumberValue) => UnitUtilies.secondsToReadableString(domainValue as number)));
        } else {
            xAxis = base.svg.append("g")
                .attr("class", "x axis")
                .attr("transform", "translate(0," + height + ")")
                .style("opacity", "0.5")
                .call(d3.axisBottom(base.xScale)
                    .tickFormat((domainValue: d3.NumberValue) => UnitUtilies.secondsToReadableString(domainValue as number)));
        }


        yAxis = base.svg.append("g")
            .attr("class", "y axis")
            .style("opacity", "0.5")
            .call(d3.axisLeft(base.yScale));
            
        return {
            xAxis,
            yAxis
        }
    }
}
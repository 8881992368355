import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { FormEvent } from 'react'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import { Link } from 'react-router-dom'
import { Button, Card, CardBody, CardTitle, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Table } from 'reactstrap'
import User from '../../models/User'
import UserService from '../../services/UserService'

interface UserSearchCardState {
    searchText: string
    users: User[]
    loading: boolean
}

export default class UserSearchCard extends React.Component<{}, UserSearchCardState> {

    constructor(props: {}) {
        super(props)

        this.state = {
            searchText: "",
            users: [] as User[],
            loading: false
        }
    }

    async getUsers(event: FormEvent) {
        event.preventDefault()
        if (this.state.searchText !== "") {
            try {
                this.setState({ loading: true })
                let users = await UserService.getUsersByNearName(this.state.searchText)
                this.setState({ loading: false, users: users })
            } catch (err) {
                this.setState({ loading: false })
            }
        }
    }

    render() {
        return (
            <Container className="top-buffer">
                <Card>
                    <CardBody>
                        <CardTitle>
                            <h2>Find User</h2>
                            <p>Search for a user by their first or last name (case insensitive)</p>
                        </CardTitle>
                        <Form onSubmit={(event) => this.getUsers(event)}>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <FontAwesomeIcon icon={faSearch} />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input type="text" minLength={3} value={this.state.searchText} onChange={(event) => this.setState({ searchText: event.target.value })} />
                                <InputGroupAddon addonType="append">
                                    <Button disabled={this.state.loading} type="submit" color="primary">Search!</Button>
                                </InputGroupAddon>
                            </InputGroup>
                        </Form>
                        <div className="buffer-top">
                            <Table>
                                <thead>
                                    <th>Name</th>
                                    <th>Bio</th>
                                </thead>
                                <tbody>
                                    {this.state.users.map((user: User) => {
                                        return (
                                            <tr>
                                                <td><Link to={`/profile/${user.id}`}>{`${user.firstName} ${user.lastName}`}</Link></td>
                                                <td style={{ maxWidth: "200px" }}><ReactMarkdown plugins={[gfm]}>{user.bio}</ReactMarkdown></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                </Card>
            </Container>
        )
    }
}
/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      rgtId
      firstName
      lastName
      searchableName
      porfilePictureUrl
      bio
      confidenceScore
      groupsCanAccess
      createdAt
      updatedAt
      tokens {
        nextToken
      }
      teams {
        nextToken
      }
      managedTeams {
        nextToken
      }
      videoEntries {
        nextToken
      }
      videoRatings {
        nextToken
      }
      videoComments {
        nextToken
      }
      keyPowerMetrics {
        nextToken
      }
      equipment {
        nextToken
      }
      userRequests {
        nextToken
      }
      verificationRequests {
        nextToken
      }
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        rgtId
        firstName
        lastName
        searchableName
        porfilePictureUrl
        bio
        confidenceScore
        groupsCanAccess
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getToken = /* GraphQL */ `
  query GetToken($id: ID!) {
    getToken(id: $id) {
      id
      userId
      authorizer
      createdAt
      updatedAt
      user {
        id
        rgtId
        firstName
        lastName
        searchableName
        porfilePictureUrl
        bio
        confidenceScore
        groupsCanAccess
        createdAt
        updatedAt
      }
      owner
    }
  }
`;
export const listTokens = /* GraphQL */ `
  query ListTokens(
    $filter: ModelTokenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTokens(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        authorizer
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const tokenByUserId = /* GraphQL */ `
  query TokenByUserId(
    $userId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTokenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tokenByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        authorizer
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getTeamsToUsers = /* GraphQL */ `
  query GetTeamsToUsers($id: ID!) {
    getTeamsToUsers(id: $id) {
      id
      userId
      teamId
      groupsCanAccess
      createdAt
      updatedAt
      user {
        id
        rgtId
        firstName
        lastName
        searchableName
        porfilePictureUrl
        bio
        confidenceScore
        groupsCanAccess
        createdAt
        updatedAt
      }
      team {
        id
        managerId
        name
        description
        groupsCanAccess
        createdAt
        updatedAt
      }
    }
  }
`;
export const listTeamsToUserss = /* GraphQL */ `
  query ListTeamsToUserss(
    $filter: ModelTeamsToUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeamsToUserss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        teamId
        groupsCanAccess
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTeam = /* GraphQL */ `
  query GetTeam($id: ID!) {
    getTeam(id: $id) {
      id
      managerId
      name
      description
      groupsCanAccess
      createdAt
      updatedAt
      manager {
        id
        rgtId
        firstName
        lastName
        searchableName
        porfilePictureUrl
        bio
        confidenceScore
        groupsCanAccess
        createdAt
        updatedAt
      }
      members {
        nextToken
      }
    }
  }
`;
export const listTeams = /* GraphQL */ `
  query ListTeams(
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        managerId
        name
        description
        groupsCanAccess
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVideoEntry = /* GraphQL */ `
  query GetVideoEntry($id: ID!) {
    getVideoEntry(id: $id) {
      id
      userId
      videoUrl
      height
      heightUnit
      weight
      weightUnit
      title
      description
      commentsEnabled
      type
      groupsCanAccess
      createdAt
      updatedAt
      user {
        id
        rgtId
        firstName
        lastName
        searchableName
        porfilePictureUrl
        bio
        confidenceScore
        groupsCanAccess
        createdAt
        updatedAt
      }
      videoRatings {
        nextToken
      }
      comments {
        nextToken
      }
    }
  }
`;
export const listVideoEntrys = /* GraphQL */ `
  query ListVideoEntrys(
    $filter: ModelVideoEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideoEntrys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        videoUrl
        height
        heightUnit
        weight
        weightUnit
        title
        description
        commentsEnabled
        type
        groupsCanAccess
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const videoEntryByUserId = /* GraphQL */ `
  query VideoEntryByUserId(
    $userId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelVideoEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    videoEntryByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        videoUrl
        height
        heightUnit
        weight
        weightUnit
        title
        description
        commentsEnabled
        type
        groupsCanAccess
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVideoRating = /* GraphQL */ `
  query GetVideoRating($id: ID!) {
    getVideoRating(id: $id) {
      id
      rating
      comment
      videoEntryId
      userId
      groupsCanAccess
      createdAt
      updatedAt
      user {
        id
        rgtId
        firstName
        lastName
        searchableName
        porfilePictureUrl
        bio
        confidenceScore
        groupsCanAccess
        createdAt
        updatedAt
      }
      videoEntry {
        id
        userId
        videoUrl
        height
        heightUnit
        weight
        weightUnit
        title
        description
        commentsEnabled
        type
        groupsCanAccess
        createdAt
        updatedAt
      }
    }
  }
`;
export const listVideoRatings = /* GraphQL */ `
  query ListVideoRatings(
    $filter: ModelVideoRatingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideoRatings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        rating
        comment
        videoEntryId
        userId
        groupsCanAccess
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVideoComment = /* GraphQL */ `
  query GetVideoComment($id: ID!) {
    getVideoComment(id: $id) {
      id
      videoEntryId
      userId
      body
      groupsCanAccess
      createdAt
      updatedAt
      user {
        id
        rgtId
        firstName
        lastName
        searchableName
        porfilePictureUrl
        bio
        confidenceScore
        groupsCanAccess
        createdAt
        updatedAt
      }
      videoEntry {
        id
        userId
        videoUrl
        height
        heightUnit
        weight
        weightUnit
        title
        description
        commentsEnabled
        type
        groupsCanAccess
        createdAt
        updatedAt
      }
    }
  }
`;
export const listVideoComments = /* GraphQL */ `
  query ListVideoComments(
    $filter: ModelVideoCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideoComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        videoEntryId
        userId
        body
        groupsCanAccess
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getKeyPowerMetric = /* GraphQL */ `
  query GetKeyPowerMetric($id: ID!) {
    getKeyPowerMetric(id: $id) {
      id
      userId
      duration
      value
      fileLinks
      feature
      notes
      groupsCanAccess
      createdAt
      updatedAt
      user {
        id
        rgtId
        firstName
        lastName
        searchableName
        porfilePictureUrl
        bio
        confidenceScore
        groupsCanAccess
        createdAt
        updatedAt
      }
    }
  }
`;
export const listKeyPowerMetrics = /* GraphQL */ `
  query ListKeyPowerMetrics(
    $filter: ModelKeyPowerMetricFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKeyPowerMetrics(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        duration
        value
        fileLinks
        feature
        notes
        groupsCanAccess
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const keyPowerMetricByUserId = /* GraphQL */ `
  query KeyPowerMetricByUserId(
    $userId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelKeyPowerMetricFilterInput
    $limit: Int
    $nextToken: String
  ) {
    KeyPowerMetricByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        duration
        value
        fileLinks
        feature
        notes
        groupsCanAccess
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEquipment = /* GraphQL */ `
  query GetEquipment($id: ID!) {
    getEquipment(id: $id) {
      id
      userId
      make
      model
      notes
      proof
      type
      serialNumber
      groupsCanAccess
      createdAt
      updatedAt
      user {
        id
        rgtId
        firstName
        lastName
        searchableName
        porfilePictureUrl
        bio
        confidenceScore
        groupsCanAccess
        createdAt
        updatedAt
      }
    }
  }
`;
export const listEquipments = /* GraphQL */ `
  query ListEquipments(
    $filter: ModelEquipmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEquipments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        make
        model
        notes
        proof
        type
        serialNumber
        groupsCanAccess
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const equipmentByUserId = /* GraphQL */ `
  query EquipmentByUserId(
    $userId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelEquipmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    EquipmentByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        make
        model
        notes
        proof
        type
        serialNumber
        groupsCanAccess
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserRequest = /* GraphQL */ `
  query GetUserRequest($id: ID!) {
    getUserRequest(id: $id) {
      id
      userId
      subjectId
      userRequestType
      requestReason
      statusReason
      status
      groupsCanAccess
      createdAt
      updatedAt
      user {
        id
        rgtId
        firstName
        lastName
        searchableName
        porfilePictureUrl
        bio
        confidenceScore
        groupsCanAccess
        createdAt
        updatedAt
      }
      subject {
        id
        rgtId
        firstName
        lastName
        searchableName
        porfilePictureUrl
        bio
        confidenceScore
        groupsCanAccess
        createdAt
        updatedAt
      }
    }
  }
`;
export const listUserRequests = /* GraphQL */ `
  query ListUserRequests(
    $filter: ModelUserRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        subjectId
        userRequestType
        requestReason
        statusReason
        status
        groupsCanAccess
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const userRequestsByDate = /* GraphQL */ `
  query UserRequestsByDate(
    $userRequestType: UserRequestType
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userRequestsByDate(
      userRequestType: $userRequestType
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        subjectId
        userRequestType
        requestReason
        statusReason
        status
        groupsCanAccess
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPowerAnalysis = /* GraphQL */ `
  query GetPowerAnalysis($id: ID!) {
    getPowerAnalysis(id: $id) {
      id
      userId
      title
      notes
      groupsCanAccess
      createdAt
      updatedAt
      user {
        id
        rgtId
        firstName
        lastName
        searchableName
        porfilePictureUrl
        bio
        confidenceScore
        groupsCanAccess
        createdAt
        updatedAt
      }
      files {
        nextToken
      }
    }
  }
`;
export const listPowerAnalysiss = /* GraphQL */ `
  query ListPowerAnalysiss(
    $filter: ModelPowerAnalysisFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPowerAnalysiss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        title
        notes
        groupsCanAccess
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const powerAnalysisByUserId = /* GraphQL */ `
  query PowerAnalysisByUserId(
    $userId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPowerAnalysisFilterInput
    $limit: Int
    $nextToken: String
  ) {
    powerAnalysisByUserId(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        title
        notes
        groupsCanAccess
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPowerAnalysisData = /* GraphQL */ `
  query GetPowerAnalysisData($id: ID!) {
    getPowerAnalysisData(id: $id) {
      id
      powerAnaysisId
      priorityOrder
      file
      fileName
      startOffset
      endOffset
      notes
      partialPowerCurve
      groupsCanAccess
      createdAt
      updatedAt
      owner
      equipment {
        nextToken
      }
    }
  }
`;
export const listPowerAnalysisDatas = /* GraphQL */ `
  query ListPowerAnalysisDatas(
    $filter: ModelPowerAnalysisDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPowerAnalysisDatas(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        powerAnaysisId
        priorityOrder
        file
        fileName
        startOffset
        endOffset
        notes
        partialPowerCurve
        groupsCanAccess
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getPowerAnalysisDataToEquipment = /* GraphQL */ `
  query GetPowerAnalysisDataToEquipment($id: ID!) {
    getPowerAnalysisDataToEquipment(id: $id) {
      id
      powerAnalysisDataId
      equipmentId
      groupsCanAccess
      createdAt
      updatedAt
      equipment {
        id
        userId
        make
        model
        notes
        proof
        type
        serialNumber
        groupsCanAccess
        createdAt
        updatedAt
      }
      powerAnalysisData {
        id
        powerAnaysisId
        priorityOrder
        file
        fileName
        startOffset
        endOffset
        notes
        partialPowerCurve
        groupsCanAccess
        createdAt
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const listPowerAnalysisDataToEquipments = /* GraphQL */ `
  query ListPowerAnalysisDataToEquipments(
    $filter: ModelPowerAnalysisDataToEquipmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPowerAnalysisDataToEquipments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        powerAnalysisDataId
        equipmentId
        groupsCanAccess
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getEventOrganizer = /* GraphQL */ `
  query GetEventOrganizer($id: ID!) {
    getEventOrganizer(id: $id) {
      id
      permissionGroup
      label
      logoURL
      email
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const listEventOrganizers = /* GraphQL */ `
  query ListEventOrganizers(
    $filter: ModelEventOrganizerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventOrganizers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        permissionGroup
        label
        logoURL
        email
        groupsCanAccess
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVerificationRequest = /* GraphQL */ `
  query GetVerificationRequest($id: ID!) {
    getVerificationRequest(id: $id) {
      id
      eventOrganizerId
      title
      notes
      status
      userId
      groupsCanAccess
      createdAt
      updatedAt
      user {
        id
        rgtId
        firstName
        lastName
        searchableName
        porfilePictureUrl
        bio
        confidenceScore
        groupsCanAccess
        createdAt
        updatedAt
      }
      eventOrganizer {
        id
        permissionGroup
        label
        logoURL
        email
        groupsCanAccess
        createdAt
        updatedAt
      }
      owner
      verificationRequestToVideoEntries {
        nextToken
      }
      verificationRequestToKeyPowerMetrics {
        nextToken
      }
      verificationRequestToEquipment {
        nextToken
      }
      verificationRequestToPowerAnalysis {
        nextToken
      }
    }
  }
`;
export const listVerificationRequests = /* GraphQL */ `
  query ListVerificationRequests(
    $filter: ModelVerificationRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVerificationRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventOrganizerId
        title
        notes
        status
        userId
        groupsCanAccess
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const verificationRequestByOrganizerId = /* GraphQL */ `
  query VerificationRequestByOrganizerId(
    $eventOrganizerId: ID
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVerificationRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    VerificationRequestByOrganizerId(
      eventOrganizerId: $eventOrganizerId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventOrganizerId
        title
        notes
        status
        userId
        groupsCanAccess
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const verificationRequestByUserId = /* GraphQL */ `
  query VerificationRequestByUserId(
    $userId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelVerificationRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    verificationRequestByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventOrganizerId
        title
        notes
        status
        userId
        groupsCanAccess
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getVerificationRequestToVideoEntries = /* GraphQL */ `
  query GetVerificationRequestToVideoEntries($id: ID!) {
    getVerificationRequestToVideoEntries(id: $id) {
      id
      verificationRequestId
      videoEntryId
      groupsCanAccess
      createdAt
      updatedAt
      videoEntry {
        id
        userId
        videoUrl
        height
        heightUnit
        weight
        weightUnit
        title
        description
        commentsEnabled
        type
        groupsCanAccess
        createdAt
        updatedAt
      }
      verificationRequest {
        id
        eventOrganizerId
        title
        notes
        status
        userId
        groupsCanAccess
        createdAt
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const listVerificationRequestToVideoEntriess = /* GraphQL */ `
  query ListVerificationRequestToVideoEntriess(
    $filter: ModelVerificationRequestToVideoEntriesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVerificationRequestToVideoEntriess(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        verificationRequestId
        videoEntryId
        groupsCanAccess
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getVerificationRequestToKeyPowerMetrics = /* GraphQL */ `
  query GetVerificationRequestToKeyPowerMetrics($id: ID!) {
    getVerificationRequestToKeyPowerMetrics(id: $id) {
      id
      verificationRequestId
      keyPowerMetricId
      groupsCanAccess
      createdAt
      updatedAt
      keyPowerMetric {
        id
        userId
        duration
        value
        fileLinks
        feature
        notes
        groupsCanAccess
        createdAt
        updatedAt
      }
      verificationRequest {
        id
        eventOrganizerId
        title
        notes
        status
        userId
        groupsCanAccess
        createdAt
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const listVerificationRequestToKeyPowerMetricss = /* GraphQL */ `
  query ListVerificationRequestToKeyPowerMetricss(
    $filter: ModelVerificationRequestToKeyPowerMetricsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVerificationRequestToKeyPowerMetricss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        verificationRequestId
        keyPowerMetricId
        groupsCanAccess
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getVerificationRequestToEquipment = /* GraphQL */ `
  query GetVerificationRequestToEquipment($id: ID!) {
    getVerificationRequestToEquipment(id: $id) {
      id
      verificationRequestId
      equipmentId
      groupsCanAccess
      createdAt
      updatedAt
      equipment {
        id
        userId
        make
        model
        notes
        proof
        type
        serialNumber
        groupsCanAccess
        createdAt
        updatedAt
      }
      verificationRequest {
        id
        eventOrganizerId
        title
        notes
        status
        userId
        groupsCanAccess
        createdAt
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const listVerificationRequestToEquipments = /* GraphQL */ `
  query ListVerificationRequestToEquipments(
    $filter: ModelVerificationRequestToEquipmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVerificationRequestToEquipments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        verificationRequestId
        equipmentId
        groupsCanAccess
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getVerificationRequestToPowerAnalysis = /* GraphQL */ `
  query GetVerificationRequestToPowerAnalysis($id: ID!) {
    getVerificationRequestToPowerAnalysis(id: $id) {
      id
      verificationRequestId
      powerAnalysisId
      groupsCanAccess
      createdAt
      updatedAt
      powerAnalysis {
        id
        userId
        title
        notes
        groupsCanAccess
        createdAt
        updatedAt
      }
      verificationRequest {
        id
        eventOrganizerId
        title
        notes
        status
        userId
        groupsCanAccess
        createdAt
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const listVerificationRequestToPowerAnalysiss = /* GraphQL */ `
  query ListVerificationRequestToPowerAnalysiss(
    $filter: ModelVerificationRequestToPowerAnalysisFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVerificationRequestToPowerAnalysiss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        verificationRequestId
        powerAnalysisId
        groupsCanAccess
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;

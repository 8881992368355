import React from 'react'
import { Link } from 'react-router-dom'
import { Card, CardBody, CardHeader, CardText, Container } from 'reactstrap'

export default class ReleaseNotes extends React.Component {
    render() {
        return (
            <Container>
                <Card className="top-buffer">
                    <CardHeader>Release Notes - Beta 1.4 2021/03/21</CardHeader>
                    <CardBody>
                        <CardText>
                            <h4>Dual Power Analysis</h4>
                            <p>We've rolled out our initial release of a multi file analysis tool which supports both zwift and rgt fit files, as well as an arbitrary amount of power sources.
                            The objective here is to make dual recording uploading as painless as possible, in the future getting files straight from RGT/Zwift would be ideal but we don't have API access for that at the momen.
                            In addition to a the power curve and activity power which many users are familiar with we've included a deviation curve.
                            This graph displays the discrepancies between your power sources for a given duration of time so you have a more granular picture of how your power meters may disagree.</p>
                        </CardText>
                    </CardBody>
                </Card>
                <Card className="top-buffer">
                    <CardHeader>Release Notes - Beta 1.3 2021/03/08</CardHeader>
                    <CardBody>
                        <CardText>
                            <h4>Moving away from confidence score</h4>
                            <p>I've decided to temporarily move away from the publicly viewabel confidence score, mainly because I felt the system would be too hard to be consistently applied in a manual way as we see more users and more events use the platform.
                            Behind the scenes I will work on automating the confidence score process but it has been put on the backburner for now as other more important issues come up. To replace confidence scores I've created an event verification system which I believe is more flexible and will better serve users.
                            The way it works is as follows:
                            </p>
                            <ol type="1">
                                <li>A logged in user can create a verification request <Link to="/verification_request_creation">here</Link>. In this request they specify what pieces of data are relevant for this specific event organizer.</li>
                                <li>Event organizers then use the admin portal to view all verification requests for the events they are responsible for. Using this portal they can Accept or Decline the request indicating if the rider meets whatever requirements they set.</li>
                                <li>Users can then see what events they are verified or denied for on their profile page in the "Verification" card.</li>
                            </ol>
                            <p>It is up to event organizers to determine what criteria riders need in order to be verified for their event and eBioPassport doesn't enforce anything here. I will be creating a page that includes the contact info of event organizers to provide users a way to engage with organizers.</p>
                            <h4>Granular Data Control</h4>
                            <p>I've added more control to specify exactly who can see your data. You have the choice between marking data private, public, viewable by certain event organizers or a combination of the three.
                            If you have selected an event organizer, or multiple event organizers, then any of their admin staff will be able to view the given data item.
                            If you select public then everyone can view your data, and finally if you select private then no other users can view your data, however eBioPassport admins can still see these items.
                            You are able to select a combination of these options and users should be aware that selecting multiple options results in the widest group having access. For example selecting both Public and Private would make the data publicly viewable.
                            </p>
                        </CardText>
                    </CardBody>
                </Card>
                <Card className="top-buffer">
                    <CardHeader>Release Notes - Beta 1.2 2021/01/30</CardHeader>
                    <CardBody>
                        <CardText>
                            <h4>Initial Rollout</h4>
                            <p>It has been a busy month with the initial rollout of eBP to the public. We launched at the Echelon Racing Pro league as the main verification system and saw great and constructive feedback from users. Many of your comments have been taken into consideration as we continue to build additional features and improve the user experience.</p>
                            <h4>Privacy Concerns</h4>
                            <p>We understand that there are privacy concerns regarding user data. This website is built and run by volunteers, none of whom have a legal background. We will protect user data to the best of our abilities and we will always use the data provided with our best intentions in our mission for fairness in sport.</p>
                            <h4>New Features</h4>
                            <ol type="1">
                                <li><b>Removing sign in wall</b> - Some pages have been brought above the sign in wall, these pages are public info pages. In order to search for or view a user, you will still have to sign in.</li>
                                <li><b>Additional edit functionality</b> - You can now edit your equipment and key power metrics.</li>
                                <li><b>Decimal support for weight and height</b> - This was an oversight on our part and should have been supported from the start.</li>
                                <li><b>Addition of the home page</b> - We've added a home page which provides a bit more info on what our website is all about.</li>
                                <li><b>Sorting power metrics</b> - Power metrics used to be randomly listed, we now list them by duration.</li>
                                <li><b>User search bug fix</b> - Fixed a bug which didn't allow you to search for all users</li>
                            </ol>
                            <h4>Concluding Thoughts</h4>
                            <p>We've recieved a lot of great, constructive feedback and there is still a lot of work to do ahead of us. We hope to continue developing this website and earning the trust of the community. I also am aware of the irony of having a blurry landing page splash photo for a site that is all about transparency.</p>
                        </CardText>
                    </CardBody>

                </Card>
                <Card className="top-buffer">
                    <CardHeader>Release Notes - Beta 1.1 2020/12/24</CardHeader>
                    <CardBody>
                        <CardText>
                            <h4>New Features</h4>
                            <ol type="1">
                                <li><b>Site re-organization</b> - The home page now is where users can see their profile, and there is a link in the nav bar to search for users.</li>
                                <li><b>Mark down support</b> - Support mark down in the user bio.</li>
                                <li><b>Video entries</b> - Video entries are now editable, and appear in chronological order.</li>
                                <li><b>Equipment</b> - Added support for users to document their equipment they use.</li>
                                <li><b>Key Power Metrics</b> - Added support for an optional secondary power source on key power metrics.</li>
                                <li><b>Confidence Score</b> - Added user confidence score, which represents how likely they are to be a clean rider.</li>
                                <li><b>Empirical Units</b> - Fixed issue with riders not able to enter empirical weights/heights.</li>
                            </ol>
                        </CardText>
                    </CardBody>
                </Card>
                <Card className="top-buffer">
                    <CardHeader>Release Notes - Beta 1.0 2020/12/24</CardHeader>
                    <CardBody>
                        <CardText>
                            <h4>Purpose</h4>
                            <p>
                                This is the initial release of the cycling e-sports transparency website. The main purpose of this site is to move away from posting half naked videos of ourselves in a facebook group because not only is it disorganized but it's quite simply weird.
                            </p>
                            <p>
                                This is the initial release, so there are some bugs which you will have to deal with.
                            </p>
                            <h4>Features</h4>
                            <p>
                                <ol type="1">
                                    <li><b>Google authentication</b> - We currently support sign in through google, in the future we will also support Facebook. The reason for sign in through social providers is because I trust that they have implemented the password management system correctly, and I do not believe I could do a better job than them, and don't want to worry about the risks associated with managing passwords.</li>
                                    <li><b>User profiles</b> - You can view your profile on the profile page, and share your unique profile URL. In addition you can edit your name and bio information, right now the bio only supports basic text but in the future we will allow for more elaborate displays.</li>
                                    <li><b>Videos</b> - You can add video entries which include a link to a youtube video or other video source and attatch it to your profile. We don't host any of the video content and right now only support displaying videos nicely with youtube, if you use another source for the video others will just be able to see a hyperlink to the source. You can also comment on yours and other people's videos.</li>
                                    <li><b>Power Metrics</b> - Since it's common to need to require outside performances to validate your indoor performance, we've created a way for you to showcase your key metrics for different durations.</li>
                                    <li><b>Privacy settings</b> - You are able to set a video or power metric entry to be public or private, if you mark it as private only system admins are able to see the entry.</li>
                                </ol>
                            </p>
                            <h4>Limitations and Known Bugs</h4>
                            <p>
                                <ol type="1">
                                    <li><b>Required sign in</b> - We currently require users to sign in to use the website, this is due to a design limitation that we will fix in the future</li>
                                    <li><b>Editability</b> - Currently most items aren't editable, in the future that won't be the case but for now you will have to delete the item and recreate it</li>
                                    <li><b>Profile tab bug</b> - Some odd things may happen if you are viewing someone else's profile and then click the profile button in the navbar, it may seem like you can delete their content but you can't, it's just a UI bug. Refresh the page to fix.</li>
                                </ol>
                            </p>
                            <h4>Future plans</h4>
                            <p>
                                In no particular order:
                                <ol type="1">
                                    <li><b>Make items editable</b> - Overall increase of the user experience.</li>
                                    <li><b>Provide a way to upload fit/gpx files</b> - This will be a path to providing dual power source analysis.</li>
                                    <li><b>Remove sign in required</b></li>
                                    <li><b>Fix profile tab bug</b></li>
                                    <li><b>Team functionality</b> - Enable the creation and joining of teams</li>
                                    <li><b>Enable comments/ratings on power metrics</b></li>
                                    <li><b>Enable ratings on video entries</b> - This will allow community memebers a way to rate the quality of other people's weigh in videos.</li>
                                    <li><b>Add a feature request/bug report intake queue</b></li>
                                    <li><b>Rule the world</b> - Self explanatory.</li>
                                    <li><b>Attatch weight to key power metrics</b></li>
                                    <li><b>Strava integration</b></li>
                                </ol>
                            </p>
                        </CardText>
                    </CardBody>
                </Card>
            </Container>
        )
    }
}
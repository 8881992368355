import * as d3 from "d3";
import PowerAnalysis, { PowerAnalysisDao, PowerAnalysisData } from "models/PowerAnalysis";
import React from "react"
import { Button, Col, Form, FormGroup, Input, Label } from "reactstrap";
import { v4 } from "uuid";
import ActivityPower from "./ActivityPower";

interface PowerAnalysisOffsetEditorProps {
    powerAnalysis: PowerAnalysis
    offsetUpdate: (powerAnalysisData: PowerAnalysisData, offset: { start: number, end: number }) => void
    nextStep?: () => void
}

interface PowerAnalysisOffsetEditorState {
    offsets: Map<string, { start: number, end: number }>
    lines: Map<string, d3.Line<[number, number]>>
    paths: Map<string, d3.Selection<SVGPathElement, [number, number][], HTMLElement, any>>
    xAxis: any
    svg: any
    id: string
}

export default class PowerAnalysisOffsetEditor extends React.Component<PowerAnalysisOffsetEditorProps, PowerAnalysisOffsetEditorState>{

    constructor(props: PowerAnalysisOffsetEditorProps) {
        super(props)

        this.state = {
            offsets: new Map(),
            lines: new Map(),
            paths: new Map(),
            xAxis: null,
            svg: null,
            id: "a" + v4()
        }
    }

    updateOffset(id: string, offset: { start: number; end: number; }): void {
        let newOffsets = new Map(this.state.offsets);
        newOffsets.set(id, offset)
        this.setState({ offsets: newOffsets })
    }

    render() {
        return (
            <div id={this.state.id}>
                <h2>Offset</h2>
                <ActivityPower powerAnalysisData={PowerAnalysisDao.getPriorityOrderedData(this.props.powerAnalysis)} />
                <Form>
                    {this.props.powerAnalysis.files.items.map(powerAnalysisData => {
                        let startOffset = powerAnalysisData.startOffset ? powerAnalysisData.startOffset : 0
                        let endOffset = powerAnalysisData.endOffset ? powerAnalysisData.endOffset : 0
                        return (
                            <div>
                                <Label>{powerAnalysisData.fileName}</Label>
                                <FormGroup row>
                                    <Col sm={4} md={3}>
                                        <Label>Start trim: </Label>
                                    </Col>
                                    <Col sm={4} md={2}>
                                        <Input type="number" min="0" value={startOffset.toString()} onChange={event => this.props.offsetUpdate(powerAnalysisData, { start: +event.target.value, end: endOffset })} />
                                    </Col>
                                    <Col sm={4} md={3}>
                                        <Label>End trim: </Label>
                                    </Col>
                                    <Col sm={4} md={2}>
                                        <Input type="number" min="0" value={endOffset.toString()} onChange={event => this.props.offsetUpdate(powerAnalysisData, { start: startOffset, end: +event.target.value })} />
                                    </Col>
                                </FormGroup>
                            </div>
                        )
                    })}
                    <Button outline color="primary" onClick={() => this.props.nextStep()}>Next</Button>
                </Form>
            </div>
        )
    }
}
import PrivacyInput from 'components/PrivacyInput'
import React, { FormEvent } from 'react'
import { Button, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupButtonDropdown, InputGroupText, Label } from 'reactstrap'
import PrivacyService from 'services/PrivacyService'
import Status, { StatusType } from 'components/Status'
import { Unit } from 'models/Unit'
import { VideoEntry } from 'models/VideoEntry'
import { VideoType } from 'models/VideoType'
import VideoService from 'services/VideoService'
import UnitUtilies from 'utilities/UnitUtilities'

interface VideoEntryCreationProps {
    userId: string;
    videoEntryToEditId?: string
}

interface VideoEntryCreationState {
    videoUrl: string
    heightEnabled: boolean
    heightDropdownExpanded: boolean
    height: string
    heightUnit: Unit
    weightEnabled: boolean
    weightDropdownExpanded: boolean
    weight: string
    weightUnit: Unit
    title: string
    description: string
    groupsCanAccess: { value: string, label: string }[];
    type: VideoType[]
    submitted: boolean
    submitting: boolean
    error: string
}

export default class VideoEntryCreation extends React.Component<VideoEntryCreationProps, VideoEntryCreationState> {
    state = {
        videoUrl: "",
        heightEnabled: false,
        heightDropdownExpanded: false,
        height: "",
        heightUnit: Unit.METRIC,
        weightEnabled: false,
        weightDropdownExpanded: false,
        weight: "",
        weightUnit: Unit.METRIC,
        title: "",
        description: "",
        public: true,
        groupsCanAccess: [PrivacyService.defaultPrivacySetting()],
        type: [] as VideoType[],
        submitted: false,
        submitting: false,
        error: ""
    }

    async componentDidMount() {
        if (this.props.videoEntryToEditId) {
            try {
                this.setState({ submitting: true })
                let videoEntry: VideoEntry = await VideoService.getVideoEntryById(this.props.videoEntryToEditId)
                let valueToLabelMapping = await PrivacyService.valueToLabelMapping();
                let groupsCanAccess = videoEntry.groupsCanAccess.map((value) => { 
                    return { 
                        value: value, 
                        label: valueToLabelMapping.get(value)
                    } 
                })
                this.setState({
                    videoUrl: videoEntry.videoUrl,
                    heightEnabled: videoEntry.height ? true : false,
                    heightUnit: videoEntry.heightUnit ? videoEntry.heightUnit : Unit.METRIC,
                    height: videoEntry.height ? videoEntry.height.toString() : "",
                    weightEnabled: videoEntry.weight ? true : false,
                    weightUnit: videoEntry.weightUnit ? videoEntry.weightUnit : Unit.METRIC,
                    weight: videoEntry.weight ? videoEntry.weight.toString() : "",
                    title: videoEntry.title ? videoEntry.title : "",
                    description: videoEntry.description ? videoEntry.description : "",
                    groupsCanAccess: groupsCanAccess,
                    type: videoEntry.type ? videoEntry.type : [],
                    submitting: false
                })
            } catch {
                this.setState({ error: "We couldn't find the video you wanted", submitting: false })
                console.log(`Failed to load: ${this.props.videoEntryToEditId}`)
            }

        }
    }

    stateToVideoEntryInput() {
        let videoEntryInput: VideoEntry = {
            userId: this.props.userId,
            title: this.state.title,
            description: this.state.description,
            videoUrl: this.state.videoUrl,
            groupsCanAccess: this.state.groupsCanAccess.map(entry => entry.value)
        }

        let type: VideoType[] = []

        if (this.state.heightEnabled) {
            videoEntryInput = {
                ...videoEntryInput,
                height: +this.state.height,
                heightUnit: this.state.heightUnit
            }
            type.push(VideoType.HEIGHT)
        }

        if (this.state.weightEnabled) {
            videoEntryInput = {
                ...videoEntryInput,
                weight: +this.state.weight,
                weightUnit: this.state.weightUnit,
            }
            type.push(VideoType.WEIGHT)
        }

        if (!this.state.weightEnabled && !this.state.heightEnabled) {
            type.push(VideoType.OTHER)
        }

        videoEntryInput = {
            ...videoEntryInput,
            type: type
        }

        if (this.props.videoEntryToEditId) {
            videoEntryInput = {
                ...videoEntryInput,
                id: this.props.videoEntryToEditId
            }
        }
        return videoEntryInput

    }

    async submitNewVideoEntry(event: FormEvent) {
        event.preventDefault();

        this.setState({ submitting: true })

        try {
            if (this.props.videoEntryToEditId) {
                await VideoService.updateVideo(this.stateToVideoEntryInput())
            } else {
                await VideoService.createVideo(this.stateToVideoEntryInput())
            }
            this.setState({ submitting: false, submitted: true, error: "" })
        } catch (err) {
            this.setState({ submitting: false, submitted: true, error: "Something went wrong, please try again." })
        }
    }

    render() {
        if (this.state.submitting) {
            return (
                <Status statusType={StatusType.LOADING} title="Loading" />
            )
        } else if (this.state.error !== "") {
            return (
                <Status statusType={StatusType.FAIL} message={this.state.error} />

            )
        } else if (this.state.submitted) {
            return (
                <Status statusType={StatusType.SUCCESS} message="Return to your profile page to view your new submission" />
            )
        }
        return (
            <Container className="top-buffer">
                <h2>Create New Video Entry</h2>
                <p>A video entry can be anything from a weigh in to a three sisters test. There is no restriction to what video you can link here, but if you want it to display nicely on your profile it's best to use a link to a youtube video.</p>
                <Form onSubmit={(event) => this.submitNewVideoEntry(event)}>
                    <FormGroup row>
                        <Label sm={12} md={4}>Video Source (YouTube link)</Label>
                        <Col sm={12} md={8}>
                            <Input required type="url" value={this.state.videoUrl} onChange={(event) => this.setState({ videoUrl: event.target.value })} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={12} md={4}>Title</Label>
                        <Col sm={12} md={8}>
                            <Input required type="text" value={this.state.title} onChange={(event) => this.setState({ title: event.target.value })} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={12} md={4}>Description</Label>
                        <Col sm={12} md={8}>
                            <Input required type="textarea" value={this.state.description} onChange={(event) => this.setState({ description: event.target.value })} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={12} md={4}>Weigh-in</Label>
                        <Col sm={12} md={8}>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText><Input addon type="checkbox" checked={this.state.weightEnabled} onChange={() => this.setState({ weightEnabled: !this.state.weightEnabled })} /></InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    min={1}
                                    readOnly={!this.state.weightEnabled}
                                    type="number" step="0.01" value={this.state.weight}
                                    onChange={(event) => this.setState({ weight: event.target.value })} />
                                <InputGroupButtonDropdown disabled={!this.state.weightEnabled} addonType="append" isOpen={this.state.weightDropdownExpanded} toggle={() => this.setState({ weightDropdownExpanded: !this.state.weightDropdownExpanded })}>
                                    <DropdownToggle caret>{UnitUtilies.getWeightLabel(this.state.weightUnit)}</DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={() => this.setState({ weightUnit: Unit.METRIC })}>{UnitUtilies.getWeightLabel(Unit.METRIC)}</DropdownItem>
                                        <DropdownItem onClick={() => this.setState({ weightUnit: Unit.EMPIRICAL })}>{UnitUtilies.getWeightLabel(Unit.EMPIRICAL)}</DropdownItem>
                                    </DropdownMenu>
                                </InputGroupButtonDropdown>
                            </InputGroup>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={12} md={4}>Height-in</Label>
                        <Col sm={12} md={8}>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText><Input addon type="checkbox" checked={this.state.heightEnabled} onChange={() => this.setState({ heightEnabled: !this.state.heightEnabled })} /></InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    min={1}
                                    readOnly={!this.state.heightEnabled}
                                    type="number" step="0.01" value={this.state.height}
                                    onChange={(event) => this.setState({ height: event.target.value })} />
                                <InputGroupButtonDropdown disabled={!this.state.heightEnabled} addonType="append" isOpen={this.state.heightDropdownExpanded} toggle={() => this.setState({ heightDropdownExpanded: !this.state.heightDropdownExpanded })}>
                                    <DropdownToggle caret>{UnitUtilies.getHeightLabel(this.state.heightUnit)}</DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={() => this.setState({ heightUnit: Unit.METRIC })}>{UnitUtilies.getHeightLabel(Unit.METRIC)}</DropdownItem>
                                        <DropdownItem onClick={() => this.setState({ heightUnit: Unit.EMPIRICAL })}>{UnitUtilies.getHeightLabel(Unit.EMPIRICAL)}</DropdownItem>
                                    </DropdownMenu>
                                </InputGroupButtonDropdown>
                            </InputGroup>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label md={4}>Permissions</Label>
                        <Col md={8}>
                            <PrivacyInput isMulti required value={this.state.groupsCanAccess} onChange={(selection) => this.setState({ groupsCanAccess: selection  as { value: string, label: string }[]})} />
                        </Col>
                    </FormGroup>
                    <Button outline color="success" type="submit">Submit</Button>
                </Form>
            </Container>
        )
    }
}
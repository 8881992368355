/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      rgtId
      firstName
      lastName
      searchableName
      porfilePictureUrl
      bio
      confidenceScore
      groupsCanAccess
      createdAt
      updatedAt
      tokens {
        nextToken
      }
      teams {
        nextToken
      }
      managedTeams {
        nextToken
      }
      videoEntries {
        nextToken
      }
      videoRatings {
        nextToken
      }
      videoComments {
        nextToken
      }
      keyPowerMetrics {
        nextToken
      }
      equipment {
        nextToken
      }
      userRequests {
        nextToken
      }
      verificationRequests {
        nextToken
      }
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      rgtId
      firstName
      lastName
      searchableName
      porfilePictureUrl
      bio
      confidenceScore
      groupsCanAccess
      createdAt
      updatedAt
      tokens {
        nextToken
      }
      teams {
        nextToken
      }
      managedTeams {
        nextToken
      }
      videoEntries {
        nextToken
      }
      videoRatings {
        nextToken
      }
      videoComments {
        nextToken
      }
      keyPowerMetrics {
        nextToken
      }
      equipment {
        nextToken
      }
      userRequests {
        nextToken
      }
      verificationRequests {
        nextToken
      }
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      rgtId
      firstName
      lastName
      searchableName
      porfilePictureUrl
      bio
      confidenceScore
      groupsCanAccess
      createdAt
      updatedAt
      tokens {
        nextToken
      }
      teams {
        nextToken
      }
      managedTeams {
        nextToken
      }
      videoEntries {
        nextToken
      }
      videoRatings {
        nextToken
      }
      videoComments {
        nextToken
      }
      keyPowerMetrics {
        nextToken
      }
      equipment {
        nextToken
      }
      userRequests {
        nextToken
      }
      verificationRequests {
        nextToken
      }
    }
  }
`;
export const createToken = /* GraphQL */ `
  mutation CreateToken(
    $input: CreateTokenInput!
    $condition: ModelTokenConditionInput
  ) {
    createToken(input: $input, condition: $condition) {
      id
      userId
      authorizer
      createdAt
      updatedAt
      user {
        id
        rgtId
        firstName
        lastName
        searchableName
        porfilePictureUrl
        bio
        confidenceScore
        groupsCanAccess
        createdAt
        updatedAt
      }
      owner
    }
  }
`;
export const updateToken = /* GraphQL */ `
  mutation UpdateToken(
    $input: UpdateTokenInput!
    $condition: ModelTokenConditionInput
  ) {
    updateToken(input: $input, condition: $condition) {
      id
      userId
      authorizer
      createdAt
      updatedAt
      user {
        id
        rgtId
        firstName
        lastName
        searchableName
        porfilePictureUrl
        bio
        confidenceScore
        groupsCanAccess
        createdAt
        updatedAt
      }
      owner
    }
  }
`;
export const deleteToken = /* GraphQL */ `
  mutation DeleteToken(
    $input: DeleteTokenInput!
    $condition: ModelTokenConditionInput
  ) {
    deleteToken(input: $input, condition: $condition) {
      id
      userId
      authorizer
      createdAt
      updatedAt
      user {
        id
        rgtId
        firstName
        lastName
        searchableName
        porfilePictureUrl
        bio
        confidenceScore
        groupsCanAccess
        createdAt
        updatedAt
      }
      owner
    }
  }
`;
export const createTeamsToUsers = /* GraphQL */ `
  mutation CreateTeamsToUsers(
    $input: CreateTeamsToUsersInput!
    $condition: ModelTeamsToUsersConditionInput
  ) {
    createTeamsToUsers(input: $input, condition: $condition) {
      id
      userId
      teamId
      groupsCanAccess
      createdAt
      updatedAt
      user {
        id
        rgtId
        firstName
        lastName
        searchableName
        porfilePictureUrl
        bio
        confidenceScore
        groupsCanAccess
        createdAt
        updatedAt
      }
      team {
        id
        managerId
        name
        description
        groupsCanAccess
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateTeamsToUsers = /* GraphQL */ `
  mutation UpdateTeamsToUsers(
    $input: UpdateTeamsToUsersInput!
    $condition: ModelTeamsToUsersConditionInput
  ) {
    updateTeamsToUsers(input: $input, condition: $condition) {
      id
      userId
      teamId
      groupsCanAccess
      createdAt
      updatedAt
      user {
        id
        rgtId
        firstName
        lastName
        searchableName
        porfilePictureUrl
        bio
        confidenceScore
        groupsCanAccess
        createdAt
        updatedAt
      }
      team {
        id
        managerId
        name
        description
        groupsCanAccess
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteTeamsToUsers = /* GraphQL */ `
  mutation DeleteTeamsToUsers(
    $input: DeleteTeamsToUsersInput!
    $condition: ModelTeamsToUsersConditionInput
  ) {
    deleteTeamsToUsers(input: $input, condition: $condition) {
      id
      userId
      teamId
      groupsCanAccess
      createdAt
      updatedAt
      user {
        id
        rgtId
        firstName
        lastName
        searchableName
        porfilePictureUrl
        bio
        confidenceScore
        groupsCanAccess
        createdAt
        updatedAt
      }
      team {
        id
        managerId
        name
        description
        groupsCanAccess
        createdAt
        updatedAt
      }
    }
  }
`;
export const createTeam = /* GraphQL */ `
  mutation CreateTeam(
    $input: CreateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    createTeam(input: $input, condition: $condition) {
      id
      managerId
      name
      description
      groupsCanAccess
      createdAt
      updatedAt
      manager {
        id
        rgtId
        firstName
        lastName
        searchableName
        porfilePictureUrl
        bio
        confidenceScore
        groupsCanAccess
        createdAt
        updatedAt
      }
      members {
        nextToken
      }
    }
  }
`;
export const updateTeam = /* GraphQL */ `
  mutation UpdateTeam(
    $input: UpdateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    updateTeam(input: $input, condition: $condition) {
      id
      managerId
      name
      description
      groupsCanAccess
      createdAt
      updatedAt
      manager {
        id
        rgtId
        firstName
        lastName
        searchableName
        porfilePictureUrl
        bio
        confidenceScore
        groupsCanAccess
        createdAt
        updatedAt
      }
      members {
        nextToken
      }
    }
  }
`;
export const deleteTeam = /* GraphQL */ `
  mutation DeleteTeam(
    $input: DeleteTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    deleteTeam(input: $input, condition: $condition) {
      id
      managerId
      name
      description
      groupsCanAccess
      createdAt
      updatedAt
      manager {
        id
        rgtId
        firstName
        lastName
        searchableName
        porfilePictureUrl
        bio
        confidenceScore
        groupsCanAccess
        createdAt
        updatedAt
      }
      members {
        nextToken
      }
    }
  }
`;
export const createVideoEntry = /* GraphQL */ `
  mutation CreateVideoEntry(
    $input: CreateVideoEntryInput!
    $condition: ModelVideoEntryConditionInput
  ) {
    createVideoEntry(input: $input, condition: $condition) {
      id
      userId
      videoUrl
      height
      heightUnit
      weight
      weightUnit
      title
      description
      commentsEnabled
      type
      groupsCanAccess
      createdAt
      updatedAt
      user {
        id
        rgtId
        firstName
        lastName
        searchableName
        porfilePictureUrl
        bio
        confidenceScore
        groupsCanAccess
        createdAt
        updatedAt
      }
      videoRatings {
        nextToken
      }
      comments {
        nextToken
      }
    }
  }
`;
export const updateVideoEntry = /* GraphQL */ `
  mutation UpdateVideoEntry(
    $input: UpdateVideoEntryInput!
    $condition: ModelVideoEntryConditionInput
  ) {
    updateVideoEntry(input: $input, condition: $condition) {
      id
      userId
      videoUrl
      height
      heightUnit
      weight
      weightUnit
      title
      description
      commentsEnabled
      type
      groupsCanAccess
      createdAt
      updatedAt
      user {
        id
        rgtId
        firstName
        lastName
        searchableName
        porfilePictureUrl
        bio
        confidenceScore
        groupsCanAccess
        createdAt
        updatedAt
      }
      videoRatings {
        nextToken
      }
      comments {
        nextToken
      }
    }
  }
`;
export const deleteVideoEntry = /* GraphQL */ `
  mutation DeleteVideoEntry(
    $input: DeleteVideoEntryInput!
    $condition: ModelVideoEntryConditionInput
  ) {
    deleteVideoEntry(input: $input, condition: $condition) {
      id
      userId
      videoUrl
      height
      heightUnit
      weight
      weightUnit
      title
      description
      commentsEnabled
      type
      groupsCanAccess
      createdAt
      updatedAt
      user {
        id
        rgtId
        firstName
        lastName
        searchableName
        porfilePictureUrl
        bio
        confidenceScore
        groupsCanAccess
        createdAt
        updatedAt
      }
      videoRatings {
        nextToken
      }
      comments {
        nextToken
      }
    }
  }
`;
export const createVideoRating = /* GraphQL */ `
  mutation CreateVideoRating(
    $input: CreateVideoRatingInput!
    $condition: ModelVideoRatingConditionInput
  ) {
    createVideoRating(input: $input, condition: $condition) {
      id
      rating
      comment
      videoEntryId
      userId
      groupsCanAccess
      createdAt
      updatedAt
      user {
        id
        rgtId
        firstName
        lastName
        searchableName
        porfilePictureUrl
        bio
        confidenceScore
        groupsCanAccess
        createdAt
        updatedAt
      }
      videoEntry {
        id
        userId
        videoUrl
        height
        heightUnit
        weight
        weightUnit
        title
        description
        commentsEnabled
        type
        groupsCanAccess
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateVideoRating = /* GraphQL */ `
  mutation UpdateVideoRating(
    $input: UpdateVideoRatingInput!
    $condition: ModelVideoRatingConditionInput
  ) {
    updateVideoRating(input: $input, condition: $condition) {
      id
      rating
      comment
      videoEntryId
      userId
      groupsCanAccess
      createdAt
      updatedAt
      user {
        id
        rgtId
        firstName
        lastName
        searchableName
        porfilePictureUrl
        bio
        confidenceScore
        groupsCanAccess
        createdAt
        updatedAt
      }
      videoEntry {
        id
        userId
        videoUrl
        height
        heightUnit
        weight
        weightUnit
        title
        description
        commentsEnabled
        type
        groupsCanAccess
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteVideoRating = /* GraphQL */ `
  mutation DeleteVideoRating(
    $input: DeleteVideoRatingInput!
    $condition: ModelVideoRatingConditionInput
  ) {
    deleteVideoRating(input: $input, condition: $condition) {
      id
      rating
      comment
      videoEntryId
      userId
      groupsCanAccess
      createdAt
      updatedAt
      user {
        id
        rgtId
        firstName
        lastName
        searchableName
        porfilePictureUrl
        bio
        confidenceScore
        groupsCanAccess
        createdAt
        updatedAt
      }
      videoEntry {
        id
        userId
        videoUrl
        height
        heightUnit
        weight
        weightUnit
        title
        description
        commentsEnabled
        type
        groupsCanAccess
        createdAt
        updatedAt
      }
    }
  }
`;
export const createVideoComment = /* GraphQL */ `
  mutation CreateVideoComment(
    $input: CreateVideoCommentInput!
    $condition: ModelVideoCommentConditionInput
  ) {
    createVideoComment(input: $input, condition: $condition) {
      id
      videoEntryId
      userId
      body
      groupsCanAccess
      createdAt
      updatedAt
      user {
        id
        rgtId
        firstName
        lastName
        searchableName
        porfilePictureUrl
        bio
        confidenceScore
        groupsCanAccess
        createdAt
        updatedAt
      }
      videoEntry {
        id
        userId
        videoUrl
        height
        heightUnit
        weight
        weightUnit
        title
        description
        commentsEnabled
        type
        groupsCanAccess
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateVideoComment = /* GraphQL */ `
  mutation UpdateVideoComment(
    $input: UpdateVideoCommentInput!
    $condition: ModelVideoCommentConditionInput
  ) {
    updateVideoComment(input: $input, condition: $condition) {
      id
      videoEntryId
      userId
      body
      groupsCanAccess
      createdAt
      updatedAt
      user {
        id
        rgtId
        firstName
        lastName
        searchableName
        porfilePictureUrl
        bio
        confidenceScore
        groupsCanAccess
        createdAt
        updatedAt
      }
      videoEntry {
        id
        userId
        videoUrl
        height
        heightUnit
        weight
        weightUnit
        title
        description
        commentsEnabled
        type
        groupsCanAccess
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteVideoComment = /* GraphQL */ `
  mutation DeleteVideoComment(
    $input: DeleteVideoCommentInput!
    $condition: ModelVideoCommentConditionInput
  ) {
    deleteVideoComment(input: $input, condition: $condition) {
      id
      videoEntryId
      userId
      body
      groupsCanAccess
      createdAt
      updatedAt
      user {
        id
        rgtId
        firstName
        lastName
        searchableName
        porfilePictureUrl
        bio
        confidenceScore
        groupsCanAccess
        createdAt
        updatedAt
      }
      videoEntry {
        id
        userId
        videoUrl
        height
        heightUnit
        weight
        weightUnit
        title
        description
        commentsEnabled
        type
        groupsCanAccess
        createdAt
        updatedAt
      }
    }
  }
`;
export const createKeyPowerMetric = /* GraphQL */ `
  mutation CreateKeyPowerMetric(
    $input: CreateKeyPowerMetricInput!
    $condition: ModelKeyPowerMetricConditionInput
  ) {
    createKeyPowerMetric(input: $input, condition: $condition) {
      id
      userId
      duration
      value
      fileLinks
      feature
      notes
      groupsCanAccess
      createdAt
      updatedAt
      user {
        id
        rgtId
        firstName
        lastName
        searchableName
        porfilePictureUrl
        bio
        confidenceScore
        groupsCanAccess
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateKeyPowerMetric = /* GraphQL */ `
  mutation UpdateKeyPowerMetric(
    $input: UpdateKeyPowerMetricInput!
    $condition: ModelKeyPowerMetricConditionInput
  ) {
    updateKeyPowerMetric(input: $input, condition: $condition) {
      id
      userId
      duration
      value
      fileLinks
      feature
      notes
      groupsCanAccess
      createdAt
      updatedAt
      user {
        id
        rgtId
        firstName
        lastName
        searchableName
        porfilePictureUrl
        bio
        confidenceScore
        groupsCanAccess
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteKeyPowerMetric = /* GraphQL */ `
  mutation DeleteKeyPowerMetric(
    $input: DeleteKeyPowerMetricInput!
    $condition: ModelKeyPowerMetricConditionInput
  ) {
    deleteKeyPowerMetric(input: $input, condition: $condition) {
      id
      userId
      duration
      value
      fileLinks
      feature
      notes
      groupsCanAccess
      createdAt
      updatedAt
      user {
        id
        rgtId
        firstName
        lastName
        searchableName
        porfilePictureUrl
        bio
        confidenceScore
        groupsCanAccess
        createdAt
        updatedAt
      }
    }
  }
`;
export const createEquipment = /* GraphQL */ `
  mutation CreateEquipment(
    $input: CreateEquipmentInput!
    $condition: ModelEquipmentConditionInput
  ) {
    createEquipment(input: $input, condition: $condition) {
      id
      userId
      make
      model
      notes
      proof
      type
      serialNumber
      groupsCanAccess
      createdAt
      updatedAt
      user {
        id
        rgtId
        firstName
        lastName
        searchableName
        porfilePictureUrl
        bio
        confidenceScore
        groupsCanAccess
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateEquipment = /* GraphQL */ `
  mutation UpdateEquipment(
    $input: UpdateEquipmentInput!
    $condition: ModelEquipmentConditionInput
  ) {
    updateEquipment(input: $input, condition: $condition) {
      id
      userId
      make
      model
      notes
      proof
      type
      serialNumber
      groupsCanAccess
      createdAt
      updatedAt
      user {
        id
        rgtId
        firstName
        lastName
        searchableName
        porfilePictureUrl
        bio
        confidenceScore
        groupsCanAccess
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteEquipment = /* GraphQL */ `
  mutation DeleteEquipment(
    $input: DeleteEquipmentInput!
    $condition: ModelEquipmentConditionInput
  ) {
    deleteEquipment(input: $input, condition: $condition) {
      id
      userId
      make
      model
      notes
      proof
      type
      serialNumber
      groupsCanAccess
      createdAt
      updatedAt
      user {
        id
        rgtId
        firstName
        lastName
        searchableName
        porfilePictureUrl
        bio
        confidenceScore
        groupsCanAccess
        createdAt
        updatedAt
      }
    }
  }
`;
export const createUserRequest = /* GraphQL */ `
  mutation CreateUserRequest(
    $input: CreateUserRequestInput!
    $condition: ModelUserRequestConditionInput
  ) {
    createUserRequest(input: $input, condition: $condition) {
      id
      userId
      subjectId
      userRequestType
      requestReason
      statusReason
      status
      groupsCanAccess
      createdAt
      updatedAt
      user {
        id
        rgtId
        firstName
        lastName
        searchableName
        porfilePictureUrl
        bio
        confidenceScore
        groupsCanAccess
        createdAt
        updatedAt
      }
      subject {
        id
        rgtId
        firstName
        lastName
        searchableName
        porfilePictureUrl
        bio
        confidenceScore
        groupsCanAccess
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateUserRequest = /* GraphQL */ `
  mutation UpdateUserRequest(
    $input: UpdateUserRequestInput!
    $condition: ModelUserRequestConditionInput
  ) {
    updateUserRequest(input: $input, condition: $condition) {
      id
      userId
      subjectId
      userRequestType
      requestReason
      statusReason
      status
      groupsCanAccess
      createdAt
      updatedAt
      user {
        id
        rgtId
        firstName
        lastName
        searchableName
        porfilePictureUrl
        bio
        confidenceScore
        groupsCanAccess
        createdAt
        updatedAt
      }
      subject {
        id
        rgtId
        firstName
        lastName
        searchableName
        porfilePictureUrl
        bio
        confidenceScore
        groupsCanAccess
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteUserRequest = /* GraphQL */ `
  mutation DeleteUserRequest(
    $input: DeleteUserRequestInput!
    $condition: ModelUserRequestConditionInput
  ) {
    deleteUserRequest(input: $input, condition: $condition) {
      id
      userId
      subjectId
      userRequestType
      requestReason
      statusReason
      status
      groupsCanAccess
      createdAt
      updatedAt
      user {
        id
        rgtId
        firstName
        lastName
        searchableName
        porfilePictureUrl
        bio
        confidenceScore
        groupsCanAccess
        createdAt
        updatedAt
      }
      subject {
        id
        rgtId
        firstName
        lastName
        searchableName
        porfilePictureUrl
        bio
        confidenceScore
        groupsCanAccess
        createdAt
        updatedAt
      }
    }
  }
`;
export const createPowerAnalysis = /* GraphQL */ `
  mutation CreatePowerAnalysis(
    $input: CreatePowerAnalysisInput!
    $condition: ModelPowerAnalysisConditionInput
  ) {
    createPowerAnalysis(input: $input, condition: $condition) {
      id
      userId
      title
      notes
      groupsCanAccess
      createdAt
      updatedAt
      user {
        id
        rgtId
        firstName
        lastName
        searchableName
        porfilePictureUrl
        bio
        confidenceScore
        groupsCanAccess
        createdAt
        updatedAt
      }
      files {
        nextToken
      }
    }
  }
`;
export const updatePowerAnalysis = /* GraphQL */ `
  mutation UpdatePowerAnalysis(
    $input: UpdatePowerAnalysisInput!
    $condition: ModelPowerAnalysisConditionInput
  ) {
    updatePowerAnalysis(input: $input, condition: $condition) {
      id
      userId
      title
      notes
      groupsCanAccess
      createdAt
      updatedAt
      user {
        id
        rgtId
        firstName
        lastName
        searchableName
        porfilePictureUrl
        bio
        confidenceScore
        groupsCanAccess
        createdAt
        updatedAt
      }
      files {
        nextToken
      }
    }
  }
`;
export const deletePowerAnalysis = /* GraphQL */ `
  mutation DeletePowerAnalysis(
    $input: DeletePowerAnalysisInput!
    $condition: ModelPowerAnalysisConditionInput
  ) {
    deletePowerAnalysis(input: $input, condition: $condition) {
      id
      userId
      title
      notes
      groupsCanAccess
      createdAt
      updatedAt
      user {
        id
        rgtId
        firstName
        lastName
        searchableName
        porfilePictureUrl
        bio
        confidenceScore
        groupsCanAccess
        createdAt
        updatedAt
      }
      files {
        nextToken
      }
    }
  }
`;
export const createPowerAnalysisData = /* GraphQL */ `
  mutation CreatePowerAnalysisData(
    $input: CreatePowerAnalysisDataInput!
    $condition: ModelPowerAnalysisDataConditionInput
  ) {
    createPowerAnalysisData(input: $input, condition: $condition) {
      id
      powerAnaysisId
      priorityOrder
      file
      fileName
      startOffset
      endOffset
      notes
      partialPowerCurve
      groupsCanAccess
      createdAt
      updatedAt
      owner
      equipment {
        nextToken
      }
    }
  }
`;
export const updatePowerAnalysisData = /* GraphQL */ `
  mutation UpdatePowerAnalysisData(
    $input: UpdatePowerAnalysisDataInput!
    $condition: ModelPowerAnalysisDataConditionInput
  ) {
    updatePowerAnalysisData(input: $input, condition: $condition) {
      id
      powerAnaysisId
      priorityOrder
      file
      fileName
      startOffset
      endOffset
      notes
      partialPowerCurve
      groupsCanAccess
      createdAt
      updatedAt
      owner
      equipment {
        nextToken
      }
    }
  }
`;
export const deletePowerAnalysisData = /* GraphQL */ `
  mutation DeletePowerAnalysisData(
    $input: DeletePowerAnalysisDataInput!
    $condition: ModelPowerAnalysisDataConditionInput
  ) {
    deletePowerAnalysisData(input: $input, condition: $condition) {
      id
      powerAnaysisId
      priorityOrder
      file
      fileName
      startOffset
      endOffset
      notes
      partialPowerCurve
      groupsCanAccess
      createdAt
      updatedAt
      owner
      equipment {
        nextToken
      }
    }
  }
`;
export const createPowerAnalysisDataToEquipment = /* GraphQL */ `
  mutation CreatePowerAnalysisDataToEquipment(
    $input: CreatePowerAnalysisDataToEquipmentInput!
    $condition: ModelPowerAnalysisDataToEquipmentConditionInput
  ) {
    createPowerAnalysisDataToEquipment(input: $input, condition: $condition) {
      id
      powerAnalysisDataId
      equipmentId
      groupsCanAccess
      createdAt
      updatedAt
      equipment {
        id
        userId
        make
        model
        notes
        proof
        type
        serialNumber
        groupsCanAccess
        createdAt
        updatedAt
      }
      powerAnalysisData {
        id
        powerAnaysisId
        priorityOrder
        file
        fileName
        startOffset
        endOffset
        notes
        partialPowerCurve
        groupsCanAccess
        createdAt
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const updatePowerAnalysisDataToEquipment = /* GraphQL */ `
  mutation UpdatePowerAnalysisDataToEquipment(
    $input: UpdatePowerAnalysisDataToEquipmentInput!
    $condition: ModelPowerAnalysisDataToEquipmentConditionInput
  ) {
    updatePowerAnalysisDataToEquipment(input: $input, condition: $condition) {
      id
      powerAnalysisDataId
      equipmentId
      groupsCanAccess
      createdAt
      updatedAt
      equipment {
        id
        userId
        make
        model
        notes
        proof
        type
        serialNumber
        groupsCanAccess
        createdAt
        updatedAt
      }
      powerAnalysisData {
        id
        powerAnaysisId
        priorityOrder
        file
        fileName
        startOffset
        endOffset
        notes
        partialPowerCurve
        groupsCanAccess
        createdAt
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const deletePowerAnalysisDataToEquipment = /* GraphQL */ `
  mutation DeletePowerAnalysisDataToEquipment(
    $input: DeletePowerAnalysisDataToEquipmentInput!
    $condition: ModelPowerAnalysisDataToEquipmentConditionInput
  ) {
    deletePowerAnalysisDataToEquipment(input: $input, condition: $condition) {
      id
      powerAnalysisDataId
      equipmentId
      groupsCanAccess
      createdAt
      updatedAt
      equipment {
        id
        userId
        make
        model
        notes
        proof
        type
        serialNumber
        groupsCanAccess
        createdAt
        updatedAt
      }
      powerAnalysisData {
        id
        powerAnaysisId
        priorityOrder
        file
        fileName
        startOffset
        endOffset
        notes
        partialPowerCurve
        groupsCanAccess
        createdAt
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const createEventOrganizer = /* GraphQL */ `
  mutation CreateEventOrganizer(
    $input: CreateEventOrganizerInput!
    $condition: ModelEventOrganizerConditionInput
  ) {
    createEventOrganizer(input: $input, condition: $condition) {
      id
      permissionGroup
      label
      logoURL
      email
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const updateEventOrganizer = /* GraphQL */ `
  mutation UpdateEventOrganizer(
    $input: UpdateEventOrganizerInput!
    $condition: ModelEventOrganizerConditionInput
  ) {
    updateEventOrganizer(input: $input, condition: $condition) {
      id
      permissionGroup
      label
      logoURL
      email
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const deleteEventOrganizer = /* GraphQL */ `
  mutation DeleteEventOrganizer(
    $input: DeleteEventOrganizerInput!
    $condition: ModelEventOrganizerConditionInput
  ) {
    deleteEventOrganizer(input: $input, condition: $condition) {
      id
      permissionGroup
      label
      logoURL
      email
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const createVerificationRequest = /* GraphQL */ `
  mutation CreateVerificationRequest(
    $input: CreateVerificationRequestInput!
    $condition: ModelVerificationRequestConditionInput
  ) {
    createVerificationRequest(input: $input, condition: $condition) {
      id
      eventOrganizerId
      title
      notes
      status
      userId
      groupsCanAccess
      createdAt
      updatedAt
      user {
        id
        rgtId
        firstName
        lastName
        searchableName
        porfilePictureUrl
        bio
        confidenceScore
        groupsCanAccess
        createdAt
        updatedAt
      }
      eventOrganizer {
        id
        permissionGroup
        label
        logoURL
        email
        groupsCanAccess
        createdAt
        updatedAt
      }
      owner
      verificationRequestToVideoEntries {
        nextToken
      }
      verificationRequestToKeyPowerMetrics {
        nextToken
      }
      verificationRequestToEquipment {
        nextToken
      }
      verificationRequestToPowerAnalysis {
        nextToken
      }
    }
  }
`;
export const updateVerificationRequest = /* GraphQL */ `
  mutation UpdateVerificationRequest(
    $input: UpdateVerificationRequestInput!
    $condition: ModelVerificationRequestConditionInput
  ) {
    updateVerificationRequest(input: $input, condition: $condition) {
      id
      eventOrganizerId
      title
      notes
      status
      userId
      groupsCanAccess
      createdAt
      updatedAt
      user {
        id
        rgtId
        firstName
        lastName
        searchableName
        porfilePictureUrl
        bio
        confidenceScore
        groupsCanAccess
        createdAt
        updatedAt
      }
      eventOrganizer {
        id
        permissionGroup
        label
        logoURL
        email
        groupsCanAccess
        createdAt
        updatedAt
      }
      owner
      verificationRequestToVideoEntries {
        nextToken
      }
      verificationRequestToKeyPowerMetrics {
        nextToken
      }
      verificationRequestToEquipment {
        nextToken
      }
      verificationRequestToPowerAnalysis {
        nextToken
      }
    }
  }
`;
export const deleteVerificationRequest = /* GraphQL */ `
  mutation DeleteVerificationRequest(
    $input: DeleteVerificationRequestInput!
    $condition: ModelVerificationRequestConditionInput
  ) {
    deleteVerificationRequest(input: $input, condition: $condition) {
      id
      eventOrganizerId
      title
      notes
      status
      userId
      groupsCanAccess
      createdAt
      updatedAt
      user {
        id
        rgtId
        firstName
        lastName
        searchableName
        porfilePictureUrl
        bio
        confidenceScore
        groupsCanAccess
        createdAt
        updatedAt
      }
      eventOrganizer {
        id
        permissionGroup
        label
        logoURL
        email
        groupsCanAccess
        createdAt
        updatedAt
      }
      owner
      verificationRequestToVideoEntries {
        nextToken
      }
      verificationRequestToKeyPowerMetrics {
        nextToken
      }
      verificationRequestToEquipment {
        nextToken
      }
      verificationRequestToPowerAnalysis {
        nextToken
      }
    }
  }
`;
export const createVerificationRequestToVideoEntries = /* GraphQL */ `
  mutation CreateVerificationRequestToVideoEntries(
    $input: CreateVerificationRequestToVideoEntriesInput!
    $condition: ModelVerificationRequestToVideoEntriesConditionInput
  ) {
    createVerificationRequestToVideoEntries(
      input: $input
      condition: $condition
    ) {
      id
      verificationRequestId
      videoEntryId
      groupsCanAccess
      createdAt
      updatedAt
      videoEntry {
        id
        userId
        videoUrl
        height
        heightUnit
        weight
        weightUnit
        title
        description
        commentsEnabled
        type
        groupsCanAccess
        createdAt
        updatedAt
      }
      verificationRequest {
        id
        eventOrganizerId
        title
        notes
        status
        userId
        groupsCanAccess
        createdAt
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const updateVerificationRequestToVideoEntries = /* GraphQL */ `
  mutation UpdateVerificationRequestToVideoEntries(
    $input: UpdateVerificationRequestToVideoEntriesInput!
    $condition: ModelVerificationRequestToVideoEntriesConditionInput
  ) {
    updateVerificationRequestToVideoEntries(
      input: $input
      condition: $condition
    ) {
      id
      verificationRequestId
      videoEntryId
      groupsCanAccess
      createdAt
      updatedAt
      videoEntry {
        id
        userId
        videoUrl
        height
        heightUnit
        weight
        weightUnit
        title
        description
        commentsEnabled
        type
        groupsCanAccess
        createdAt
        updatedAt
      }
      verificationRequest {
        id
        eventOrganizerId
        title
        notes
        status
        userId
        groupsCanAccess
        createdAt
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const deleteVerificationRequestToVideoEntries = /* GraphQL */ `
  mutation DeleteVerificationRequestToVideoEntries(
    $input: DeleteVerificationRequestToVideoEntriesInput!
    $condition: ModelVerificationRequestToVideoEntriesConditionInput
  ) {
    deleteVerificationRequestToVideoEntries(
      input: $input
      condition: $condition
    ) {
      id
      verificationRequestId
      videoEntryId
      groupsCanAccess
      createdAt
      updatedAt
      videoEntry {
        id
        userId
        videoUrl
        height
        heightUnit
        weight
        weightUnit
        title
        description
        commentsEnabled
        type
        groupsCanAccess
        createdAt
        updatedAt
      }
      verificationRequest {
        id
        eventOrganizerId
        title
        notes
        status
        userId
        groupsCanAccess
        createdAt
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const createVerificationRequestToKeyPowerMetrics = /* GraphQL */ `
  mutation CreateVerificationRequestToKeyPowerMetrics(
    $input: CreateVerificationRequestToKeyPowerMetricsInput!
    $condition: ModelVerificationRequestToKeyPowerMetricsConditionInput
  ) {
    createVerificationRequestToKeyPowerMetrics(
      input: $input
      condition: $condition
    ) {
      id
      verificationRequestId
      keyPowerMetricId
      groupsCanAccess
      createdAt
      updatedAt
      keyPowerMetric {
        id
        userId
        duration
        value
        fileLinks
        feature
        notes
        groupsCanAccess
        createdAt
        updatedAt
      }
      verificationRequest {
        id
        eventOrganizerId
        title
        notes
        status
        userId
        groupsCanAccess
        createdAt
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const updateVerificationRequestToKeyPowerMetrics = /* GraphQL */ `
  mutation UpdateVerificationRequestToKeyPowerMetrics(
    $input: UpdateVerificationRequestToKeyPowerMetricsInput!
    $condition: ModelVerificationRequestToKeyPowerMetricsConditionInput
  ) {
    updateVerificationRequestToKeyPowerMetrics(
      input: $input
      condition: $condition
    ) {
      id
      verificationRequestId
      keyPowerMetricId
      groupsCanAccess
      createdAt
      updatedAt
      keyPowerMetric {
        id
        userId
        duration
        value
        fileLinks
        feature
        notes
        groupsCanAccess
        createdAt
        updatedAt
      }
      verificationRequest {
        id
        eventOrganizerId
        title
        notes
        status
        userId
        groupsCanAccess
        createdAt
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const deleteVerificationRequestToKeyPowerMetrics = /* GraphQL */ `
  mutation DeleteVerificationRequestToKeyPowerMetrics(
    $input: DeleteVerificationRequestToKeyPowerMetricsInput!
    $condition: ModelVerificationRequestToKeyPowerMetricsConditionInput
  ) {
    deleteVerificationRequestToKeyPowerMetrics(
      input: $input
      condition: $condition
    ) {
      id
      verificationRequestId
      keyPowerMetricId
      groupsCanAccess
      createdAt
      updatedAt
      keyPowerMetric {
        id
        userId
        duration
        value
        fileLinks
        feature
        notes
        groupsCanAccess
        createdAt
        updatedAt
      }
      verificationRequest {
        id
        eventOrganizerId
        title
        notes
        status
        userId
        groupsCanAccess
        createdAt
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const createVerificationRequestToEquipment = /* GraphQL */ `
  mutation CreateVerificationRequestToEquipment(
    $input: CreateVerificationRequestToEquipmentInput!
    $condition: ModelVerificationRequestToEquipmentConditionInput
  ) {
    createVerificationRequestToEquipment(input: $input, condition: $condition) {
      id
      verificationRequestId
      equipmentId
      groupsCanAccess
      createdAt
      updatedAt
      equipment {
        id
        userId
        make
        model
        notes
        proof
        type
        serialNumber
        groupsCanAccess
        createdAt
        updatedAt
      }
      verificationRequest {
        id
        eventOrganizerId
        title
        notes
        status
        userId
        groupsCanAccess
        createdAt
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const updateVerificationRequestToEquipment = /* GraphQL */ `
  mutation UpdateVerificationRequestToEquipment(
    $input: UpdateVerificationRequestToEquipmentInput!
    $condition: ModelVerificationRequestToEquipmentConditionInput
  ) {
    updateVerificationRequestToEquipment(input: $input, condition: $condition) {
      id
      verificationRequestId
      equipmentId
      groupsCanAccess
      createdAt
      updatedAt
      equipment {
        id
        userId
        make
        model
        notes
        proof
        type
        serialNumber
        groupsCanAccess
        createdAt
        updatedAt
      }
      verificationRequest {
        id
        eventOrganizerId
        title
        notes
        status
        userId
        groupsCanAccess
        createdAt
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const deleteVerificationRequestToEquipment = /* GraphQL */ `
  mutation DeleteVerificationRequestToEquipment(
    $input: DeleteVerificationRequestToEquipmentInput!
    $condition: ModelVerificationRequestToEquipmentConditionInput
  ) {
    deleteVerificationRequestToEquipment(input: $input, condition: $condition) {
      id
      verificationRequestId
      equipmentId
      groupsCanAccess
      createdAt
      updatedAt
      equipment {
        id
        userId
        make
        model
        notes
        proof
        type
        serialNumber
        groupsCanAccess
        createdAt
        updatedAt
      }
      verificationRequest {
        id
        eventOrganizerId
        title
        notes
        status
        userId
        groupsCanAccess
        createdAt
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const createVerificationRequestToPowerAnalysis = /* GraphQL */ `
  mutation CreateVerificationRequestToPowerAnalysis(
    $input: CreateVerificationRequestToPowerAnalysisInput!
    $condition: ModelVerificationRequestToPowerAnalysisConditionInput
  ) {
    createVerificationRequestToPowerAnalysis(
      input: $input
      condition: $condition
    ) {
      id
      verificationRequestId
      powerAnalysisId
      groupsCanAccess
      createdAt
      updatedAt
      powerAnalysis {
        id
        userId
        title
        notes
        groupsCanAccess
        createdAt
        updatedAt
      }
      verificationRequest {
        id
        eventOrganizerId
        title
        notes
        status
        userId
        groupsCanAccess
        createdAt
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const updateVerificationRequestToPowerAnalysis = /* GraphQL */ `
  mutation UpdateVerificationRequestToPowerAnalysis(
    $input: UpdateVerificationRequestToPowerAnalysisInput!
    $condition: ModelVerificationRequestToPowerAnalysisConditionInput
  ) {
    updateVerificationRequestToPowerAnalysis(
      input: $input
      condition: $condition
    ) {
      id
      verificationRequestId
      powerAnalysisId
      groupsCanAccess
      createdAt
      updatedAt
      powerAnalysis {
        id
        userId
        title
        notes
        groupsCanAccess
        createdAt
        updatedAt
      }
      verificationRequest {
        id
        eventOrganizerId
        title
        notes
        status
        userId
        groupsCanAccess
        createdAt
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const deleteVerificationRequestToPowerAnalysis = /* GraphQL */ `
  mutation DeleteVerificationRequestToPowerAnalysis(
    $input: DeleteVerificationRequestToPowerAnalysisInput!
    $condition: ModelVerificationRequestToPowerAnalysisConditionInput
  ) {
    deleteVerificationRequestToPowerAnalysis(
      input: $input
      condition: $condition
    ) {
      id
      verificationRequestId
      powerAnalysisId
      groupsCanAccess
      createdAt
      updatedAt
      powerAnalysis {
        id
        userId
        title
        notes
        groupsCanAccess
        createdAt
        updatedAt
      }
      verificationRequest {
        id
        eventOrganizerId
        title
        notes
        status
        userId
        groupsCanAccess
        createdAt
        updatedAt
        owner
      }
      owner
    }
  }
`;

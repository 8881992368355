import { faPlusCircle, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import ContentLoader from "react-content-loader";
import { Link } from "react-router-dom";
import { Card, CardBody, CardTitle, Row, Col, Table, ButtonGroup, Button } from "reactstrap";

export default class PowerAnalysisLoadin extends React.Component<{ editable: boolean }> {
    getContentLoader() {
        return (
            <ContentLoader viewBox="0 0 100% 30" height={30} width={"100%"}>
                <rect x="0" y="5" width="100%" height="20" />
            </ContentLoader>
        )
    }
    render() {
        return (
            <Card>
                <CardBody>
                    <CardTitle>
                        <Row>
                            <Col>
                                <h2>Power Analysis</h2>
                            </Col>
                            {
                                this.props.editable &&
                                <Col xs={2}>
                                    <Link style={{ float: "right" }} className="btn btn-outline-success" to="/power_analysis_creation">
                                        <FontAwesomeIcon icon={faPlusCircle} />
                                    </Link>
                                </Col>
                            }
                        </Row>
                    </CardTitle>
                    <Table responsive size="sm">
                        <thead>
                            <tr>
                                <th>Title</th>
                                <th>1 Sec</th>
                                <th>15 Sec</th>
                                <th>1 Min</th>
                                <th>5 Min</th>
                                <th>20 Min</th>
                                {this.props.editable && <th>Actions</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {[0, 1, 2, 3].map(index => {
                                return (
                                    <tr>
                                        <td>{this.getContentLoader()}</td>
                                        <td>
                                            <div>
                                                {this.getContentLoader()}
                                            </div>
                                            <div>
                                                {this.getContentLoader()}
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                {this.getContentLoader()}
                                            </div>
                                            <div>
                                                {this.getContentLoader()}
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                {this.getContentLoader()}
                                            </div>
                                            <div>
                                                {this.getContentLoader()}
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                {this.getContentLoader()}
                                            </div>
                                            <div>
                                                {this.getContentLoader()}
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                {this.getContentLoader()}
                                            </div>
                                            <div>
                                                {this.getContentLoader()}
                                            </div>
                                        </td>
                                        <td>{this.getContentLoader()}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
        )
    }
}
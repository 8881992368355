import React from "react"
import Status, { StatusType } from "../../components/Status"
import KeyPowerMetric from "../../models/KeyPowerMetric"
import KeyPowerMetricService from "../../services/KeyPowerMetricService"
import KeyPowerMetricMutate from "./KeyPowerMetricMutate"
import ReactGA from "react-ga"

interface UpdateKeyPowerMetricProps {
    userId: string;
    keyPowerMetricId: string;
}

interface UpdateKeyPowerMetricState {
    keyPowerMetric: KeyPowerMetric;
    isLoading: boolean;
    error: boolean;
}

export default class UpdateKeyPowerMetric extends React.Component<UpdateKeyPowerMetricProps, UpdateKeyPowerMetricState> {
    constructor(props: UpdateKeyPowerMetricProps) {
        super(props)

        this.state = {
            isLoading: true,
            keyPowerMetric: null,
            error: false
        }
    }

    async componentDidMount() {
        try {            
            let keyPowerMetric = await KeyPowerMetricService.getKeyPowerMetric(this.props.keyPowerMetricId)
            this.setState({isLoading: false, keyPowerMetric: keyPowerMetric})
        } catch (err) {
            console.log(err)
            ReactGA.exception({
                description: `UpdateKeyPowerMetric.componentDidMount.getKeyPowerMetric(${this.props.keyPowerMetricId})`,
                fatal: true
            })
            this.setState({isLoading: false, error: true})
        }

    }

    render() {
        if (!this.state.isLoading && !this.state.error && this.state.keyPowerMetric) {
            return (
                <KeyPowerMetricMutate userId={this.props.userId} keyPowerMetric={this.state.keyPowerMetric} />
            )
        } else if (this.state.isLoading) {
            return (
                <Status statusType={StatusType.LOADING} />
            )
        } else {
            return (
                <Status statusType={StatusType.FAIL} message={`Couldn't find the required key power metric: ${this.props.keyPowerMetricId}`}/>
            )
        }

    }
}
import { faEdit, faPlusCircle, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PowerAnalysis, { PowerAnalysisDao } from "models/PowerAnalysis";
import React from "react"
import { Link } from "react-router-dom";
import { Button, ButtonGroup, Card, CardBody, CardTitle, Col, Pagination, PaginationItem, PaginationLink, Row, Table } from "reactstrap";
import PowerAnalysisService from "services/PowerAnalysisService";

interface PowerAnalysisFeedProps {
    powerAnalysis: PowerAnalysis[]
    editable: boolean
}

interface PowerAnalysisFeedState {
    page: number
}

export default class PowerAnalysisFeed extends React.Component<PowerAnalysisFeedProps, PowerAnalysisFeedState> {

    constructor(props: PowerAnalysisFeedProps){
        super(props);
        this.state = {
            page: 0
        }
    }

    getScaleColor(a: number, b: number) {
        let delta = Math.abs((b - a) / a) * 100
        if (delta < 2.5) { return "text-success" }
        else if (delta < 5) { return "text-warning" }
        else { return "text-danger" }
    }

    async deletePowerAnalysis(powerAnalysis: PowerAnalysis) {
        await PowerAnalysisService.deletePowerAnalysis(powerAnalysis)
        window.location.reload()
    }

    getPaginatedData(): PowerAnalysis[][] {
        let powerAnalysisData = this.props.powerAnalysis.sort((a, b) => {
            if (a.createdAt > b.createdAt) {
                return -1
            } else {
                return 1
            }
        })
        let paginatedData: PowerAnalysis[][] = [[]]
        let pageLength = 10
        for (let powerAnalysis of powerAnalysisData) {
            let lastPage = paginatedData[paginatedData.length - 1]
            if (lastPage.length < pageLength) {
                lastPage.push(powerAnalysis);
            } else {
                paginatedData.push([powerAnalysis])
            }
        }
        return paginatedData;
    }

    render() {
        let paginatedData = this.getPaginatedData();
        return (
            <Card>
                <CardBody>
                    <CardTitle>
                        <Row>
                            <Col>
                                <h2>Power Analysis</h2>
                            </Col>
                            {
                                this.props.editable &&
                                <Col xs={2}>
                                    <Link style={{ float: "right" }} className="btn btn-outline-success" to="/power_analysis_creation">
                                        <FontAwesomeIcon icon={faPlusCircle} />
                                    </Link>
                                </Col>
                            }
                        </Row>
                    </CardTitle>
                    <Pagination>
                        <PaginationItem onClick={() => this.state.page !== 0 && this.setState({page: this.state.page - 1})} disabled={this.state.page == 0}>
                            <PaginationLink previous/>
                        </PaginationItem>
                        {paginatedData.map((_, i) => {
                            return (
                                <PaginationItem active={this.state.page === i} onClick={() => this.setState({page: i})}>
                                    <PaginationLink>
                                        {i + 1}
                                    </PaginationLink>
                                </PaginationItem>
                            )
                        })}
                        <PaginationItem onClick={() => this.state.page !== paginatedData.length - 1 && this.setState({page: this.state.page + 1})} disabled={this.state.page === paginatedData.length - 1}>
                            <PaginationLink next/>
                        </PaginationItem>
                    </Pagination>
                    <Table responsive size="sm">
                        <thead>
                            <tr>
                                <th>Title</th>
                                <th>1 Sec</th>
                                <th>15 Sec</th>
                                <th>1 Min</th>
                                <th>5 Min</th>
                                <th>20 Min</th>
                                {this.props.editable && <th>Actions</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedData[this.state.page].map(powerAnalysis => {
                                return (
                                    <tr>
                                        <td style={{maxWidth: "200px"}}><Link to={`/power_analysis/${powerAnalysis.id}`}>{powerAnalysis.title}</Link></td>
                                        {[1, 15, 60, 360, 1200].map(duration => {
                                            let basePowerMap: Map<string, number>
                                            if (powerAnalysis.files.items.length) {
                                                basePowerMap = powerAnalysis.files.items[0].partialPowerCurveMap
                                            }
                                            return (
                                                <td>{PowerAnalysisDao.getPriorityOrderedData(powerAnalysis).map(file => {
                                                    return (
                                                        <div className={this.getScaleColor(basePowerMap.get(duration.toString()), file.partialPowerCurveMap.get(duration.toString()))}>
                                                            {file.partialPowerCurveMap.get(duration.toString()).toFixed(0)}w
                                                        </div>)
                                                })}</td>
                                            )
                                        })}
                                        {this.props.editable &&
                                        <td>
                                            <ButtonGroup>
                                                <Button color="danger" outline size="sm" onClick={() => this.deletePowerAnalysis(powerAnalysis)}>
                                                    <FontAwesomeIcon size="sm" icon={faTrashAlt} />
                                                </Button>
                                            </ButtonGroup>
                                        </td>
                                        }
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
        )
    }
}
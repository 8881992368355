import LineChart from "components/LineChart/LineChart"
import LineChartWrapper from "components/LineChart/LineChartWrapper"
import React from "react"
import { Button, CardTitle, Col, Form, FormGroup, Input, Label, Row } from "reactstrap"
import PowerAnalysis, { PowerAnalysisDao, PowerAnalysisDataDao } from "../../models/PowerAnalysis"
import PowerCurveUtilities from "../../utilities/PowerCurveUtilities"
import ActivityPower from "./ActivityPower"
import DeviationCurve from "./DeviationCurve"
import PowerCurve from "./PowerCurve"
import { CSVLink, CSVDownload } from "react-csv";

interface PowerAnalysisProps {
    powerAnalysis: PowerAnalysis
    nextStep?: () => void
}

export default class PowerAnalysisView extends React.Component<PowerAnalysisProps> {
    render() {
        let deviceNames;
        let powerCurves = PowerAnalysisDao.getPriorityOrderedData(this.props.powerAnalysis).map((powerAnalysisData) => {
            return PowerCurveUtilities.getPowerCurve(powerAnalysisData.fitFile.records)
        })

        deviceNames = PowerAnalysisDao.getPriorityOrderedData(this.props.powerAnalysis)
            .map((powerAnalysisData) => PowerAnalysisDataDao.getMainEquipmentName(powerAnalysisData))

        let lines = PowerAnalysisDao.toLines(this.props.powerAnalysis)

        return (
            <div>
                
                <CardTitle><h4>Activity Power</h4></CardTitle>
                <LineChartWrapper lines={lines} legend={true} />
                <CardTitle><h4>Power Curve</h4></CardTitle>
                <PowerCurve datas={powerCurves} deviceNames={deviceNames} />
                <CardTitle><h4>Deviation Curve</h4></CardTitle>
                <p>The deviation curve displays how your two power sources have deviated during your maximum effort of a given duration.</p>
                <DeviationCurve datas={powerCurves} deviceNames={deviceNames} />
                {this.props.nextStep && <Button outline color="primary" onClick={() => this.props.nextStep()}>Save</Button>}
            </div>
        )
    }
}
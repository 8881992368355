import Equipment, { EquipmentType } from "../models/Equipment";
import EquipmentData from "./EquipmentData";
import { API } from "aws-amplify";
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';

const SMART_TRAINER = "Smart trainer";
const POWER_METER = "Power meter";
const HEART_RATE_MONITOR = "Heart rate monitor";
export default class EquipmentService {

    static async createNewEquipment(equipment: Equipment) {
        let result: any = await API.graphql({
            query: mutations.createEquipment, variables: {
                input: {
                    ...equipment
                }
            }
        })
        return result.data.createEquipment as Equipment
    }

    static async getEquipment(id: string) {
        let result: any = await API.graphql({
            query: queries.getEquipment, variables: {
                id: id
            }
        })
        if (result.data.getEquipment) {
            return result.data.getEquipment as Equipment
        } else {
            throw Error(`Couldn't retrieve equipment with ID: ${id}`)
        }
    }

    static async getEquipmentByUserId(userId: string) {
        try {
            let result: any = await API.graphql({
                query: queries.equipmentByUserId, variables: {
                    userId: userId
                }
            })
            return result.data.EquipmentByUserId.items as Equipment[]
        } catch (err) {
            throw Error(`Failed to get equipment for user ${userId}, due to: ${err.message}`)
        }
    }

    static async updateEquipment(equipment: Equipment) {
        await API.graphql({
            query: mutations.updateEquipment, variables: {
                input: {...equipment}
            }
        })
    }

    static async deleteEquipment(id: string) {
        await API.graphql({query: mutations.deleteEquipment, variables: {
            input: {
                id: id
            }
        }})
    }
    
    static equipmentTypeLabelToEnum(type: string): any {
        let mapping: Map<string, EquipmentType> = new Map([
            [SMART_TRAINER, EquipmentType.SMART_TRAINER],
            [POWER_METER, EquipmentType.POWER_METER],
            [HEART_RATE_MONITOR, EquipmentType.HEART_RATE_MONITOR]
        ])

        return mapping.get(type)
    }

    static equipmentTypeEnumToLabel(type: EquipmentType) {
        let mapping: Map<EquipmentType, string> = new Map([
            [EquipmentType.SMART_TRAINER, SMART_TRAINER],
            [EquipmentType.POWER_METER, POWER_METER],
            [EquipmentType.HEART_RATE_MONITOR, HEART_RATE_MONITOR]
        ])

        return mapping.get(type)
    }

    static getEquipmentTypeLabels() {
        return [SMART_TRAINER, POWER_METER]
    }

    static getModels(equipmentType: EquipmentType, make: string) {
        if (equipmentType === EquipmentType.SMART_TRAINER) {
            if (EquipmentData.TRAINER_BRANDS_TO_MODELS.has(make)) {
                return EquipmentData.TRAINER_BRANDS_TO_MODELS.get(make);
            } else {
                return []
            }
        } else if (equipmentType === EquipmentType.POWER_METER) {
            if (EquipmentData.POWER_METER_BRANDS_TO_MODELS.has(make)) {
                return EquipmentData.POWER_METER_BRANDS_TO_MODELS.get(make);
            } else {
                return []
            }
        } else {
            return []
        }
    }

    public static getBrands(equipmentType: EquipmentType): string[] {
        if (equipmentType === EquipmentType.SMART_TRAINER) {
            return Array.from(EquipmentData.TRAINER_BRANDS_TO_MODELS.keys());
        } else if (equipmentType === EquipmentType.POWER_METER) {
            return Array.from(EquipmentData.POWER_METER_BRANDS_TO_MODELS.keys());
        } else {
            return []
        }
    }
}
import React from 'react';
import { Navbar, NavbarBrand, NavbarToggler, Collapse, Nav, NavItem } from 'reactstrap'
import { NavLink } from 'react-router-dom';
import Logo from "content/Logo.svg"
import "components/Navbar.css"

interface MyNavBarState {
    collapsed: boolean;
}

export default class MyNavBar extends React.Component<{}, MyNavBarState> {
    constructor(props: {}) {
        super(props);

        this.state = {
            collapsed: true
        }
    }

    render() {
        return (
            <Navbar style={{ backgroundColor: "white" }} color="faded" light expand="lg">
                <NavbarBrand>
                    <NavLink style={{ color: "inherit" }} className="nav-link" to={`/`}>
                        <img height="70px" src={Logo}></img>
                    </NavLink>
                </NavbarBrand>
                <NavbarToggler onClick={() => this.setState({ collapsed: !this.state.collapsed })} />
                <Collapse isOpen={!this.state.collapsed} navbar>
                    <Nav navbar>
                        <NavItem>
                            <NavLink className="nav-link" to={"/my_profile"}>My Profile</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className="nav-link" to={"/search_users"}>Find Riders</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className="nav-link" to={"/articles"}>News</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className="nav-link" to={"/articles/release-notes"}>Release Notes</NavLink>
                        </NavItem>
                        <NavItem>
                            <div style={{marginTop: "7px"}}>
                                <form action="https://www.paypal.com/donate" method="post" target="_blank">
                                    <input type="hidden" name="business" value="65WDXLVV93ZEC" />
                                    <input type="hidden" name="no_recurring" value="0" />
                                    <input type="hidden" name="item_name" value="Help support eBioPassport through a donation. These donations will help pay for the costs of maintaining/hosting the website." />
                                    <input type="hidden" name="currency_code" value="CAD" />
                                    <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                                    <img alt="" src="https://www.paypal.com/en_CA/i/scr/pixel.gif" width="1" height="1" />
                                </form>
                            </div>
                        </NavItem>
                    </Nav>
                </Collapse>
            </Navbar>
        )
    }
} 
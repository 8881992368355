import React from 'react'
import { Col, Row, Table } from 'reactstrap';
import LineChart from './LineChart';
import { Line } from './model';

interface LineChartWrapperProps {
    lines: Line[];
    xAxisLabel?: (input: number) => string
    legend?: boolean
}

interface LineChartWrapperState {
    legendData: [number, number][];
    brushPoints: { startIndex: number, endIndex: number }[]
}

export default class LineChartWrapper extends React.Component<LineChartWrapperProps, LineChartWrapperState> {
    static defaultProps = {
        legend: false
    }

    constructor(props: LineChartWrapperProps) {
        super(props)

        this.state = {
            legendData: this.props.lines.map(line => line.data.length ? [line.data[0][0], line.data[0][1]] : [0,0]),
            brushPoints: []
        }
    }

    brushedData() {
        let brushedData: [number, number][][] = []
        this.props.lines.map((line, index) => {
            let slice = line.data;
            if (this.state.brushPoints.length > index) {
                let brushPoints = this.state.brushPoints[index]
                slice = line.data.slice(brushPoints.startIndex, brushPoints.endIndex);
            }
            brushedData.push(slice)
        })
        return brushedData;
    }

    render() {
        let brushedData = this.brushedData()
        return (
            <div>
                <LineChart lines={this.props.lines}
                    onMouseOver={(yValues) => this.setState({ legendData: yValues })}
                    onBrush={(brushPoints) => this.setState({ brushPoints })} />
                <div style={{ margin: "0px 50px" }}>
                    <span>Drag to zoom, double click to reset.</span>
                    <Table responsive size='sm'>
                        <thead>
                            <tr>
                                <th>Device</th>
                                <th>Power</th>
                                <th>Average Power</th>
                                <th>Max Power</th>
                                <th>Min Power</th>
                                <th>Zeros</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.legendData.map((value, i) => {
                                let line = this.props.lines[i]
                                let currentBrushedData: number[] = brushedData[i].map(value => value[1])
                                return (
                                    <tr style={{ color: line.color ? line.color : "inherit" }}>
                                        <td>{line.label}</td>
                                        <td>{value[1]}</td>
                                        <td>{(currentBrushedData.reduce((acc, value) => acc + value, 0) / currentBrushedData.length).toFixed(0)}</td>
                                        <td>{Math.max(...currentBrushedData)}</td>
                                        <td>{Math.min(...currentBrushedData)}</td>
                                        <td>{currentBrushedData.filter(value => value === 0).length}</td>
                                    </tr>)
                            })}
                        </tbody>

                    </Table>

                </div>

            </div>

        )
    }
}
import React from 'react';
import { Jumbotron } from 'reactstrap';
import PrismicService, { Article as IArticle } from 'services/PrismicService';
import * as prismicH from '@prismicio/helpers'
import "./Article.css"
import YoutubeUtilities from 'utilities/YoutubeUtilities';
import CustomComponentDelegator from './CustomComponents/CustomComponentDelegator';


interface ArticleProps {
    articleId: string
}

interface ArticleState {
    loading: boolean;
    article: IArticle;
}

export default class Article extends React.Component<ArticleProps, ArticleState> {

    constructor(props: ArticleProps) {
        super(props);

        this.state = {
            loading: true,
            article: undefined
        }
    }

    async componentDidMount() {
        let article = await PrismicService.getArticle(this.props.articleId);
        console.log(article);
        this.setState({
            article: article[0],
            loading: false
        })
    }

    render() {
        if (this.state.loading) {
            return (<div></div>)
        }
        return (
            <div className='container top-buffer'>
                <div className='hero-image center' style={{
                    background: `url(${this.state.article.data.cover_image.url})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }}>
                    <div className='hero-title' dangerouslySetInnerHTML={{ __html: prismicH.asHTML(this.state.article.data.title) }}></div>
                    <div className='background-screen'></div>
                </div>
                {this.state.article.data.body.map(body => {
                    let youtubeId = body.youtube_video?.url ? YoutubeUtilities.youtubeParser(body.youtube_video.url) : undefined
                    return (
                        <div className='article-body top-buffer'>
                            <div className='center'>
                                {body.image?.url !== "" && <img src={body.image.url} />}
                            </div>
                            <div className='center'>
                                {youtubeId && <iframe title={youtubeId} style={{ width: "800px", height: "450px", maxWidth: "100%" }} src={`https://www.youtube.com/embed/${youtubeId}`} allowFullScreen></iframe>}
                            </div>
                            <div className='center'>
                                {body.image_caption && <div className='top-buffer' dangerouslySetInnerHTML={{ __html: prismicH.asHTML(body.image_caption) }}></div>}
                            </div>
                            <div className='center'>
                                {body.custom_component && <CustomComponentDelegator componentName={body.custom_component} />}
                            </div>
                            {body.image && <div className='top-buffer' />}
                            <div dangerouslySetInnerHTML={{ __html: prismicH.asHTML(body.text) }}>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }
}
import React from "react"
import { Row, Col } from "reactstrap"

export default class Footer extends React.Component {
    render() {
        return (
            <div style={{ marginTop: "30px", backgroundColor: "#e1e1e1", padding: "20px" }}>
                <Row>
                    <Col>
                        <div>
                            <a href="mailto:eBioPassport@gmail.com" className="text-muted">Contact Us</a>
                        </div>
                        <div>
                            <a href="/release_notes" className="text-muted">Release Notes</a>
                        </div>
                        <div>
                            <a href="/privacy" className="text-muted">Privacy</a>
                        </div>
                        <div>
                            <a href="/GDPR" className="text-muted">GDPR</a>
                        </div>
                    </Col>
                    <Col>
                        <h5 className="text-muted">Useful Links - RGT</h5>
                        <div>
                            <a className="text-muted" href="https://rgtdb.com/" target="_blank" ref="eBioPassport">RGTDB</a>
                        </div>
                        <div>
                            <a className="text-muted" href="https://dblodgett.shinyapps.io/gpxr/" target="_blank" ref="eBioPassport">GPX Editor</a>
                        </div>
                        <div>
                            <a className="text-muted" href="https://dblodgett.shinyapps.io/dualR/" target="_blank" ref="eBioPassport">Dual recording analysis</a>
                        </div>

                    </Col>
                </Row>
            </div>
        )
    }
}
import User from "./User"

export default interface UserRequest {
    id?: string
    userId?: string
    user?: User
    subjectId?: string
    subject?: User
    userRequestType?: UserRequestType
    requestReason?: string
    statusReason?: string
    status?: string
    groupsCanAccess?: string[]
    createdAt?: string
}

export enum UserRequestType {
    CONFIDENCE_SCORE_ASSESMENT = "CONFIDENCE_SCORE_ASSESMENT",
    BUG_REPORT = "BUG_REPORT",
    FEATURE_REQUEST = "FEATURE_REQUEST"
}
import React from "react"
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap"
import ContentLoader from "react-content-loader"

export default class VideoFeedLoading extends React.Component {
    render() {
        return (
            <div>
                <Card>
                    <CardBody>
                        <CardTitle>
                            <Row>
                                <Col>
                                    <h2>Videos</h2>
                                </Col>
                            </Row>
                        </CardTitle>
                        {[0, 1, 2].map(index => {
                            return (
                                <Row key={index} className="top-buffer">
                                    <Col lg={4}>
                                        <ContentLoader viewBox="0 0 100% 180" height={180} width={"100%"}>
                                            <rect x="0" y="0" width="100%" height="160" />
                                        </ContentLoader>
                                    </Col>
                                    <Col lg={8}>
                                        <ContentLoader viewBox="0 0 100% 180" height={180} width={"100%"}>
                                            <rect x="0" y="0" width="60%" height="20" />
                                            <rect x="0" y="30" width="100%" height="10" />
                                            <rect x="0" y="50" width="100%" height="10" />
                                            <rect x="0" y="70" width="100%" height="10" />
                                        </ContentLoader>
                                    </Col>
                                </Row>
                            )
                        })}
                    </CardBody>
                </Card>
            </div>
        )
    }
}
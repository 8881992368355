import { faEdit, faShareAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, ButtonGroup, Button, Toast, ToastBody, ToastHeader } from 'reactstrap'
import YoutubeUtilities from 'utilities/YoutubeUtilities'
import { VideoEntry } from '../../models/VideoEntry'
import VideoService from '../../services/VideoService'
import UnitUtilies from '../../utilities/UnitUtilities'

interface VideoCardProps {
    videoEntry: VideoEntry
    editable: boolean
}

interface VideoCardState {
    showCopyToast: boolean
}



export default class VideoCard extends React.Component<VideoCardProps, VideoCardState> {
    constructor(props: VideoCardProps) {
        super(props)

        this.state = {
            showCopyToast: false
        }
    }

    async deleteVideoEntry(id: string) {
        await VideoService.deleteVideoEntryById(id)
        window.location.reload()
    }


    getEmbededYoutubeVideo(videoUrl: string) {
        if (videoUrl.includes("youtube") && videoUrl.includes("/shorts/")) {
            let youtubeId = YoutubeUtilities.youtubeShortsParser(videoUrl)
            return (
                <iframe title={youtubeId} width="100%" src={`https://www.youtube.com/embed/${youtubeId}`} allowFullScreen></iframe>
            )
        }
        if (videoUrl.includes("youtu")) {
            let youtubeId = YoutubeUtilities.youtubeParser(videoUrl)
            return (
                <iframe title={youtubeId} width="100%" src={`https://www.youtube.com/embed/${youtubeId}`} allowFullScreen></iframe>
            )
        }
        return (
            <div><a href={videoUrl} rel="noreferrer" target="_blank">Source</a></div>
        )
    }

    copyToClipboard() {
        navigator.clipboard.writeText(`Checkout my transparency video: ${window.origin}/video_entry/${this.props.videoEntry.id}`)
        this.setState({ showCopyToast: true })
    }

    componentDidUpdate() {
        setTimeout(() => this.setState({ showCopyToast: false }), 3000);
    }

    render() {
        return (
            <div>
                <Row className="top-buffer">
                    <Col lg={4}>
                        {this.getEmbededYoutubeVideo(this.props.videoEntry.videoUrl)}
                    </Col>
                    <Col lg={8}>
                        <Row>
                            <Col>
                                <Link style={{ color: "inherit" }} to={`/video_entry/${this.props.videoEntry.id}`}>
                                    <h3>{this.props.videoEntry.title}</h3>
                                </Link>
                            </Col>
                            <Col xs={12} md={2}>
                                <ButtonGroup style={{ float: "right" }}>
                                    <Button onClick={() => this.copyToClipboard()} size="sm" color="primary" outline>
                                        <FontAwesomeIcon icon={faShareAlt} />
                                    </Button>
                                    {this.props.editable &&
                                        <Link className="btn btn-outline-info btn-sm" to={`/video_entry/edit/${this.props.videoEntry.id}`}>
                                            <FontAwesomeIcon icon={faEdit} />
                                        </Link>
                                    }
                                    {this.props.editable &&
                                        <Button size="sm" color="danger" outline
                                            onClick={() => this.deleteVideoEntry(this.props.videoEntry.id)}>
                                            <FontAwesomeIcon icon={faTrashAlt} />
                                        </Button>
                                    }
                                </ButtonGroup>
                            </Col>

                        </Row>
                        <Link style={{ color: "inherit", textDecoration: "inherit" }} to={`/video_entry/${this.props.videoEntry.id}`}>
                            <p>{this.props.videoEntry.description}</p>
                            <Row>
                                {this.props.videoEntry.height &&
                                    <Col>
                                        <p><b>Height: </b>{`${this.props.videoEntry.height} ${UnitUtilies.getHeightLabel(this.props.videoEntry.heightUnit)}`}</p>
                                    </Col>
                                }
                                {this.props.videoEntry.weight &&
                                    <Col>
                                        <p><b>Weight: </b>{`${this.props.videoEntry.weight} ${UnitUtilies.getWeightLabel(this.props.videoEntry.weightUnit)}`}</p>
                                    </Col>
                                }
                            </Row>
                            {this.props.videoEntry.groupsCanAccess.includes("all") ? <i>Public</i> : <i>Private</i>}
                        </Link>
                    </Col>
                </Row>
                {this.state.showCopyToast && <div style={{ position: "fixed", bottom: "20px", right: "10px", zIndex: 1 }}>
                    <Toast>
                        <ToastHeader>
                            Thanks for sharing!
                        </ToastHeader>
                        <ToastBody>
                            Link copied to clipboard.
                        </ToastBody>
                    </Toast>
                </div>}
            </div>
        )
    }
}
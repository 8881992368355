export const deepVerificationRequestByOrganizerId = /* GraphQL */ `
  query DeepVerificationRequestByOrganizerId(
    $eventOrganizerId: ID
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVerificationRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    VerificationRequestByOrganizerId(
      eventOrganizerId: $eventOrganizerId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventOrganizerId
        title
        notes
        status
        userId
        user {
            firstName
            lastName
        }
        verificationRequestToVideoEntries {
            items {
                videoEntry {
                    id
                    userId
                    videoUrl
                    height
                    heightUnit
                    weight
                    weightUnit
                    title
                    description
                    commentsEnabled
                    type
                    groupsCanAccess
                    createdAt
                    updatedAt
                }
            }
        }
        verificationRequestToKeyPowerMetrics {
            items {
                keyPowerMetric {
                    id
                    userId
                    duration
                    value
                    fileLinks
                    feature
                    notes
                    groupsCanAccess
                    createdAt
                    updatedAt
                }
            }
        }
        verificationRequestToEquipment {
            items {
                equipment {
                    id
                    userId
                    make
                    model
                    notes
                    proof
                    type
                    serialNumber
                    groupsCanAccess
                    createdAt
                    updatedAt
                }
            }
        }
        verificationRequestToPowerAnalysis {
          items {
            powerAnalysis {
              id
              userId
              title
              notes
              groupsCanAccess
              createdAt
              updatedAt
              files {
                items {
                  id
                  partialPowerCurve
                  equipment {
                    items {
                      id
                    }
                  }
                }
              }
            }
          }
        }
        groupsCanAccess
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
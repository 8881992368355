import { faEdit } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import PrivacyInput from "components/PrivacyInput"
import Status, { StatusType } from "components/Status"
import Equipment from "models/Equipment"
import { EventOrganizer } from "models/EventOrganizer"
import KeyPowerMetric from "models/KeyPowerMetric"
import PowerAnalysis from "models/PowerAnalysis"
import VerificationRequest, { VerificationRequestStatus } from "models/VerificationRequest"
import { VideoEntry } from "models/VideoEntry"
import VideoCard from "pages/Video/VideoCard"
import { title } from "process"
import React from "react"
import { Link } from "react-router-dom"
import { Alert, Button, Card, CardBody, CardDeck, Col, Container, Form, FormGroup, Input, Label, Table } from "reactstrap"
import EquipmentService from "services/EquipmentService"
import EventOrganizerService from "services/EventOrganizerService"
import KeyPowerMetricService from "services/KeyPowerMetricService"
import PowerAnalysisService from "services/PowerAnalysisService"
import VerificationRequestService from "services/VerificationRequestService"
import VideoService from "services/VideoService"
import UnitUtilies from "utilities/UnitUtilities"
import { v4 } from "uuid"

const PERMISSION_MISSMATCH_WARNING = "Some items may not be viewable to the event organizer you have selected due to the privacy settings you have specified on the item. You can update privacy settings by clicking the edit button."
interface VerificationRequestMutationProps {
    userId: string
}

interface VerificationRequestMutationState {
    selectedEventOrganizers: { value: string, label: string }[];
    title: string
    notes: string
    selectedVideoEntries: Set<string>
    videoEntries: VideoEntry[]
    selectedKeyPowerMetrics: Set<string>
    keyPowerMetrics: KeyPowerMetric[]
    selectedPowerAnalysis: Set<string>
    powerAnalysis: PowerAnalysis[]
    selectedEquipment: Set<string>
    equipment: Equipment[]
    eventOrganizers: EventOrganizer[]
    loading: boolean,
    submitted: boolean,
    error: string
}

export default class VerificationRequestMutation extends React.Component<VerificationRequestMutationProps, VerificationRequestMutationState> {
    constructor(props: VerificationRequestMutationProps) {
        super(props)

        this.state = {
            selectedEventOrganizers: [],
            title: "",
            notes: "",
            selectedVideoEntries: new Set(),
            videoEntries: [],
            selectedKeyPowerMetrics: new Set(),
            keyPowerMetrics: [],
            selectedPowerAnalysis: new Set(),
            powerAnalysis: [],
            selectedEquipment: new Set(),
            equipment: [],
            eventOrganizers: [],
            loading: true,
            submitted: false,
            error: ""
        }
    }

    stateToModel(): VerificationRequest {
        let selectedEventOrganizerPermissionGroup = this.state.selectedEventOrganizers[0].value;
        let selectedEventOrganizer = this.state.eventOrganizers.find((eventOrganizer) => eventOrganizer.permissionGroup === selectedEventOrganizerPermissionGroup)
        let id = v4()
        return {
            id: id,
            eventOrganizerId: selectedEventOrganizer.id,
            title: this.state.title,
            notes: this.state.notes,
            status: VerificationRequestStatus.SUBMITTED,
            userId: this.props.userId,
            groupsCanAccess: [selectedEventOrganizer.permissionGroup]
        }
    }

    async submit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault()
        this.setState({ loading: true })
        try {
            let newVerificationRequest = this.stateToModel()
            await VerificationRequestService.createVerificationRequest(newVerificationRequest,
                Array.from(this.state.selectedVideoEntries), Array.from(this.state.selectedKeyPowerMetrics), Array.from(this.state.selectedEquipment), Array.from(this.state.selectedPowerAnalysis))
            this.setState({ loading: false, submitted: true, error: "" })
        } catch (err) {
            console.log(err)
            this.setState({ loading: false, submitted: true, error: err.message })
        }
    }

    async componentDidMount() {
        let eventOrganizers = await EventOrganizerService.getEventOrganizers()
        let videoEntries = await VideoService.getVideoEntriesByUserId(this.props.userId);
        let keyPowerMetrics = await KeyPowerMetricService.getKeyPowerMetricsByUser(this.props.userId);
        let equipment = await EquipmentService.getEquipmentByUserId(this.props.userId);
        let powerAnalysis = await PowerAnalysisService.getPowerAnalysisByUser(this.props.userId);
        this.setState({
            loading: false,
            eventOrganizers: eventOrganizers,
            videoEntries: videoEntries,
            keyPowerMetrics: keyPowerMetrics,
            powerAnalysis: powerAnalysis,
            equipment: equipment
        })
    }

    toggleVideoEntry(videoEntry: string) {
        let newSelectedVideoEntries = new Set(this.state.selectedVideoEntries)
        if (this.state.selectedVideoEntries.has(videoEntry)) {
            newSelectedVideoEntries.delete(videoEntry)
        } else {
            newSelectedVideoEntries.add(videoEntry)
        }
        this.setState({ selectedVideoEntries: newSelectedVideoEntries })
    }

    toggleKeyPowerMetrics(keyPowerMetric: string) {
        let newSelectedKeyPowerMetrics = new Set(this.state.selectedKeyPowerMetrics)
        if (this.state.selectedKeyPowerMetrics.has(keyPowerMetric)) {
            newSelectedKeyPowerMetrics.delete(keyPowerMetric)
        } else {
            newSelectedKeyPowerMetrics.add(keyPowerMetric)
        }
        this.setState({ selectedKeyPowerMetrics: newSelectedKeyPowerMetrics })
    }

    toggleEquipment(equipment: string) {
        let newSelectedEquipment = new Set(this.state.selectedEquipment)
        if (newSelectedEquipment.has(equipment)) {
            newSelectedEquipment.delete(equipment)
        } else {
            newSelectedEquipment.add(equipment)
        }
        this.setState({ selectedEquipment: newSelectedEquipment })
    }

    togglePowerAnalysis(powerAnalysis: string) {
        let newSelectedPowerAnalysis = new Set(this.state.selectedPowerAnalysis)
        if (newSelectedPowerAnalysis.has(powerAnalysis)) {
            newSelectedPowerAnalysis.delete(powerAnalysis)
        } else {
            newSelectedPowerAnalysis.add(powerAnalysis)
        }
        this.setState({ selectedPowerAnalysis: newSelectedPowerAnalysis })
    }

    canEventOrganizerView(groupsCanAccess: string[]) {
        if (groupsCanAccess.includes("all")) {
            return true
        } else {
            if (this.state.selectedEventOrganizers.length > 0) {
                return groupsCanAccess.includes(this.state.selectedEventOrganizers[0].value)
            } else {
                return true
            }
        }
    }

    doVideoEntriesContainWarning() {
        let canEventOrganizerView = this.state.videoEntries.map(videoEntry => this.canEventOrganizerView(videoEntry.groupsCanAccess))
        return canEventOrganizerView.includes(false)
    }

    doKeyPowerMetricsContainWarning() {
        let canEventOrganizerView = this.state.keyPowerMetrics.map(keyPowerMetrics => this.canEventOrganizerView(keyPowerMetrics.groupsCanAccess))
        return canEventOrganizerView.includes(false)
    }

    doesEquipmentContainWarning() {
        let canEventOrganizerView = this.state.equipment.map(equipment => this.canEventOrganizerView(equipment.groupsCanAccess))
        return canEventOrganizerView.includes(false)
    }

    doesPowerAnalysisContainWarning() {
        let canEventOrganizerView = this.state.powerAnalysis.map(powerAnalysis => this.canEventOrganizerView(powerAnalysis.groupsCanAccess))
        return canEventOrganizerView.includes(false)
    }

    render() {
        if (this.state.loading) {
            return (
                <Status statusType={StatusType.LOADING} title="Loading" />
            )
        } else if (this.state.submitted && this.state.error === "") {
            return (
                <Status statusType={StatusType.SUCCESS} message="Return to your profile page to view your new submission" />
            )
        } else if (this.state.submitted && this.state.error !== "") {
            return (
                <Status statusType={StatusType.FAIL} message={this.state.error} />

            )
        } else {
            return (
                <Container className="top-buffer">
                    <h2>Create/Update Verification Request</h2>
                    <Form onSubmit={(event) => this.submit(event)}>
                        <FormGroup row>
                            <Label md={4}>*Event Organizer</Label>
                            <Col md={8}>
                                <PrivacyInput value={this.state.selectedEventOrganizers} isMulti={false} required onChange={(privacySelection) => this.setState({ selectedEventOrganizers: [privacySelection as { value: string, label: string }] })} />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label md={4}>*Title</Label>
                            <Col md={8}>
                                <Input required type="text" value={this.state.title} onChange={(event) => this.setState({ title: event.target.value })} />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label md={4}>Notes</Label>
                            <Col md={8}>
                                <Input type="textarea" value={this.state.notes} onChange={(event) => this.setState({ notes: event.target.value })} />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label md={4}>Relevant Videos (click to select): </Label>
                            <Col md={8}>
                                {this.doVideoEntriesContainWarning() && <Alert color="warning">{PERMISSION_MISSMATCH_WARNING}</Alert>}
                                <Table responsive size="sm">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Title</th>
                                            <th>Date Created</th>
                                            <th>Source</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.videoEntries.map((videoEntry) => {
                                            return (
                                                <tr className={!this.canEventOrganizerView(videoEntry.groupsCanAccess) ? "table-warning" : ""}>
                                                    <td><input type="checkbox" checked={this.state.selectedVideoEntries.has(videoEntry.id)} onChange={() => this.toggleVideoEntry(videoEntry.id)} /></td>
                                                    <td>{videoEntry.title}</td>
                                                    <td>{UnitUtilies.getLocalDateTime(videoEntry.createdAt)}</td>
                                                    <td><a href={videoEntry.videoUrl} target="_brank">Source</a></td>
                                                    <td>
                                                        <Link className="btn btn-outline-info btn-sm" to={`/video_entry/edit/${videoEntry.id}`}>
                                                            <FontAwesomeIcon size="sm" icon={faEdit} />
                                                        </Link>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label md={4}>Relevant Power Analysis (click to select): </Label>
                            <Col md={8}>
                                {this.doesPowerAnalysisContainWarning() && <Alert color="warning">{PERMISSION_MISSMATCH_WARNING}</Alert>}

                                <Table responsive size="sm">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Title</th>
                                            <th>Date Created</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.powerAnalysis.map((powerAnalysis) => {
                                            return (
                                                <tr className={!this.canEventOrganizerView(powerAnalysis.groupsCanAccess) ? "table-warning" : ""}>
                                                    <td><input type="checkbox" checked={this.state.selectedPowerAnalysis.has(powerAnalysis.id)} onChange={() => this.togglePowerAnalysis(powerAnalysis.id)} /></td>
                                                    <td><Link to={`/power_analysis/${powerAnalysis.id}`}>{powerAnalysis.title}</Link></td>
                                                    <td>{powerAnalysis.createdAt}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label md={4}>Relevant Power Metrics (click to select): </Label>
                            <Col md={8}>
                                {this.doKeyPowerMetricsContainWarning() && <Alert color="warning">{PERMISSION_MISSMATCH_WARNING}</Alert>}

                                <Table responsive size="sm">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Duration</th>
                                            <th>Power</th>
                                            <th>Date Created</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.keyPowerMetrics.map((keyPowerMetric) => {
                                            return (
                                                <tr className={!this.canEventOrganizerView(keyPowerMetric.groupsCanAccess) ? "table-warning" : ""}>
                                                    <td><input type="checkbox" checked={this.state.selectedKeyPowerMetrics.has(keyPowerMetric.id)} onChange={() => this.toggleKeyPowerMetrics(keyPowerMetric.id)} /></td>
                                                    <td>{UnitUtilies.secondsToReadableString(keyPowerMetric.duration)}</td>
                                                    <td>{keyPowerMetric.value}</td>
                                                    <td>{UnitUtilies.getLocalDateTime(keyPowerMetric.createdAt)}</td>
                                                    <td>
                                                        <Link className="btn btn-sm btn-outline-info" to={`/key_power_metric_edit/${keyPowerMetric.id}`}>
                                                            <FontAwesomeIcon size="sm" icon={faEdit} />
                                                        </Link>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label md={4}>Relevant Equipment (click to select): </Label>
                            <Col md={8}>
                                {this.doesEquipmentContainWarning() && <Alert color="warning">{PERMISSION_MISSMATCH_WARNING}</Alert>}
                                <Table style={{ padding: "0 10px" }} responsive size="sm">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Make</th>
                                            <th>Model</th>
                                            <th>Date Created</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.equipment.map((equipment) => {
                                            return (
                                                <tr className={!this.canEventOrganizerView(equipment.groupsCanAccess) ? "table-warning" : ""}>
                                                    <td><input type="checkbox" checked={this.state.selectedEquipment.has(equipment.id)} onChange={() => this.toggleEquipment(equipment.id)} /></td>
                                                    <td>{equipment.make}</td>
                                                    <td>{equipment.model}</td>
                                                    <td>{UnitUtilies.getLocalDateTime(equipment.createdAt)}</td>
                                                    <td>
                                                        <Link className="btn btn-sm btn-outline-info" to={`/equipment_edit/${equipment.id}`}>
                                                            <FontAwesomeIcon size="sm" icon={faEdit} />
                                                        </Link>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </Col>
                        </FormGroup>
                        <Button color="success" type="submit">Submit</Button>
                    </Form>
                </Container>
            )
        }
    }
}
import React from "react"
import ContentLoader from "react-content-loader"
import { Card, CardBody, CardTitle, Container } from "reactstrap"
import PowerAnalysis from "../../models/PowerAnalysis"
import PowerAnalysisService from "../../services/PowerAnalysisService"
import PowerAnalysisBasicCard from "./PowerAnalysisBasicCard"
import PowerAnalysisView from "./PowerAnalysisView"

interface PowerAnalysisViewWrapperProps {
    id: string
}

interface PowerAnalysisWrapperState {
    loading: boolean
    powerAnalysis: PowerAnalysis
}

export default class PowerAnalysisViewWrapper extends React.Component<PowerAnalysisViewWrapperProps, PowerAnalysisWrapperState> {

    constructor(props: PowerAnalysisViewWrapperProps) {
        super(props)

        this.state = {
            loading: true,
            powerAnalysis: null
        }
    }

    async componentDidMount() {
        let powerAnalysis = await PowerAnalysisService.getPowerAnalysis(this.props.id)
        this.setState({ powerAnalysis: powerAnalysis, loading: false })
    }

    render() {
        if (this.state.loading) {
            return (<Container className="top-buffer">
                <Card>
                    <CardBody>
                        <CardTitle><h2>Activity Power</h2></CardTitle>
                        <ContentLoader viewBox="0 0 100% 30" height={200} width={"100%"}>
                            <rect x="0" y="0" width="100%" height="100%" />
                        </ContentLoader>
                        <CardTitle><h2>Power Curve</h2></CardTitle>
                        <ContentLoader viewBox="0 0 100% 30" height={200} width={"100%"}>
                            <rect x="0" y="0" width="100%" height="100%" />
                        </ContentLoader>
                        <CardTitle><h2>Deviation Curve</h2></CardTitle>
                        <p>The deviation curve displays how your two power sources have deviated during your maximum effort of a given duration.</p>
                        <ContentLoader viewBox="0 0 100% 30" height={200} width={"100%"}>
                            <rect x="0" y="0" width="100%" height="100%" />
                        </ContentLoader>
                    </CardBody>
                </Card>
            </Container>)
        }
        return (
            <Container className="top-buffer">
                {this.state.powerAnalysis && <PowerAnalysisBasicCard powerAnalysis={this.state.powerAnalysis} />}
                <div className="top-buffer" />
                <Card>
                    <CardBody>
                        {this.state.powerAnalysis && <PowerAnalysisView powerAnalysis={this.state.powerAnalysis} />}
                    </CardBody>
                </Card>
            </Container>
        )
    }
}
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'
import { Card, CardBody, CardTitle, Col, Pagination, PaginationItem, PaginationLink, Row } from 'reactstrap'
import { VideoEntry } from '../../../models/VideoEntry'
import VideoCard from '../../../pages/Video/VideoCard'

interface VideoFeedProps {
    videoEntries: VideoEntry[];
    editable: boolean;
}

interface VideoFeedState {
    page: number
}

export default class VideoFeed extends React.Component<VideoFeedProps, VideoFeedState> {

    constructor(props: VideoFeedProps) {
        super(props);

        this.state = {
            page: 0
        }
    }

    getPaginatedData(): VideoEntry[][] {
        let data = this.props.videoEntries.sort((a, b) => {
            let dateA = new Date(a.createdAt)
            let dateB = new Date(b.createdAt)
            if (dateA >= dateB) {
                return -1
            } else {
                return 1
            }
        })
        let paginatedData: VideoEntry[][] = [[]]
        let pageLength = 5
        for (let video of data) {
            let lastPage = paginatedData[paginatedData.length - 1]
            if (lastPage.length < pageLength) {
                lastPage.push(video);
            } else {
                paginatedData.push([video])
            }
        }
        return paginatedData;
    }

    render() {
        let paginatedData = this.getPaginatedData()
        return (
            <div>
                <Card>
                    <CardBody>
                        <CardTitle>
                            <Row>
                                <Col>
                                    <h2>Videos</h2>
                                </Col>
                                {
                                    this.props.editable &&
                                    <Col xs={2}>
                                        <Link style={{ float: "right" }} className="btn btn-outline-success" to="/video_entry_creation">
                                            <FontAwesomeIcon icon={faPlusCircle} />
                                        </Link>
                                    </Col>
                                }
                            </Row>
                        </CardTitle>
                        <Pagination>
                            <PaginationItem onClick={() => this.state.page !== 0 && this.setState({page: this.state.page - 1})} disabled={this.state.page == 0}>
                                <PaginationLink previous/>
                            </PaginationItem>
                            {paginatedData.map((_, i) => {
                                return (
                                    <PaginationItem active={this.state.page === i} onClick={() => this.setState({page: i})}>
                                        <PaginationLink>
                                            {i + 1}
                                        </PaginationLink>
                                    </PaginationItem>
                                )
                            })}
                            <PaginationItem onClick={() => this.state.page !== paginatedData.length - 1 && this.setState({page: this.state.page + 1})} disabled={this.state.page === paginatedData.length - 1}>
                                <PaginationLink next/>
                            </PaginationItem>
                        </Pagination>
                        {paginatedData[this.state.page].map((videoEntry: VideoEntry) => {
                            return (
                                <VideoCard videoEntry={videoEntry} editable={this.props.editable} />
                            )
                        })}
                        {this.props.videoEntries.length === 0 && this.props.editable &&
                            <div style={{ textAlign: "center" }}>
                                <p>Use the plus button to add video entries. These can be anything from a weigh-in to a video of you calibrating your power source</p>
                            </div>
                        }
                        {this.props.videoEntries.length === 0 && !this.props.editable &&
                            <div style={{ textAlign: "center" }}>
                                <p>This user hasn't uploaded any videos yet.</p>
                            </div>
                        }
                    </CardBody>
                </Card>
            </div>
        )
    }
}
import Status, { StatusType } from "components/Status"
import { EventOrganizer } from "models/EventOrganizer"
import VerificationRequest, { VerificationRequestStatus } from "models/VerificationRequest"
import EquipmentFeed from "components/feeds/equipment/EquipmentFeed"
import KeyPowerMetricFeed from "components/feeds/keyPowerMetric/KeyPowerMetricFeed"
import VideoCard from "pages/Video/VideoCard"
import React from "react"
import { Link } from "react-router-dom"
import { Button, ButtonGroup, Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap"
import EventOrganizerService from "services/EventOrganizerService"
import VerificationRequestService from "services/VerificationRequestService"
import Select, { OptionsType } from "react-select"
import UnitUtilies from "utilities/UnitUtilities"
import PowerAnalysisFeedWrapper from "components/feeds/powerAnalysis/PowerAnalysisFeedWrapper"
import PowerAnalysisFeed from "components/feeds/powerAnalysis/PowerAnalysisFeed"

interface VerificationRequestFeedProps {
    editable: boolean
}

interface VerificationRequestFeedState {
    verificationRequests: VerificationRequest[]
    selectedEventOrganizers: Set<{ value: string; label: string }>
    eventOrganizersById: Map<string, EventOrganizer>
    verificationRequestToStatus: Map<string, VerificationRequestStatus>
    statusFilter: Set<{ value: string; label: string }>
    loading: boolean
    feedLoading: boolean
    submitted: boolean
    errors: string[]
}

const VERIFICATION_REQUEST_VALUE_TO_LABEL_MAP = VerificationRequestService.getVerificationRequestStatusValueToLabelsMap()
export default class VerificationRequestFeed extends React.Component<VerificationRequestFeedProps, VerificationRequestFeedState> {

    constructor(props: VerificationRequestFeedProps) {
        super(props)

        this.state = {
            verificationRequests: [],
            selectedEventOrganizers: new Set(),
            eventOrganizersById: new Map(),
            verificationRequestToStatus: new Map(),
            statusFilter: new Set(),
            loading: true,
            feedLoading: false,
            submitted: false,
            errors: []
        }
    }

    async componentDidMount() {
        let eventOrganizersById = new Map()
        let eventOrganizers = await EventOrganizerService.getEventOrganizers()
        eventOrganizers.forEach((eventOrganizer) => eventOrganizersById.set(eventOrganizer.id, eventOrganizer))
        this.setState({
            loading: false,
            eventOrganizersById: eventOrganizersById
        })
    }

    async updateEventOrganizer(values: OptionsType<{ value: string; label: string }>) {
        this.setState({ feedLoading: true })
        let verificationRequests: VerificationRequest[] = []
        let newEventOrganizers = new Set(values)
        let temp = Array.from(newEventOrganizers)
        for (let i = 0; i < temp.length; i++) {
            let newVerificationRequests = await VerificationRequestService.getVerificationRequestsByOrganizerId(temp[i].value)
            verificationRequests = verificationRequests.concat(newVerificationRequests)
        }
        this.setState({
            selectedEventOrganizers: newEventOrganizers,
            verificationRequests: verificationRequests,
            feedLoading: false
        })
    }

    toggleStatus(verificationRequestId: string, status: VerificationRequestStatus) {
        let newMap = new Map(this.state.verificationRequestToStatus)
        newMap.set(verificationRequestId, status)
        this.setState({
            verificationRequestToStatus: newMap
        })
    }

    verificationRequestStatusOptions() {
        return Array.from(VERIFICATION_REQUEST_VALUE_TO_LABEL_MAP.keys()).map((value) => {
            return { value: value, label: VERIFICATION_REQUEST_VALUE_TO_LABEL_MAP.get(value) }
        })
    }

    eventOrganizersOptions() {
        return Array.from(this.state.eventOrganizersById.keys()).map((eventOrganizerId: string) => {
            return { value: eventOrganizerId, label: this.state.eventOrganizersById.get(eventOrganizerId).label }
        })
    }

    isFiltered(verificationRequest: VerificationRequest): boolean {
        let status = verificationRequest.status.toString()
        let filteredStatuses = new Set(Array.from(this.state.statusFilter.keys()).map((entry) => entry.value))
        if (filteredStatuses.size === 0) {
            return true
        } else {
            return filteredStatuses.has(verificationRequest.status.toString())
        }
    }

    async submit() {
        this.setState({ loading: true });
        let keysToUpdate = Array.from(this.state.verificationRequestToStatus.keys())
        let failures: string[] = []
        for (let i = 0; i < keysToUpdate.length; i++) {
            try {
                let key = keysToUpdate[i]
                await VerificationRequestService.updateVerificationRequest({ id: key, status: this.state.verificationRequestToStatus.get(key) })
            } catch (err) {
                failures.push(err.message)
            }
        }
        this.setState({ loading: false, errors: failures, submitted: true })
    }

    modifiedVerificationRequestStatus(verificationRequest: VerificationRequest) {
        if (this.state.verificationRequestToStatus.has(verificationRequest.id)) {
            return this.state.verificationRequestToStatus.get(verificationRequest.id)
        } else {
            return verificationRequest.status
        }
    }

    render() {
        if (this.state.loading) {
            return (
                <Status statusType={StatusType.LOADING} />
            )
        } else if (this.state.errors.length > 0) {
            return (
                <Status statusType={StatusType.FAIL} message={this.state.errors.toString()} />
            )
        } else if (this.state.submitted) {
            return (
                <Status statusType={StatusType.SUCCESS} message="Updated all verification requests." />
            )
        }
        return (
            <Container className="top-buffer">
                <div style={{ position: "sticky", top: "0", backgroundColor: "var(--brand-blue)", padding: "0 15px 15px", zIndex: 2 }}>
                    <Row>
                        <Col className="top-buffer" xs={12} md={6}>
                            <Select isMulti={true}
                                options={this.eventOrganizersOptions()}
                                value={Array.from(this.state.selectedEventOrganizers)}
                                onChange={(values) => this.updateEventOrganizer(values)}
                                placeholder="Event Organizer"
                            />
                        </Col>
                        <Col className="top-buffer" xs={12} md={6}>
                            <Select isMulti={true}
                                options={this.verificationRequestStatusOptions()}
                                value={Array.from(this.state.statusFilter)}
                                onChange={(values) => this.setState({ statusFilter: new Set(values) })}
                                placeholder="Filter verification status"
                            />
                        </Col>
                        {this.props.editable &&
                            <Col className="top-buffer">
                                <Button style={{ width: "90%", maxWidth: "250px" }} color="info" onClick={() => this.submit()}>Submit All</Button>
                            </Col>
                        }
                        <Col className="top-buffer"></Col>
                    </Row>
                </div>
                {!this.state.feedLoading && this.state.verificationRequests
                    .filter((verificationRequest) => this.isFiltered(verificationRequest))
                    .sort((a, b) => a.createdAt > b.createdAt ? -1 : 1)
                    .map((verificationRequest) => {
                        return (
                            <Card className="top-buffer">
                                <CardHeader>
                                    <h5><Link to={`/profile/${verificationRequest.userId}`}>{`${verificationRequest.user.firstName} ${verificationRequest.user.lastName} - ${verificationRequest.title}`}</Link></h5>
                                    {this.props.editable &&
                                        <ButtonGroup>
                                            <Button outline={this.modifiedVerificationRequestStatus(verificationRequest) !== VerificationRequestStatus.PENDING}
                                                onClick={() => this.toggleStatus(verificationRequest.id, VerificationRequestStatus.PENDING)} size="sm" color="primary">
                                                Pending
                                    </Button>
                                            <Button outline={this.modifiedVerificationRequestStatus(verificationRequest) !== VerificationRequestStatus.ACCEPTED}
                                                onClick={() => this.toggleStatus(verificationRequest.id, VerificationRequestStatus.ACCEPTED)} size="sm" color="success">
                                                Approved
                                    </Button>
                                            <Button outline={this.modifiedVerificationRequestStatus(verificationRequest) !== VerificationRequestStatus.DENIED}
                                                onClick={() => this.toggleStatus(verificationRequest.id, VerificationRequestStatus.DENIED)} size="sm" color="danger">
                                                Denied
                                    </Button>
                                        </ButtonGroup>
                                    }
                                </CardHeader>
                                <CardBody>
                                    {`Request created at: ${UnitUtilies.getLocalDateTime(verificationRequest.createdAt)}`}
                                    <Row>
                                        {verificationRequest.notes && <Col xs={12} md={12} className="top-buffer">
                                            <p>{verificationRequest.notes}</p>
                                        </Col>}
                                        {verificationRequest.verificationRequestToVideoEntries.items
                                            .filter((videoRequestEntry) => videoRequestEntry.videoEntry !== null)
                                            .map((verificationRequestToVideoEntry) => {
                                                return (
                                                    <Col xs={12} lg={6} className="top-buffer">
                                                        <VideoCard videoEntry={verificationRequestToVideoEntry.videoEntry} editable={false} />
                                                    </Col>
                                                )
                                            })}
                                        <Col xs={12} md={6} className="top-buffer">
                                            <PowerAnalysisFeed editable={false} powerAnalysis={verificationRequest.verificationRequestToPowerAnalysis.items
                                                .filter(item => item.powerAnalysis !== null)
                                                .map(item => item.powerAnalysis)
                                            } />
                                        </Col>
                                        <Col xs={12} md={6} className="top-buffer">
                                            <KeyPowerMetricFeed editable={false} keyPowerMetrics={verificationRequest.verificationRequestToKeyPowerMetrics.items
                                                .filter(item => item.keyPowerMetric !== null)
                                                .map(item => item.keyPowerMetric)} />
                                        </Col>
                                        <Col className="top-buffer">
                                            <EquipmentFeed editable={false} equipment={verificationRequest.verificationRequestToEquipment.items
                                                .filter(item => item.equipment !== null)
                                                .map((item) => item.equipment)} />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        )
                    })}
                {this.state.feedLoading && <Status statusType={StatusType.LOADING} message="" title="Loading Requests" />}
            </Container>
        )
    }
}
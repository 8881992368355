import EventOrganizerService from "./EventOrganizerService"

export default class PrivacyService {
    public static async getOptions() {
        let eventOrganizers = await EventOrganizerService.getEventOrganizers()
        let eventOrganizerValueLabels = eventOrganizers.map((eventOrganizer) => {
            return {
                value: eventOrganizer.permissionGroup,
                label: eventOrganizer.label
            }
        })
        return [
            ...eventOrganizerValueLabels,
            { value: "all", label: "public" },
            { value: "", label: "private" }
        ]
    }

    public static async valueToLabelMapping() {
        let mapping = new Map(
            [
                ["all", "public"],
                ["", "private"]]
        )
        let eventOrganizers = await EventOrganizerService.getEventOrganizers()
        for (let index = 0; index < eventOrganizers.length; index++) {
            const element = eventOrganizers[index];
            mapping.set(element.permissionGroup, element.label)
        }
        return mapping
    }

    public static defaultPrivacySetting() {
        return { value: "all", label: "public" }
    }
}
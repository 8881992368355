import { faCheckCircle, faCircleNotch, faPlusCircle, faQuestionCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Status, { StatusType } from "components/Status"
import { VerificationRequestStatus } from "models/VerificationRequest"
import { VerificationStatus } from "models/VerificationStatus"
import React from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, CardTitle, Col, Row, Table } from "reactstrap"

interface VerificationFeedProps {
    verificationStatus: VerificationStatus[]
    editable: boolean
}

export default class VerificationFeed extends React.Component<VerificationFeedProps> {

    verificationRequestStatusToIcon(verificationRequestStatus: VerificationRequestStatus) {
        if (verificationRequestStatus === VerificationRequestStatus.ACCEPTED) {
            return (
                <FontAwesomeIcon icon={faCheckCircle} className="text-success text-center" />
            )
        } else if (verificationRequestStatus === VerificationRequestStatus.DENIED) {
            return (
                <FontAwesomeIcon icon={faTimesCircle} className="text-danger text-center" />
            )
        } else {
            return (
                <FontAwesomeIcon icon={faQuestionCircle} className="text-info text-center" />
            )
        }
    }

    render() {
        return (
            <Card>
                <CardBody>
                    <CardTitle>
                        <Row>
                            <Col>
                                <h2>Verification</h2>
                            </Col>
                            {
                                this.props.editable &&
                                <Col xs={2}>
                                    <Link className="btn btn-outline-success" style={{ float: "right" }} to="/verification_request_creation">
                                        <FontAwesomeIcon icon={faPlusCircle} />
                                    </Link>
                                </Col>
                            }
                        </Row>
                    </CardTitle>
                    {this.props.verificationStatus.length > 0 &&
                        <Table responsive size="sm">
                            <thead>
                                <th>Organizer</th>
                                <th>Status</th>
                            </thead>
                            <tbody>
                                {this.props.verificationStatus.map(verificationStatus => {
                                    return (
                                        <tr>
                                            <td>{verificationStatus.eventOrganizerLabel}</td>
                                            <td>{this.verificationRequestStatusToIcon(verificationStatus.status)}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>}
                    {this.props.verificationStatus.length === 0 &&
                        <p>This user hasn't been verified by any event organizer.</p>}
                </CardBody>
            </Card>
        )
    }
}
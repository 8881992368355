import { API } from "aws-amplify"
import UserRequest, { UserRequestType } from "../models/UserRequest"
import * as mutations from '../graphql/mutations';
import * as customQueries from '../graphql/customQueries';


const BUG_REPORT_LABEL = "Bug Report"
const FEATURE_REQUEST_LABEL = "Feature Request"
const CONFIDENCE_SCORE_ASSESMENT_LABEL = "Confidence Score Assesment"

export default class UserRequestService {
    static getUserRequestTypeLabels(): string[] {
        return [BUG_REPORT_LABEL, FEATURE_REQUEST_LABEL, CONFIDENCE_SCORE_ASSESMENT_LABEL]
    }

    static userRequestTypeLabelToEnum(type: string): UserRequestType {
        let mapping: Map<string, UserRequestType> = new Map([
            [BUG_REPORT_LABEL, UserRequestType.BUG_REPORT],
            [FEATURE_REQUEST_LABEL, UserRequestType.FEATURE_REQUEST],
            [CONFIDENCE_SCORE_ASSESMENT_LABEL, UserRequestType.CONFIDENCE_SCORE_ASSESMENT]
        ])

        return mapping.get(type)
    }

    static userRequestTypeEnumToLabel(type: UserRequestType): string {
        let mapping: Map<UserRequestType, string> = new Map([
            [UserRequestType.BUG_REPORT, BUG_REPORT_LABEL],
            [UserRequestType.FEATURE_REQUEST, FEATURE_REQUEST_LABEL],
            [UserRequestType.CONFIDENCE_SCORE_ASSESMENT, CONFIDENCE_SCORE_ASSESMENT_LABEL]
        ])

        return mapping.get(type)
    }

    static async createUserRequest(userRequest: UserRequest) {
        let result: any = await API.graphql({
            query: mutations.createUserRequest, variables: {
                input: {
                    ...userRequest
                }
            }
        })
        return result.data.createKeyPowerMetric as UserRequest
    }

    static async getConfidenceScoreRequests(): Promise<UserRequest[]> {
        let result: any = await API.graphql({
            query: customQueries.detailedUserRequestsByDate, variables: {
                userRequestType: UserRequestType.CONFIDENCE_SCORE_ASSESMENT,
                sortDirection: "DESC"
            }
        })
        return result.data.userRequestsByDate.items as UserRequest[]
    }

    static async updateUserRequest(userRequest: UserRequest) {
        let result: any = await API.graphql({
            query: mutations.updateUserRequest, variables: {
                input: { ...userRequest }
            }
        })
    }

}
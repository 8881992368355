import React from 'react'
import User from '../../models/User'
import { Container, Col, Row, Button } from 'reactstrap'
import { Auth } from 'aws-amplify';
import VideoFeedWrapper from 'components/feeds/video/VideoFeedWrapper';
import KeyPowerMetricFeedWrapper from 'components/feeds/keyPowerMetric/KeyPowerMetricFeedWrapper';
import EquipmentFeedWrapper from 'components/feeds/equipment/EquipmentFeedWrapper';
import ProfileCardWrapper from './ProfileCardWrapper';
import VerificationFeedWrapper from 'components/feeds/verification/VerificationFeedWrapper';
import PowerAnalysisFeedWrapper from 'components/feeds/powerAnalysis/PowerAnalysisFeedWrapper';
import ThirdPartyFeedWrapper from 'components/feeds/thirdParty/ThirdParyFeedWrapper';
import AlphaService from 'services/AlphaService';

interface ProfileProps {
    userId?: string;
    user?: User;
    loggedInUser: User;
}

interface ProfileState {
}

export default class Profile extends React.Component<ProfileProps, ProfileState> {

    isLoggedInUsersProfile() {
        return this.props.userId === this.props.loggedInUser.id;
    }

    render() {
        return (
            <Container>
                <Row>
                    <Col className="top-buffer" sm={12} lg={4}>
                        <ProfileCardWrapper userId={this.props.userId} editable={this.isLoggedInUsersProfile()} />
                        {this.isLoggedInUsersProfile() &&
                            <Button
                                onClick={() => Auth.signOut()}
                                style={{ width: "100%" }} outline color="primary" className="top-buffer">Sign Out
                                </Button>
                        }
                        <div className="top-buffer">
                            <VerificationFeedWrapper editable={this.isLoggedInUsersProfile()} userId={this.props.userId} />
                        </div>
                        {this.isLoggedInUsersProfile() && AlphaService.isAlphaUser(this.props.userId) && <div className="top-buffer">
                            <ThirdPartyFeedWrapper userId={this.props.userId} />
                        </div>}
                    </Col>
                    <Col className="top-buffer" sm={12} lg={8}>
                        <div>
                            <EquipmentFeedWrapper editable={this.isLoggedInUsersProfile()} userId={this.props.userId} />
                        </div>
                        <div className="top-buffer">
                            <PowerAnalysisFeedWrapper userId={this.props.userId} editable={this.isLoggedInUsersProfile()} />
                        </div>
                        <div className="top-buffer">
                            <KeyPowerMetricFeedWrapper editable={this.isLoggedInUsersProfile()} userId={this.props.userId} />
                        </div>
                        <div className="top-buffer">
                            <VideoFeedWrapper userId={this.props.userId} editable={this.isLoggedInUsersProfile()} />
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }
}
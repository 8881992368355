import React from 'react'
import Select from 'react-select';
import { FormText } from 'reactstrap';
import PrivacyService from 'services/PrivacyService';

interface PrivacyInputProps {
    onChange?: (privacySelection: { value: string, label: string }[] | { value: string, label: string }) => void;
    value: { value: string, label: string }[];
    required?: boolean;
    isMulti: boolean;
}

interface PrivacyInputState {
    options: { value: string, label: string }[]
    loading: boolean
}

export default class PrivacyInput extends React.Component<PrivacyInputProps, PrivacyInputState> {

    constructor(props: PrivacyInputProps) {
        super(props)

        this.state = {
            loading: true,
            options: []
        }
    }

    async componentDidMount() {
        let options = await PrivacyService.getOptions()
        this.setState({options: options})
    }

    render() {
        return (
            <div>
                {this.props.required &&
                    <input style={{ position: "absolute", border: "none", width: "1px", height: "1px", top: "10px", left: "80px" }} required value={this.props.value.toString()}></input>
                }
                <Select isMulti={this.props.isMulti} options={this.state.options} value={this.props.value} onChange={(value: any) => this.props.onChange(value as { label: string, value: string }[])} />
                <FormText className="text-muted">* Select which user groups can see this data.</FormText>
            </div>
        )
    }
}
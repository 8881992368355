import { faEdit, faPlusCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'
import { Button, ButtonGroup, Card, CardBody, CardText, CardTitle, Col, Row, Table } from 'reactstrap'
import Equipment from '../../../models/Equipment'
import EquipmentService from '../../../services/EquipmentService'

interface EquipmentFeedProps {
    editable: boolean
    equipment: Equipment[]
}

export default class EquipmentFeed extends React.Component<EquipmentFeedProps> {

    async deleteEquipment(id: string) {
        await EquipmentService.deleteEquipment(id)
        window.location.reload()
    }

    render() {
        return (
            <Card>
                <CardBody>
                    <CardTitle>
                        <Row>
                            <Col>
                                <h2>Equipment</h2>
                            </Col>
                            {
                                this.props.editable &&
                                <Col xs={2}>
                                    <Link style={{float: "right"}} className="btn btn-outline-success" to="/equipment_creation">
                                        <FontAwesomeIcon icon={faPlusCircle} />
                                    </Link>
                                </Col>
                            }
                        </Row>
                    </CardTitle>
                    <CardText>
                        {this.props.equipment.length !== 0 && <Table responsive size="sm">
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Make</th>
                                    <th>Model</th>
                                    <th>Proof</th>
                                    {this.props.editable && <th>Actions</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.equipment.map((equipment: Equipment) => {
                                    return (
                                        <tr>
                                            <td>{EquipmentService.equipmentTypeEnumToLabel(equipment.type)}</td>
                                            <td>{equipment.make}</td>
                                            <td>{equipment.model}</td>
                                            {equipment.proof && equipment.proof[0] !== "" ? <td><a href={equipment.proof[0]} target="_blank">Proof</a></td> : <td></td>}
                                            {this.props.editable && <td>
                                                <ButtonGroup>
                                                        <Link className="btn btn-sm btn-outline-info" to={`/equipment_edit/${equipment.id}`}>
                                                            <FontAwesomeIcon size="sm" icon={faEdit} />
                                                        </Link>
                                                    <Button color="danger" outline size="sm" onClick={() => this.deleteEquipment(equipment.id)}>
                                                        <FontAwesomeIcon size="sm" icon={faTrashAlt} />
                                                    </Button>
                                                </ButtonGroup>
                                            </td>}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>}
                        {this.props.equipment.length === 0 && this.props.editable &&
                            <div style={{ textAlign: "center" }}>
                                <p>Use the plus button to add smart trainers and power meters you use.</p>
                            </div>
                        }
                        {this.props.equipment.length === 0 && !this.props.editable &&
                            <div style={{ textAlign: "center" }}>
                                <p>This user hasn't listed any equipment yet.</p>
                            </div>
                        }
                    </CardText>
                </CardBody>
            </Card>
        )
    }

}
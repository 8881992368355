import Equipment from "models/Equipment";
import React from "react"
import EquipmentService from "services/EquipmentService";
import EquipmentFeed from "./EquipmentFeed";
import EquipmentFeedLoading from "./EquipmentFeedLoading";

interface EquipmentFeedWrapperState {
    loading: boolean;
    equipment: Equipment[];
}

interface EquipmentFeedWrapperProps {
    userId: string
    editable: boolean
}

export default class EquipmentFeedWrapper extends React.Component<EquipmentFeedWrapperProps, EquipmentFeedWrapperState> {
    constructor(props: EquipmentFeedWrapperProps) {
        super(props)

        this.state = {
            loading: true,
            equipment: []
        }
    }

    async componentDidMount() {
        let equipment = await EquipmentService.getEquipmentByUserId(this.props.userId);
        this.setState({equipment: equipment, loading: false})
    }

    render() {
        if (!this.state.loading) {
            return (
                <EquipmentFeed equipment={this.state.equipment} editable={this.props.editable} />
            )
        } else {
            return (
                <EquipmentFeedLoading editable={this.props.editable}/>
            )
        }
    }
}
/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://6nfefntpffcx5fqn254437fwmu.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "TransparencyPublic",
            "endpoint": "https://xhmvwt3xg7.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:d029bbd8-caf6-4b9b-98b4-a0ab8e001a57",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_2mqJzHIWr",
    "aws_user_pools_web_client_id": "7ao4fsaj511vst4s3cbi8b2719",
    "oauth": {
        "domain": "transparency43a1673c-43a1673c-prod.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,https://dev.d3kz024hv2c1pt.amplifyapp.com/,https://test.d3kz024hv2c1pt.amplifyapp.com/,https://prod.d3kz024hv2c1pt.amplifyapp.com/,https://www.ebiopassport.com/",
        "redirectSignOut": "http://localhost:3000/,https://dev.d3kz024hv2c1pt.amplifyapp.com/,https://test.d3kz024hv2c1pt.amplifyapp.com/,https://prod.d3kz024hv2c1pt.amplifyapp.com/,https://www.ebiopassport.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "transparencybucket92228-prod",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_cognito_login_mechanisms": [
        "GOOGLE"
    ]
};


export default awsmobile;

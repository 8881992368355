import { Line } from "components/LineChart/model";
import { SvgUtilities } from "utilities/SvgUtilitiles";
import Equipment from "./Equipment";
import FitFile, { Record } from "./FitFile";
import User from "./User"

export default interface PowerAnalysis {
    id?: string
    userId?: string
    user?: User
    title?: string
    notes?: string
    files?: { items: PowerAnalysisData[] }
    groupsCanAccess?: string[]
    createdAt?: string
}

export interface PowerAnalysisData {
    id?: string
    powerAnaysisId?: string
    priorityOrder?: number
    equipment?: { items: { equipment?: Equipment, id?: string }[] }
    file?: string
    fileName?: string
    fitFile?: FitFile
    rawFile?: File
    notes?: string
    startOffset?: number
    endOffset?: number
    partialPowerCurve?: string
    partialPowerCurveMap?: Map<string, number>
    groupsCanAccess?: string[]
}

export class PowerAnalysisDao {
    public static getPriorityOrderedData(powerAnalysis: PowerAnalysis) {
        return powerAnalysis.files.items.sort((a, b) => PowerAnalysisDataDao.getPriorityOrder(a) - PowerAnalysisDataDao.getPriorityOrder(b))
    }

    public static recordToSeconds(record: Record) {
        let date = new Date(record.timestamp)
        return date.getTime()
    }

    public static toLines(powerAnalysis: PowerAnalysis): Line[] {
        let colorMapper = SvgUtilities.getColorGradient(powerAnalysis.files.items.length)
        return powerAnalysis.files.items.map((item, i) => {
            let records = item.fitFile.records
            let offset = PowerAnalysisDataDao.getOffset(item)
            let filteredRecords = records
                .filter((record) => {
                    return this.recordToSeconds(record) < this.recordToSeconds(records[records.length - 1]) - offset.end * 1000 &&
                        this.recordToSeconds(record) - offset.start * 1000 > this.recordToSeconds(records[0])
                })
            let startTime = this.recordToSeconds(filteredRecords[0])
            return {
                data: filteredRecords
                    .map((record: Record) => [this.recordToSeconds(record) - startTime, record.power]),
                label: PowerAnalysisDataDao.getMainEquipmentName(item),
                unit: "w",
                color: colorMapper(i)
            }
        })
    }

    public static toCSV(powerAnalysis: PowerAnalysis): (number | string)[][] {
        let data: Map<string, (number | string)[]> = new Map()
        let legend = ["timestamp"];
        let allTimestamps = new Set(powerAnalysis.files.items.flatMap(file => file.fitFile.records.map(record => record.timestamp.toString())))
        allTimestamps.forEach(timestamp => {
            data.set(timestamp, [timestamp])
        })
        powerAnalysis.files.items.forEach((file) => {
            let equipment = PowerAnalysisDataDao.getMainEquipmentName(file)
            let headers = [`cadence_${equipment}`, `power_${equipment}`, `heart rate_${equipment}`, `distance_${equipment}`, `temperature_${equipment}`]
            legend = legend.concat(headers);
            let timestamps: Set<string> = new Set()
            file.fitFile.records.forEach(record => {
                timestamps.add(record.timestamp.toString())
                let row = data.get(record.timestamp.toString()).concat([record.cadence, record.power, record.heart_rate, record.distance, record.temperature])
                data.set(record.timestamp.toString(), row)
            })
            // if we skipped records we need to align them still
            data.forEach((_, key) => {
                if (!timestamps.has(key)) {
                    let row = data.get(key).concat(["", "", "", "", ""])
                    data.set(key, row)
                }
            })
        })
        
        let dataBody = Array.from(data.values())
        dataBody.unshift(legend)
        return dataBody
    }

}

export class PowerAnalysisDataDao {
    public static getMainEquipmentName(powerAnalysisData: PowerAnalysisData) {
        if (powerAnalysisData.equipment.items.length > 0) {
            let primaryEquipment = powerAnalysisData.equipment.items[0].equipment
            if (primaryEquipment) {
                return `${primaryEquipment.make} ${primaryEquipment.model}`
            } else {
                return "N/A"
            }
        } else {
            return "N/A"
        }
    }

    public static getOffset(powerAnalysisData: PowerAnalysisData) {
        let start = powerAnalysisData.startOffset ? powerAnalysisData.startOffset : 0
        let end = powerAnalysisData.endOffset ? powerAnalysisData.endOffset : 0
        return { start, end }
    }

    public static getPriorityOrder(powerAnalysisData: PowerAnalysisData) {
        if (powerAnalysisData.priorityOrder === 0) {
            return 0
        }
        return powerAnalysisData.priorityOrder ? powerAnalysisData.priorityOrder : 1
    }

    public static isPrimary(powerAnalysisData: PowerAnalysisData) {
        return this.getPriorityOrder(powerAnalysisData) === 0
    }

    public static toCSV(powerAnalysisData: PowerAnalysisData): (number | string)[][] {
        let data: (number | string)[][] = [["timestamp", "cadence", "power", "heart rate", "distance", "temperature"]]
        return data.concat(
            powerAnalysisData.fitFile.records.map(record => {
                return [record.timestamp.toString(), record.cadence, record.power, record.heart_rate, record.distance, record.temperature]
            }))
    }
}
import React from "react"
import ContentLoader from "react-content-loader"
import { Card, CardBody } from "reactstrap"

export default class ProfileCardLoading extends React.Component<{ editable: boolean }> {
    render() {
        return (
            <Card>
                <CardBody>
                    <ContentLoader viewBox="0 0 100% 500" height={260} width={"100%"}>
                        <rect x="0" y="0" width="70%" height="40" />
                        <rect x="0" y="60" width="100%" height="20" />
                        <rect x="0" y="90" width="100%" height="20" />
                        <rect x="0" y="120" width="80%" height="20" />
                        <rect x="0" y="180" width="100%" height="20"/>
                        <rect x="0" y="210" width="50%" height="20"/>
                    </ContentLoader>
                </CardBody>
            </Card>
        )
    }
}
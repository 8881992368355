export default class EquipmentData {
    static POWER_METER_BRANDS_TO_MODELS: Map<string, string[]> = new Map([
        ["4iiii", ["Left Side Podiiiium", "Right Side Podiiiium", "Dual Sided Precision", "Left Side Precision", "Right Side Precision", "Dual Sided Precision", "Dual Sided Precision Pro" ]],
        ["Assioma", ["Uno", "Duo"]],
        ["Arofly", []],
        ["Bryton", []],
        ["CateEye", []],
        ["Easton", ["Cinch"]],
        ["FSA", ["Powerbox"]],
        ["Garmin", ["Vector 3", "Vector 3s", "Vector 2", "Vector 2s", "Vector 1", "Vector 1s", "Rally RS200", "Rally RS100"]],
        ["IQSquare", []],
        ["Kinetic", []],
        ["Look", []],
        ["Moov", []],
        ["Pioneer", ["Single Side", "Dual Sided"]],
        ["Power2Max", ["NG Road", "NGeco Road", "Type S Road"]],
        ["PowerTap", ["P1 Pedals", "P2 Pedals", "G3 Hub"]],
        ["Praxis", []],
        ["Quarq", ["Spider Power"]],
        ["Rotor", []],
        ["Shimano", ["Single Side", "Dual Sided"]],
        ["Specialized", []],
        ["SRM", ["Origin"]],
        ["Stages", ["Gen 3 Left Side", "Gen 3 Dual Sided", "Gen 2 Left Side", "Gen 2 Dual Sided", "Gen 1 Left Side", "Gen 1 Dual Sided"]],
        ["Velocomp", []],
        ["Verve", []],
        ["Watteam", []],
        ["Zwatt", []]
    ]);
    static TRAINER_BRANDS_TO_MODELS: Map<string, string[]> = new Map([
        ["Bkool", []],
        ["CycleOps", []],
        ["Cyclotronics", ["Rolo de Treinamento"]],
        ["Elite", ["Direto", "Direto II", "Direto X", "Drivo", "Drivo II", "Kura", "Real Turbo Muin", "Real Turbo Muin B+", "Suito", "Turbo Muin", "Turbo Roteo Smart B+", "Volano"]],
        ["Jetblack", ["WhisperDrive"]],
        ["Kinetic", []],
        ["Magnetic Days", ["MagneticDays"]],
        ["Minoura", []],
        ["Saris", ["Hammer", "H2", "H3"]],
        ["Stages", []],
        ["Tacx", ["Flux 2", "Flux S", "Flux", "Neo", "Neo II", "Neo 2t", "Neo Smart Bike"]],
        ["Technogym", ["Mycycling"]],
        ["Wahoo", ["Kickr", "Kickr Core", "Kickr SNAP", "Kickr Bike"]],
        ["Wattbike", []],
        ["Xplova", ["Nova"]]
    ])
}
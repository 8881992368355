import React from 'react'
import { Container } from 'reactstrap'

export default class Privacy extends React.Component {
    render () {
        return (
            <Container className="top-buffer">
                <h2>Privacy and data</h2>
                <h3>What data we collect?</h3>
                <p>We collect any data that is uploaded to our site and store it until it is explicitly deleted by a user. Upon signing up we also collect your first and last name provided by google, this can be deleted by updating your profile on the profile page.
                    We also use google analytics to collect website usage data, however this isn't tied to an individual's account.</p>
                <h3>Who can see your data?</h3>
                <p>Your basic profile info which includes your name and bio is viewable publicly. For key power metrics, videos, and equipment entries we provide the ability to specify exactly which groups of people are able to see the given data.
                    If you mark your data as private, the only people capable of viewing your data are the system admins. Another thing to note is we allow you to specify multiple groups of people who are able to access your data, this feature should be used with caution as it will allow all users in all the groups specified to view the item.
                    For example, if you create a new weigh in video and set the privacy controls to Race organizer X and Race organizer Y, then both organizers will be able to view your video entry.
                </p>
                <h3>What do we do with your data?</h3>
                <p>The primary usage we have for your data is to display it to the users whom you have specified should be allowed to view the data. We are not responsible for the actions taken by users who have been given access to your data.
                    For example, if you specify that race organizer X should be allowed to view your data, and when they do they choose to disqualify you we cannot take responsibility for this.
                    Users should realize that although they have the ability to control the privacy settings on their data, as soon as they mark something public, others may view this data and take screen shots which we have no control over.
                </p>
                <p>The secondary usage for your data is to generate a user confidence score which is viewable to the general public. In order to do this, system admins look through all profile data, including privately marked items and assign a score and a breakdown of the score.
                    It should be noted that this breakdown can reveal some necessary information. For example if all of your videos are marked as private and as a result you receive full credit in the video section, other users will be able to tell you've uploaded videos however they won't be able to see the videos.
                </p>
                <p>Finally, we have google analytics on our site which we use to identify usage patterns to help us improve the site. In addition, site wide demographics may be used in the future to help attract advertisers which will be used to help pay for the running cost of the site. Users should know that we don't have the ability to view individual usage patterns and can only view things at an aggregate level.
                    For example, we don't know that Alice went to look at Bob's profile, we do know however that 10 people viewed Bob's profile on any given day.
                </p>
                <h3>How to access your data?</h3>
                <p>You can access your data by viewing your own profile and looking through all the entries you have provided. If you have some additional need that isn't addressed by this, you can contact us (details in footer). You also have the ability to permanently delete all your data, by deleting individual items.</p>
                <h3>Additional concerns</h3>
                <p>For additional concerns please send us an email (link in footer).</p>
            </Container>
        )
    }
}
import * as d3 from "d3";
import { Record } from "models/FitFile";
import { PowerAnalysisData, PowerAnalysisDataDao } from "models/PowerAnalysis";
import React from "react";
import { v4 } from "uuid";

const LEGEND_OFFSET = 200;
interface ActivityPowerProps {
    powerAnalysisData: PowerAnalysisData[]
    showTooltip?: boolean
}

interface ActivityPowerState {
    lines: Map<string, d3.Line<[number, number]>>
    paths: Map<string, d3.Selection<SVGPathElement, [number, number][], HTMLElement, any>>
    pointer: number
    xAxis: any
    svg: any
    id: string
}

export default class ActivityPower extends React.Component<ActivityPowerProps, ActivityPowerState> {
    constructor(props: ActivityPowerProps) {
        super(props)

        this.state = {
            lines: new Map(),
            paths: new Map(),
            pointer: 0,
            xAxis: null,
            svg: null,
            id: "a" + v4()
        }
    }

    componentDidMount() {
        this.createBase();
    }

    getSpacing() {
        let margin = { top: 50, right: 50, bottom: 50, left: 50 }
        let width = Math.min(document.getElementById(this.state.id).clientWidth - margin.left - margin.right)
        let height = Math.min(width * 0.5, 400)
        return {
            margin: margin,
            width: width,
            height: height
        }
    }

    recordToSeconds(record: Record) {
        let date = new Date(record.timestamp)
        return date.getTime()
    }

    getScale(xValues: number[], yValues: number[]) {
        let spacing = this.getSpacing()
        let width = spacing.width
        let height = spacing.height


        let xScale = d3.scaleLinear()
            .domain([d3.min(xValues), d3.max(xValues)])
            .range([0, width]);

        let yScale = d3.scaleLinear()
            .domain([0, Math.ceil(d3.max(yValues) / 100) * 100])
            .range([height, 0])

        return { xScale, yScale }
    }

    getColorGradient() {
        return d3.scaleLinear<string>()
            .domain([0, this.props.powerAnalysisData.length - 1])
            .range(["#fab529", "#032859"]);
    }

    toData(records: Record[], offset: { start: number, end: number }): [number, number][] {
        let filteredRecords = records
            .filter((record) => {
                return this.recordToSeconds(record) < this.recordToSeconds(records[records.length - 1]) - offset.end * 1000 &&
                    this.recordToSeconds(record) - offset.start * 1000 > this.recordToSeconds(records[0])
            })
        let startTime = this.recordToSeconds(filteredRecords[0])
        return filteredRecords
            .map((record: Record) => [this.recordToSeconds(record) - startTime, record.power])
    }

    componentDidUpdate() {
        try {
            let colorGradienter = this.getColorGradient()
            let spacing = this.getSpacing()
            this.props.powerAnalysisData.forEach((powerAnalysisData, index) => {
                let line = this.state.lines.get(powerAnalysisData.id)
                let path = this.state.paths.get(powerAnalysisData.id)
                let data: [number, number][] = this.toData(powerAnalysisData.fitFile.records, PowerAnalysisDataDao.getOffset(powerAnalysisData))
                path
                    .datum(data)
                    .attr("fill", "none")
                    .attr("stroke", colorGradienter(index))
                    .attr("stroke-width", 0.5)
                    .attr("d", line)

            })
        } catch (err) {
            console.log(err)
        }
    }

    createBase() {

        let spacing = this.getSpacing()
        let margin = spacing.margin
        let width = spacing.width
        let height = spacing.height

        let data = this.props.powerAnalysisData.map(powerAnalysisData => this.toData(powerAnalysisData.fitFile.records, PowerAnalysisDataDao.getOffset(powerAnalysisData)))
        let equipment = this.props.powerAnalysisData.map(powerAnalysisData => PowerAnalysisDataDao.getMainEquipmentName(powerAnalysisData))
        let xValues = data.map(values => values.map(value => value[0])).flat()
        let yValues = data.map(values => values.map(value => value[1])).flat()

        let scale = this.getScale(xValues, yValues)
        let xScale = scale.xScale
        let yScale = scale.yScale

        let colorGradienter = this.getColorGradient()

        let svg = d3.select(`#${this.state.id}-svg`)
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .style("opacity", 0.9)
            .append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

        let xAxis = svg.append("g")
            .attr("class", "x axis")
            .attr("transform", "translate(0," + height + ")")
            .style("opacity", "0.5")
            .call(d3.axisBottom(xScale)
                .tickFormat((domainValue: d3.NumberValue) => {
                    let date = new Date(domainValue as number)
                    return date.toLocaleTimeString()
                }))


        let yAxis = svg.append("g")
            .attr("class", "y axis")
            .style("opacity", "0.5")
            .call(d3.axisLeft(yScale))

        if (this.props.showTooltip) {
            svg.append("text")
                .attr("class", `tooltip-text`)
                .attr("width", 300)
                .attr("height", 50)
                .style("opacity", 0.5)
                .attr("x", width - LEGEND_OFFSET)
                .attr("y", 0)

            data.forEach((d, index) => {
                svg.append("text")
                    .attr("class", `tooltip-text-${index.toString()}`)
                    .attr("width", 300)
                    .attr("height", 50)
                    .attr("x", width - LEGEND_OFFSET)
                    .attr("y", index * 18 + 18)
                    .style("fill", colorGradienter(index))
            })

            let focus = svg.append("g")
                .attr("class", "focus")
                .style("display", "none");

            focus.append("line").attr("class", "lineHover")
                .style("stroke", "#999")
                .attr("stroke-width", 1)
                .style("shape-rendering", "crispEdges")
                .style("opacity", 0.5)
                .attr("y1", -height)
                .attr("y2", 0);

            let mousemove = (event: any) => {
                focus.attr("transform", `translate(${d3.pointer(event)[0]}, ${height})`)
                let x0 = xScale.invert(d3.pointer(event)[0])
                data.forEach((d: number[][], index: number) => {
                    let y = d.map(d => d[1])
                    let x = d.map(d => d[0])
                    let i = d3.bisect(x, x0, 1)
                    svg.select(`.tooltip-text-${index}`).text(`${equipment[index]}: ${y[i]}w`)
                })
            }

            svg.append("rect")
                .attr("class", "overlay")
                .attr("width", width)
                .attr("height", height)
                .style("fill", "none")
                .style("pointer-events", "all")
                .on("mouseover", function () { focus.style("display", null); })
                .on("mouseout", function () { focus.style("display", "none"); })
                .on("mousemove", mousemove);
        }

        let lines: Map<string, d3.Line<[number, number]>> = new Map()
        let paths: Map<string, d3.Selection<SVGPathElement, [number, number][], HTMLElement, any>> = new Map()

        this.props.powerAnalysisData.forEach((powerAnalysisData, index) => {
            let line = d3.line()
                .x((d: [number, number]) => xScale(d[0]))
                .y((d: [number, number]) => yScale(Math.round(d[1])))
                .curve(d3.curveMonotoneX)

            let data: [number, number][] = this.toData(powerAnalysisData.fitFile.records, PowerAnalysisDataDao.getOffset(powerAnalysisData))
            let path = svg.append("path")
                .datum(data)
                .attr("fill", "none")
                .attr("stroke", colorGradienter(index))
                .attr("stroke-width", 0.5)
                .attr("d", line)

            lines.set(powerAnalysisData.id, line)
            paths.set(powerAnalysisData.id, path)
        })

        this.setState({
            svg, paths, xAxis, lines
        })
    }

    render() {
        return (
            <div id={this.state.id}>
                <svg id={`${this.state.id}-svg`}></svg>
            </div>
        )
    }
}
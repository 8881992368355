import { VideoEntry } from "../models/VideoEntry";

import { API } from "aws-amplify";
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';
import * as customQueries from '../graphql/customQueries';
import VideoComment from "../models/VideoComment";

export default class VideoService {
    public static async createVideo(videoEntry: VideoEntry): Promise<VideoEntry> {
        let result: any = await API.graphql({
            query: mutations.createVideoEntry, variables: {
                input: {
                    ...videoEntry
                }
            }
        })
        return result.data.createVideoEntry as VideoEntry
    }

    public static async updateVideo(videoEntry: VideoEntry): Promise<VideoEntry> {
        let result: any = await API.graphql({ query: mutations.updateVideoEntry, variables: { input: { ...videoEntry } } })
        return result.data.updateVideoEntry as VideoEntry
    }

    public static async getVideoEntriesByUserId(userId: string) {
        let result: any = await API.graphql({
            query: queries.videoEntryByUserId, variables: {
                userId: userId
            }
        })
        return result.data.videoEntryByUserId.items;
    }

    public static async getVideoEntryById(id: string): Promise<VideoEntry> {
        let result: any = await API.graphql({ query: queries.getVideoEntry, variables: { id: id } })
        return result.data.getVideoEntry as VideoEntry
    }

    public static async getVideoEntryAllById(id: string): Promise<VideoEntry> {
        let result: any = await API.graphql({ query: customQueries.getVideoEntryAll, variables: { id: id } })
        return result.data.getVideoEntry as VideoEntry
    }

    public static async deleteVideoEntryById(id: string) {
        await API.graphql({ query: mutations.deleteVideoEntry, variables: { input: { id: id } } });
    }

    public static async createNewComment(videoComment: VideoComment) {
        await API.graphql({
            query: mutations.createVideoComment, variables: {
                input: {
                    ...videoComment
                }
            }
        })
    }

    public static async updateVideoComment(videoComment: VideoComment) {
        await API.graphql({ query: mutations.updateVideoComment, variables: { input: { ...videoComment } } });
    }
}
const ALPHA_USERS = new Set([
    "Google_109053477375104046631", //Ollie
    "Google_101857054893853181690", //Swart
    "Google_109649557070652238090", //Lister
    "Google_109836454411915511210", //Maxime
    "Google_112222425562865486298" //Alex
])
export default class AlphaService {
    static isAlphaUser(userId: string) {
        return ALPHA_USERS.has(userId)
    }
}